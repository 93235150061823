import { AuthGuard } from './core/auth/AuthGuard';
import { AuthService } from './core/auth/AuthService';
import { UserStageDetailComponent } from './pages/user-detail/user-stage-details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { APPBootComponent } from './app-boot/app-boot.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { NavbarComponent } from './common/navbar/navbar.component';
//import { SampleComponentComponent } from './pages/sample-component/sample-component.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { GraphComponent } from './graph/graph.component';
import { DeepdiveComponent } from './deepdive/deepdive.component';
import { SampleComponentComponent } from './pages/sample-component/sample-component.component';
import { RiskscoreComponent } from './riskscore/riskscore.component';


const routes: Routes = [
  { 
    path: '', redirectTo: '/login', pathMatch: 'full' 
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'side',
    component: SidebarComponent
  },
  {
    path: 'hub',
    canActivate: [AuthGuard],
    component: APPBootComponent,
    children:
      [
        { path: '', component: DashboardComponent , pathMatch: 'full'},
        { path: 'dashboard', component: DashboardComponent },
        { path: 'reports', component: ReportsComponent },
        { path: 'dialog', component: UserStageDetailComponent },
        { path: 'graph', component: GraphComponent },
        //{ path: 'deepdive', component: DeepdiveComponent },
        { path: 'sample', component: SampleComponentComponent },
        { path: 'Riskscore', component: RiskscoreComponent },

      ]

  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
