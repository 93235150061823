import { FilterModel, DashboardFilterModel } from '../DashboardFilterModel';
import { Domain } from './Domain'

export class BagicDomain implements Domain {
    getIcon(): string {
        return "ic_bagic.png";
    }
    getMobileAppName(): string {
        return "Insurance Wallet";
    }
    getDomainTelematicsProductName(): string {
        return "Bajaj Alliance Insurance Policy";
    }

    public filterModel:DashboardFilterModel;
    
    private drivenFilters:FilterModel[] = [];
        
    constructor() {
        this.filterModel = new DashboardFilterModel();
            
        this.drivenFilters.push(this.filterModel.allCustomers);
        this.drivenFilters.push(this.filterModel.notVerified);
        this.drivenFilters.push(this.filterModel.appDownload);
        this.drivenFilters.push(this.filterModel.shipDevice);
        this.drivenFilters.push(this.filterModel.deviceDelivered);
        this.drivenFilters.push(this.filterModel.deviceRTO);
        this.drivenFilters.push(this.filterModel.connectDevice);
        this.drivenFilters.push(this.filterModel.forfeedback);
        this.drivenFilters.push(this.filterModel.withfeedback);
        this.drivenFilters.push(this.filterModel.ncCustomers);
        this.drivenFilters.push(this.filterModel.niCustomers);
        this.drivenFilters.push(this.filterModel.snoozedCustomers);
        this.drivenFilters.push(this.filterModel.invalidCustomers);

    }
    
    getDashboardFilters(): FilterModel[] {
        return this.drivenFilters;
    }

    getName(): string {
        return "Drivesmart";
    }
    getPlayStoreLink(): string {
        return "https://play.google.com/store/apps/details?id=com.ba.cp.controller";
    }
    
    getCompanyName(): string {
        return "Bajaj Allianz General Insurance";
    }
}
