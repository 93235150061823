import { Domain } from './Domain'
import { DrivenDomain } from "./DrivenDomain";

export class CarIQDevDomain extends DrivenDomain {
    constructor() {
        super();
    }

    getName(): string {
        return "Driven-Dev";
    }
}