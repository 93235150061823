import { RestClient } from '../rest_client/RestClient'
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/StringUtils'
import { PageEvent } from '@angular/material';
import { Domain } from 'src/app/core/domain/Domain';

@Injectable()
export class PolicyExporterService {

  public static SPOTLIGHT_OVERALL: string = "OverallSpotlightExporter";
  public static SPOTLIGHT_APPOINTMENT: string = "AppointmentExporter";
  public static SPOTLIGHT_PRE_SHIPMENT: string = "PreShipmentExporter";
  public static SPOTLIGHT_POST_SHIPMENT: string = "PostShipmentExporter";
  public static SPOTLIGHT_RENEWAL: string = "RenewalSpotlight";
  public static SPOTLIGHT_RETRIEVAL: string = "RetrievalSpotlight";

  constructor(private restClient: RestClient) {
    
  }

  /**
   * Get policy details
   * 
   * @param createdAfter      Date
   * @param pageNo            PageNo
   */
  public getPolicyDetails(createdAfter: string, pageNo: number, authHeader: string,
    filter: string, username: string, callFilter: string,
    pageSize: number, priority, owner: string, domain: string) {
    let endPoint = `/atomAdmin/exporters/exportMultiple/OnboardingExporter/${filter}/${pageNo}/${pageSize}`;

    let callCount;
    if (callFilter == "All calls") {
      callCount = -1;
    } else if (callFilter == "Call 1") {
      callCount = 0;
    } else if (callFilter == "Call 2") {
      callCount = 1;
    } else if (callFilter == "Call 3") {
      callCount = 2;
    } else if (callFilter == "Call 4") {
      callCount = 3;
    } else if (callFilter == "Call 5") {
      callCount = 4;
    }

    //change 'Me' to username
    if (owner == "Me") {
      owner = username;
    }
    //change 'Everyone' to 'All Users'
    if (owner == "Everyone") {
      owner = "All Users";
    }

    let body: any;

    if (filter == 'ByTierAorB') {
      body = {
        "CreatedAfter": createdAfter,
        "username": username,
        "callFilter": callCount,
        "priority": priority,
        "owner": owner,
        "ByTierAorB": "B",
        "domain": domain
      }
    } else {
      body = {
        "CreatedAfter": createdAfter,
        "username": username,
        "callFilter": callCount,
        "owner": owner,
        "priority": priority,
        "domain": domain
      }
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }

  public getPolicyBySearchTerm(searchTerm: string, authHeader: string, username: string, domain: string) {
    let endPoint = `/atomAdmin/exporters/exportSingle/OnboardingExporter/ByPolicyNumber`;
    let body = {

      "Keyword search": searchTerm,
      "username": username,
      "domain": domain
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }


  public getPolicyBySearchQuery(searchTerm: string, authHeader: string, username: string, domain: string) {
    let endPoint = `/atomAdmin/exporters/exportMultiple/OnboardingExporter/KeywordSearch` + `/1/5000`;

    let body = {

      "Keyword search": searchTerm,
      "username": username,
      "domain": domain
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }



  /**
   * Download CSV file.
   * 
   * @param createdAfter 
   * @param username 
   * @param authHeader 
   */
  public downloadCSV(createdAfter: string, username: string, authHeader: string, filter: string, exporterName: string, domain: string) {
    let endPoint = `/atomAdmin/exporters/export/${exporterName}/${filter}/CSV`;

    let body = {
      "CreatedAfter": createdAfter,
      "username": username,
      "domain": domain
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }


  /**
   * Update proxy details
   */
  public updateProxyDetails(domainName: string, objectType: string, signature: string,
    username: string, domainId: string, authHeader: string) {
    console.log("updateProxyDetails: called");

    let body = {
      domainName: domainName,
      objectId: null,
      objectType: objectType,
      signature: signature,
      username: username,
      domainId: domainId
    }

    let url = `/proxyobject/update`;

    return this.restClient.execPUTRequest(url, authHeader, body);
  }


  /**
   * get policies by name of spotlight.
   * 
   * @param name 
   * @param domain 
   * @param owner 
   * @param authHeader 
   */
  public getPoliciesBySpotlight(name: string, domain: string, owner: string, authHeader: string) {
    let endPoint = `/atomAdmin/exporters/exportMultiple/Spotlight/Default/1/100`;

    let body = {
      "domain": domain,
      "owner": owner,
      "name": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }

  public getpolicyCount(categoryType: string, category: string, domain: string, fromDate: any, toDate: any, authHeader: string) {
    
    let endPoint = `/atomAdmin/exporters/exportMultiple/StageStatisticsCountExporter/TotalCount/1/50000`;

    let body = {
      "categoryType": categoryType,
      "category": category,
      "domain": domain,
      "fromDate": fromDate,
      "toDate": toDate,
      "username": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

  }
  /* 
  All customer count
  
  */

  getpolicyallCustomCount(categoryType: string, category: string, domain: string, fromDate: any, toDate: any, authHeader: string, username: string, ) {
    // console.log("inside statices service****************");
    // console.log(domain);
    // console.log(category);
    // console.log(fromDate);
    // console.log(toDate);
    // console.log(authHeader);
    // console.log(username);

    // let endPoint = `/atomAdmin/exporters/exportMultiple/StageStatisticsCountExporter/ByMonth/1/5000`;
    let endPoint = `/atomAdmin/exporters/exportMultiple/StageStatisticsCountExporter/ByMonthCumulative/1/5000`;

    let body = {
      "categoryType": categoryType,
      "category": category,
      "domain": domain,
      "fromDate": fromDate,
      "toDate": toDate,
      "username": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

  }
  getpolicystagingCount(stage: string, domain: string, fromDate: any, toDate: any, authHeader: string, username: string) {
    // console.log("inside statices service****************");
    // console.log(domain);
    // //console.log(category);
    // console.log(fromDate);
    // console.log(toDate);
    // console.log(authHeader);
    // console.log(username);

    let endPoint = `/atomAdmin/exporters/exportMultiple/StageStatisticsCountExporter/ByStagingDates/1/5000`;

    let body = {
      "stage": stage,
      "domain": domain,
      "fromDate": fromDate,
      "toDate": toDate,
      "username": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

  }


  getpolicyHitmap(MinLatitude: any, MaxLatitude: any, MinLongitude: any, MaxLongitude: any, NumberOfRegions: number, authHeader: string, username: string) {
    // console.log("inside statices service****************");
    // console.log(MinLatitude);
    // console.log(MaxLatitude);
    // console.log(MinLongitude);
    // console.log(MaxLongitude);
    // console.log(NumberOfRegions);
    // console.log(authHeader);
    // console.log(username);

    let endPoint = `/atomAdmin/exporters/exportMultiple/HeatMapExporter/ByRegion/1/499`;

    let body = {
      "MinLatitude": MinLatitude,
      "MaxLatitude": MaxLatitude,
      "MinLongitude": MinLongitude,
      "MaxLongitude": MaxLongitude,
      "NumberOfRegions": NumberOfRegions,
      "username": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

  }
/**
 * 
 * @param authHeader 
 * @param username 
 */
  getStateCount(authHeader, username, domain) {
      
    let endPoint = `/aggregateData/counts/${domain}/ByState`;
    return this.restClient.execGETRequest(endPoint,authHeader);

  }
  /**
 * 
 * @param authHeader 
 * @param username 
 */
   getPHYUAndTelematicsCount(authHeader, username, domain) {
      
    let endPoint = `/cariqProxy/get/Cariq|insurancePolicy|policyCount`;

    return this.restClient.execGETRequest(endPoint,authHeader);

  }

  /**
   * 
   * @param authHeader 
   * @param username 
   */
  getTotalcounts(authHeader, username, domain) {
    
    let endPoint = `/aggregateData/counts/${domain}`;
    return this.restClient.execGETRequest(endPoint,authHeader);
  }

/**
 * RiskScore
 */
getRiskScore(authHeader, username, domain){

  let endPoint = `/scoringEngine/drivingScoreDef`;
  return this.restClient.execGETRequest(endPoint,authHeader);
}

DrivingScore(distance: any, overSpeedingPercent: any, travelTimeInNight: any, idlingPercent: any, rashBrakingCount: any, rashAccelerationCount: any, authHeader: string, username: string) {
  
  let endPoint = `/scoringEngine/drivingScore`;

  let body = {
    "distance": distance,
    "overSpeedingPercent": overSpeedingPercent,
    "travelTimeInNight": travelTimeInNight,
    "idlingPercent": idlingPercent,
    "rashBrakingCount": rashBrakingCount,
    "rashAccelerationCount": rashAccelerationCount,

  }

  return this.restClient.execPOSTRequest(endPoint, authHeader, body);

}

/**
 * Monthly Growth
 */
CustomerDetails(categoryType: string, category: string, domain: string, fromDate: any, toDate: any, authHeader: string, username: string,){

  let endPoint = `/atomAdmin/exporters/exportMultiple/StageStatisticsCountExporter/ByMonthCumulative/1/5000`;

    let body = {
      "categoryType": categoryType,
      "category": category,
      "domain": domain,
      "fromDate": fromDate,
      "toDate": toDate,
      "username": name
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

}


CarperformanceDetails(PolicyNumber: any, domain: string,  authHeader: string, username: string){

  let endPoint = `/atomAdmin/exporters/exportMultiple/PolicyCarPerformanceExporter/ByPolicyNumbers/1/10`;

    let body = {
      "PolicyNumber": PolicyNumber,
      "domain": domain,
      "username": username,
    }

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);

}

/**
 * get MIS report
 */
getMISReport(authHeader, username, domain, startDate,endDate) {
    
  let endPoint = `/exportedData/enlist/${domain}/${startDate}/${endDate}/1/500`;
  return this.restClient.execGETRequest(endPoint,authHeader);
}

// get Active Vehicles

getActiveVehicles(authHeader, username, domain, startDate,endDate) {
    
  let endPoint = `/cariqProxy/get/Cariq|cars|activeVehiclesCount|${startDate}|${endDate}`;
  return this.restClient.execGETRequest(endPoint,authHeader);
}

//get renewal rate
getRenewalRate(authHeader, username, domain, startDate,endDate) {    
  let endPoint = `/aggregateData/policyRenewalPercentage/${domain}/${startDate}/${endDate}`;
  return this.restClient.execGETRequest(endPoint,authHeader);
}



/**
 * get odometer details
 */
getOdometerDetails(authHeader, username, domain, carID) {
    
  let endPoint = `/cariqProxy/get/Cariq|odometerRecord|enlist|${carID}`;
  // console.log("end point url is : " + endPoint);
  return this.restClient.execGETRequest(endPoint,authHeader);
}
}