import {DashboardService} from '../../pages/sample-component/DashboardService';
import { Component, Input, Output, EventEmitter, Injector } from '@angular/core';
/* import Swal from 'sweetalert2';
import swal from  'sweetalert';  */

/* import Swal  from 'node_modules/sweetalert/dist/sweetalert'; */
 
/* import swal,Swal, { SweetAlert } from "./core";   */

/* export default swal;
export as namespace swal;

export default Swal;
export as namespace Swal;  */
 

import { MatDialog, MatSnackBar } from '@angular/material';
import { Role } from '../role/Role'
import { AuthService } from '../../core/auth/AuthService'
import { PolicyExporterService } from "../../services/policy-exporter.service";


declare var jquery:any;
declare var $ :any;

@Component({
    selector: 'app-basecomponent',
    template: ` `
  })
  export class BaseComponent {

    public dialog : MatDialog;

    public role : Role;
    public authService : AuthService;
    public snackBar : MatSnackBar;

    private policyExporterService:PolicyExporterService;
    protected dashboardService:DashboardService;

    public constructor(injector : Injector) {
      this.dialog = injector.get(MatDialog);
      this.authService = injector.get(AuthService);
      this.role = this.authService.getRole();
      this.snackBar = injector.get(MatSnackBar);
      this.policyExporterService = injector.get(PolicyExporterService);
      this.dashboardService = injector.get(DashboardService);
    }
   
    public showAlert(message) {
     /*  swal(message); */
    }

    public confirmDialog(title, message) {
      /* return Swal({
        title: title,
        text: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Verify',
        cancelButtonText: 'Cancel'
      }); */
    }

    public confirmWithNote(message) {
     /*  return Swal({
        title: message,
        input: "textarea",
        
        showCancelButton: true,
        confirmButtonText: 'Verify',
        cancelButtonText: 'Cancel'
      }) */
    }

    public showDialog(dialogComponent : any, canClose:boolean) {

      var option = {
        disableClose: canClose
      }

      let dialogRef = this.showDialogWithOptions(dialogComponent, option);
      return dialogRef;
    }

    public showDialogWithSize(dialogComponent: any, canClose:boolean, h:string, w:string) {

      var option = {
        disableClose: canClose,
        height: h,
        width: w
      }

      let dialogRef = this.showDialogWithOptions(dialogComponent, option);
      return dialogRef;
    }

    public showDialogWithSizeAndInput(dialogComponent: any, canClose:boolean, h:string, w:string, input:any) {
      
            var option = {
              disableClose: canClose,
              height: h,
              width: w,
              data : input
            }
      
            let dialogRef = this.showDialogWithOptions(dialogComponent, option);
            return dialogRef;
    }


    public showDialogWithOptions(dialogComponent: any, options) {
      let dialogRef = this.dialog.open(dialogComponent, options)
      return dialogRef;
    }

    public showNotification(message:string, type:string) {
    }

    public showSnackBar(message:string) {
      console.log("BaseComponent: showSnackBar called: message : " + message);

      this.snackBar.open(message, 'OK', {
        duration: 3000,
      });
    }

    public showPreloader() {
      // $('.loaderbox').fadeIn();
    }

    public hidePreloader() {
    // $('.loaderbox').fadeOut();
    } 

    /**
     * Retrieve user from server
     * 
     * @param onUser  
     * @param signature 
     */
    protected getUserBySignature(cb: (res:any) => void, signature:string, domain:string) {
      let username = this.authService.getUsername();
      let basicAuth = this.authService.getBasicAuth();
      console.log("get policy for username " + username);
  
      this.showPreloader();
      this.policyExporterService.getPolicyBySearchTerm(signature, basicAuth, username, domain)
      .subscribe(data=>{
        this.hidePreloader();
        cb(data.json());
      },
      error=>{
        this.hidePreloader();
        this.showAlert("Error while loading details of " + signature);
      },
      () => {
        console.log("Get policy by policy number api completed!");
      });
    }

    public scrollToTop() {
      // $("html,body").animate({ scrollTop: 0 });
    }
  }
