import { Utils } from '../utils/Utils';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { MatMenuTrigger } from '@angular/material';
import { OnInit, ViewEncapsulation, Inject, Injector } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete, MAT_DIALOG_DATA, MatDatepicker } from '@angular/material';
import { Observable, Scheduler } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TimelineService } from '../services/timeline.service';
import { UserDetailBaseComponent } from '../pages/user-detail/UserDetailBaseComponent';
import { StagingService } from '../services/StagingService'
import { TagService } from '../services/TagService'
import { PolicyExporterService } from '../services/policy-exporter.service';
import { Desposition } from "../core/desposition/Desposition";
import { DespositionManager } from "../core/desposition/DespositionManager";
import { AdminBSBTemplate } from '../core/adminbsb';
import { DashboardService } from '../pages/sample-component/DashboardService';
import { formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppointmentService } from "../services/AppointmentService";
import swal from 'sweetalert2';
//import { ButtonList } from "sweetalert/typings/modules/options/buttons";
import { MatMenuModule } from '@angular/material/menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { OdometerDetailComponent } from '../odometer-detail/odometer-detail.component';

declare var TagsInput: any;
declare var $: any;


export interface PeriodicElement {
  name: string;
  position: any;
  weight: string;
  symbol: string;
  status: boolean;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 2, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 3, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 4, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 5, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 6, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 7, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 8, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 9, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false },
  { position: 10, name: '21000', weight: 'pune,maharashta', symbol: 'Mar 24 12:01:54 AM', status: false }

];

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css'],
  providers: [AppointmentService, TimelineService, TagService, StagingService],
  encapsulation: ViewEncapsulation.None
})
export class AccountDetailsComponent extends UserDetailBaseComponent implements OnInit {

  /**
   * Select options
   */
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Sweta' },
    { value: 'pizza-1', viewValue: 'Preeti' },
    { value: 'tacos-2', viewValue: 'Mayur' }
  ];

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;


  animal: string;
  name: string;
  //<START>tag input and tags properties start here
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['Lemon'];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  //<END>tag input properties end here
  displayedColumns: string[] = ['profile', 'reading', 'place', 'dateandtime'];
  dataSource = ELEMENT_DATA;
  //Is this customer exercised?
  //(taken action on it: added comment(not monitoring it.now...), appointment scheduled, etc)
  public exercised: boolean = false;

  public user: any;
  public owners: any;
  public timelines: any;
  public message: any;
  public commentTag: string;
  public username: any;
  public authHeader: any;
  public isSuccess: boolean = true;
  public uiError: string;
  public initialOwner: any;
  public selectedOwner: any;
  public car_details: any;
  public car_det: any = {};
  public dManager: DespositionManager;
  public despositionTypes: string[] = [];
  public despositions: Desposition[] = [];
  public selectedDespositionType: string;
  public selectedDesposition: string;
  public commentTypes: string[] = ["Phone call", "Comment", "Feedback"];
  public stage: string = "User verified";
  public type: string = "Phone call";
  today = new Date();
  jstoday = '';
  policyNumber: any;
  public comment: string = "";
  public hours = [];
  public minute: any = [];
  public PolicyNumber: any = "OG-15-1701-1801-00073894";

  public updatableUser: any;

  @ViewChild('keepOpen') _input: ElementRef;
  CarID: any;
  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }
  date: Date;

  constructor(
    public dialogRef: MatDialogRef<AccountDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    public timelineService: TimelineService,
    private stagingService: StagingService,
    public exporterService: PolicyExporterService,
    public adminbsb: AdminBSBTemplate,
    public policyService: PolicyExporterService,
    public dashboardService: DashboardService,
    public appointmentService: AppointmentService,
    private router: Router, private spinner: NgxSpinnerService) {

    super(injector);

    this.policyNumber = data.users.policyNumber;
    this.carDrivingDetails(this.policyNumber);
    this.getPolicyByPolicyNumber(this.policyNumber);
    this.owners = data.owners;
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    //this.domain= localStorage.getItem("domain");
    this.jstoday = formatDate(this.today, 'MMM d, y', 'en-US');

    this.dManager = new DespositionManager();
    this.despositionTypes = this.dManager.getTypes();
    this.selectedDespositionType = this.despositionTypes[0];
    this.despositions = this.dManager.getByType(this.despositionTypes[0]);
    for (let i = 0; i < 60; i++) {
      if (i <= 9) {
        let number = "0" + i
        this.minute.push({ "minute": number });
      }
      else {
        this.minute.push({ "minute": i });
      }

    }

    for (let j = 1; j <= 12; j++) {
      if (j <= 9) {
        let number = "0" + j
        this.hours.push({ "hour": number });
      }
      else {
        this.hours.push({ "hour": j });
      }
    }
    console.log("minutes array:");
    console.log(this.minute);


  }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);

  }

  ngAfterViewInit() {
    // console.log("Views are loaded for User timeline componenet");
    this.adminbsb.loadTag();
    $('#commentTag').tagsinput({
      maxTags: 1
    });

    this.updatableUser = this.user;
    console.log("*************CarID**************")
    this.CarID = this.user.CarId
    console.log(this.CarID);
    this.getOdometerDetails();
  }

  changeOwner(owner) {
    console.log("OWNER CHANGED CLICKED");
    console.log(owner);
    // console.log("policy number is :");
    // console.log(this.user.policyNumber);
    this.PolicyNumber = this.user.policyNumber;
    this.initialOwner = owner.value;
    this.exporterService.updateProxyDetails(this.user.domainName, "InsurancePolicy", this.user.policyNumber,
      this.initialOwner, this.user.domainId, this.authHeader).subscribe(
        data => {
          // console.log("updateProxyDetails: data: " + data.json());
          super.showSnackBar("Task assigned to " + this.initialOwner);
          // this.ownerUpdated.next(this.selectedOwner);
        },
        err => {
          console.error(err);
          super.showSnackBar("Error while changing owner to " + this.selectedOwner);
        },
        () => {
          console.log("updateProxyDetails service completed!");
        })
  }


  /* get odometer details */

  getOdometerDetails() {
    this.policyService.getOdometerDetails(this.authHeader, this.username,this.domain, this.CarID)
      .subscribe(data => {
        console.log("data : " + data);
        let odometer = data.json();
        console.log("**************odometer Details*****************");
        console.log(odometer);

      },
        error => {
          console.log(" finished with error : " + error);
          // this.pageItemCount = 0;
          // this.changeDetect.detectChanges();
        },
        () => {
          console.log("service completed!");
          // this.hidePreloader();
          // this.changeDetect.detectChanges();
        }
      );

  }


  // add comments on stage and customers
  addComment() {
    // console.log("add comment clicked");

    // console.log("comment :" + this.comment);
    // this.type = 'comment';
    // this.commentTag = 'empty';
    console.log("comment is:" + this.comment);
    console.log(this.comment)
    if (this.comment == "") {
      this.isSuccess = false;
      this.uiError = "Comment cannot be empty!";
      return;
    }

    this.timelineService.createTimeline(this.user.PolicyId, this.user.policyNumber, this.comment,
      this.type, this.commentTag, this.user.State, this.user.domainId, this.username, this.authHeader)
      .subscribe(data => {
        // console.log("data : " + data.json()); 
        this.getPolicyByPolicyNumber(this.user.policyNumber);
        this.isSuccess = true;
        this.uiError = "Comment added successfully!";
      }, err => {
        console.log("Error while adding comment, error : " + err);
        this.isSuccess = false;
        this.uiError = "Error while saving comment : " + err;
      }, () => {
        console.log("Add comment service successful!");
      });
    this.comment = "";
    this.commentTag = "";
  }

  // part of despoition and comment
  public onTypeSelected(type: string) {
    // console.log("onTypeSelected called: selected type is : " + type);
    this.type = type;
  }

  public onStageSelected(stage: string) {
    // console.log("onStageSelected called: selected stage is : " + stage);
    this.stage = stage;
  }

  /**
* On desposition type selected
*/
  public onDespositionTypeSelected(despositionType: string) {
    this.selectedDespositionType = despositionType;
    this.commentTag = this.selectedDespositionType;
    this.despositions = this.dManager.getByType(this.selectedDespositionType);
  }

  /**
   * On Desposition selected.
   * 
   * @param desposition 
   */
  public onDespositionSelected(desposition: string) {
    this.selectedDesposition = desposition;
    this.comment = desposition;
    // this.commentView.nativeElement.disabled = true;
  }
  // comment section end

  // update Policy

  public updatePolicy() {
    console.log("updatePolicy called");
    console.log("Updated Customer details:");
    console.log(this.updatableUser);

    if (this.updatableUser.hasOwnProperty('timeline')) {
      delete this.updatableUser['timeline'];
    }
    this.stagingService.load(this.updatableUser, this.authHeader)
      .subscribe(data => {
        console.log(JSON.stringify(data));
        this.getPolicyByPolicyNumber(this.user.policyNumber);
        super.showAlert("Updated successfully");

      }, err => {
        console.log(JSON.stringify(err));
        super.showAlert("Error while updating policy:  " + JSON.stringify(err));
      }, () => {
        console.log("updatePolicy service completed!");
      });
  }


  // verify users
  public verify() {
    // console.log("verify called");
    // console.log(this.user);
    if (this.user.hasOwnProperty('timeline')) {
      delete this.user['timeline'];
    }

  }

  // convert 12 hours to 24 hours time
  convertTime12to24(time12h) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    console.log("final time is:" + hours + ":" + minutes);
    return hours + ':' + minutes + ":00";
  }


  // change owner of task

  //<START>TAG input fuctions start here
  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  // remove(fruit: string): void {
  //   if (index >= 0) {
  //     const index = this.fruits.indexOf(fruit);
  //     this.fruits.splice(index, 1);
  //   }
  // }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  //<END> tag input functions end here
  onNoClick(): void {

    this.dialogRef.close();
  }

  public onCloseClicked() {

    let cancel_data = '';


  }
  /**
   * Open ticket window in new dialog
   * 
   * @param user 
   * @param policyNumber 
   */
  public openTicketWindow(policyNumber: string) {
    // [routerLink]="['dashboard',user.domainName,'freshdesk','create-ticket', user.policyNumber]"
    let link = "/#/dashboard/" + this.user.domainName + "/freshdesk/create-ticket/"
      + Utils.get2FValue(this.user.policyNumber);
    window.open("http://" + window.location.host + link, '_blank');
  }


  carDrivingDetails(poly) {
    console.log("Car driven details" + poly);
    this.policyService.CarperformanceDetails(poly, this.domain, this.authHeader, this.username)
      .subscribe(data => {
        let temp = data.json();
        console.log("**************************" + temp.length);

        console.log("car_details");
        console.log(temp);
        console.log(temp.length);

        this.car_det = temp[0];
        console.log(this.car_det);
        console.log("**************************" + temp.length);
        if (temp.length == 0) {
          this.car_det = [];
          console.log("inside the candition");
          this.car_det.TotalNightDriving = 0;
          this.car_det.TotalDistance = 0;
          this.car_det.AvgDrivingScore = 0;
          console.log("customer detail is in empty array");
          console.log(this.car_det.TotalNightDriving);
          console.log(this.car_det.TotalDistance);
          console.log(this.car_det.AvgDrivingScore);

        }

        // if(temp == [] && temp.length == 0)
        // {
        //   console.log("inside the candition");
        //   this.car_det.TotalNightDriving = 0;
        //   this.car_det.TotalDistance = 0;
        //   this.car_det.AvgDrivingScore = 0;
        //   console.log("customer detail is zero");
        //   console.log(this.car_det.TotalNightDriving);
        //   console.log(this.car_det.TotalDistance);
        //   console.log(this.car_det.AvgDrivingScore);

        // }
        console.log("data");

        console.log(this.car_det.TotalDistance);
        if (this.car_det.TotalNightDriving == undefined) {
          this.car_det.TotalNightDriving = 0;
          console.log("TotalNightDriving is null");
          console.log(this.car_det.TotalNightDriving);
        }
        if (this.car_det.TotalDistance == undefined) {
          this.car_det.TotalDistance = 0;
          console.log("TotalDistance is null");
          console.log(this.car_det.TotalDistance)
        }
        if (this.car_det.AvgDrivingScore == undefined) {
          this.car_det.AvgDrivingScore = 0;
          console.log("AvgDrivingScore is null");
          console.log(this.car_det.AvgDrivingScore)
        }
      })
  }


  /* odometer log image */
  openDialog(url): void {
    const dialogRef = this.dialog.open(OdometerDetailComponent, {
      height: '500px',
      width: '550px',
      data: { url }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  /**
   * Appointment status callback.
   * 
   * @param data 
   */
  public appointmentStatusCallback(data) {
    console.log("Appointment status callback: ", data);
    this.exercised = data;
  }
}

export interface Food {
  value: string;
  viewValue: string;
}

