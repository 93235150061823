import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class LoginService {

    //authenticate API.
    authenticateAPI:string = "/authenticate";
    
        constructor(private restClient: RestClient) {
        }
    
        login(mUsername, mPassword) {  
            console.log("LoginService: login called : username : " + mUsername +", password : " + mPassword);
            let headers = new Headers();
            headers.append('Content-Type','application/json');
    
            let authHeader = StringUtils.getAuthHeader(mUsername, mPassword);
    
            let body = {
                username : mUsername,
                password : mPassword
            }
    
            return this.restClient.execPOSTRequest(this.authenticateAPI,authHeader, body);
        }
}
