export class Server {
    static FLEET_BASE_URL: string = "https://ecu-bg-dev.mycariq.com:443/FleetIQ";

    static CARIQ_BASE_URL: string = "https://ecu-bg-dev.mycariq.com:443/Cariq";

    //static ADMIN_BASE_URL: string = "https://ecu.mycariq.com/Cariq";

    static ADMIN_BASE_URL: string = "https://baecu.mycariq.com/Cariq";

static HOST:string = "api.mycariq.com";

static PORT:string = "443";

    //static BASE_URL: string = "https://" + Server.HOST + ":" + Server.PORT + "/atom";
    //static BASE_URL: string = "https://dev-ecu.mycariq.com/atom";
     static BASE_URL: string = "https://api.mycariq.com/atom";
}
