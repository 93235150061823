import {Utils} from '../utils/Utils';
import {DateUtils} from '../utils/DateUtils';
import {AuthService} from '../core/auth/AuthService';
import {DashboardService} from '../pages/sample-component/DashboardService';
import {BaseComponent} from '../core/baseui/BaseComponent';
import { Component, OnInit, Injector } from '@angular/core';
import { StatisticsService } from "../services/StatisticsService";
import {formatDate } from '@angular/common';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.css'],
  providers: [StatisticsService, AuthService, UserService]
})
export class AccountManagerComponent extends BaseComponent implements OnInit {

  public static predefinedStages:string[] = ['Car Connected', 'Device Onboarded', 'Device Delivered', 'App Not Downloaded'];

  public selectedFood:any = "Poornima G.";

  public statsOfOwner:any = [];

  public statsByOwnerAndDate:any;

  public stages:Stage[] = [];

  public stat_days:any;

  public username;

  public authHeader;

  public userData;

  public domain;
  /**
   * Select options
   */

   
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Sweta'},
  ];

  constructor(
          public injector:Injector, 
          public statsService:StatisticsService,
          public authService:AuthService,
          public userService:UserService) {
          super(injector);
          this.username = localStorage.getItem("USERNAME");
          this.authHeader = localStorage.getItem("AUTH");
          //this.domain = localStorage.getItem("domain");

          this.execGetUserService(this.username, this.authHeader)
  }

  /**
   * Get account agent data
   */ 
  public execGetUserService(username:string, authHeader:string) {
      console.log("execGetUserService called");

      this.userService.getUser(username, authHeader).subscribe(
        data => {
          this.userData = data.json();
          console.log("User details:  user: ");
          console.log( this.userData);

        },
        err => {
          console.log("Error while fetching user : " + err);
        },
        () => {
          console.log("Get user service completed!");
        }
      );
    }



  ngOnInit() {
    this.selectedFood = "Poornima G.";
    this.getStatsForOwner(this.authService.getUsername(), this.authService.getBasicAuth());
    this.getStatsByOwnerAndDate(this.authService.getUsername(), this.authService.getBasicAuth());
  }

  /**
   * Get stats for owner
   */
  public getStatsForOwner(username:string, authHeader:string) {
    this.statsService.getStatisticsForOwner(username, authHeader).subscribe(
      data=>{
        let statsData = data.json();
        console.log("Dashboard is: ");
        console.log(statsData);
        this.statsOfOwner = this.enhanceStatsOfOwner(statsData);
      },
      err=>{
        super.showAlert(err.json());
      },
      ()=>{
        console.log("GetStatistics for owner service completed!");
      }
    );
  }

  /**
   * Enhance stats of owner.
   * 
   * @param statsOfOwner 
   */
  public enhanceStatsOfOwner(statsData:any) : any {
      let statsOfOwner = [];

      //push car connected data
      let carConnected = this.getStatsObjectByStage(statsData, 'Car Connected');
      statsOfOwner.push({stage: "Connected Cars", count:  carConnected != null ?  carConnected.count : 0});
      
      //push Device onboarded data
      let onboarded = this.getStatsObjectByStage(statsData, 'Device OnBoarded');
      statsOfOwner.push({stage: "Device onboarded", count:  onboarded != null ?  onboarded.count : 0});

      //push Device delivered data
      let delivered = this.getStatsObjectByStage(statsData, 'Device Delivered');
      statsOfOwner.push({stage: "Device Delivered", count:  delivered != null ?  delivered.count : 0});

      //push device not requested data
      let notRequested = this.getStatsObjectByStage(statsData, 'User Created');
      statsOfOwner.push({stage: "Device Not Requested", count:  notRequested != null ?  notRequested.count : 0});

      return statsOfOwner;
  }

  /**
   * Get stats object by stage
   * 
   * @param stage 
   */
  private getStatsObjectByStage(dataArr:any, stage:string) : any {
    for(let i = 0; i < Object.keys(dataArr).length; i++) {
      let object = dataArr[i];
      if(object.stage == stage)
        return object;
    }
    return null;
  }

  /**
   * Get Stats By Owner and date
   * 
   * @param username 
   * @param authHeader 
   */
  public getStatsByOwnerAndDate(username:string, authHeader:string) {
    this.statsService.getStatisticsByOwnerAndDate(authHeader, username)
    .subscribe(data=>{
        let dataJson = data.json();
        console.log("dataJson: " + dataJson);
        let uniqueStages = Utils.getValuesForProperty(dataJson, "stage");
        for(let i = 0; i < Object.keys(AccountManagerComponent.predefinedStages).length; i++) {
          let uniquestage = AccountManagerComponent.predefinedStages[i];
          this.stages.push(new Stage(uniquestage, dataJson));
        }
        console.log("stages: ");
        console.log( this.stages);
        this.stat_days = this.stages[0];
        console.log(this.stat_days);

    }, err=>{
      let errData = err.json();
      super.showAlert(errData);
    },
    ()=>{ console.log("GetStatsByOwnerAndDate completed!")});
  }
}

export interface Food {
  value: string;
  viewValue: string;
}

/**
 * Stage class
 */
export class Stage {
  dates:StageDate[] = [];
  public name_of_days:any;

  /**
   * Stage constructor
   * 
   * @param name 
   * @param data 
   */
  constructor(public name:string, private data:any) {

    //get date array from current date
    this.createAndInitStageDates();
  }

  public createAndInitStageDates() {
    //get all dates for this week
    let dates:string [] = DateUtils.getDateArrFromGivenDate(new Date(), 6, DateUtils.FORMAT_DATE);

    //iterate on dates array
    for(let i = 0; i < dates.length; i++) {
      let currentDate = dates[i];
      let aNewStateDate = null;

      //iterate on backend data and find, whether data exists or not for given 
      //stage and date
      for(let j = 0; j < Object.keys(this.data).length; j++) {
        let object:any = this.data[j];

        //If data exists then add that data to stageDates array
        if(object.date == currentDate && object.stage == this.name) {
           aNewStateDate = new StageDate(object.date, object.count);
        } 
      }

      //If does not exists then add placeholder date with 0 count.
      if(aNewStateDate == null) 
        aNewStateDate = new StageDate(currentDate, 0);

      //finally push object in array
      this.dates.push(aNewStateDate);
    }
    console.log("Stat table data: ");
    console.log(this.dates);
  }
}

/**
 * Stage Date
 */
export class StageDate {
  constructor(public date:string, public count:number) {
    //no implementation
  }
}