import { Domain } from './Domain'
import { FilterModel, DashboardFilterModel } from "../DashboardFilterModel";

export class DrivenDomain implements Domain {
    
    getIcon(): string {
        return "ic_driven.png";
    }

    getMobileAppName(): string {
        return "CarIQ Driven";
    }
    getDomainTelematicsProductName(): string {
        return "Kotak Mahindra Pvt Ltd bank loan";
    }

    public filterModel:DashboardFilterModel;

    private drivenFilters:FilterModel[] = []; 
    
    constructor() { 
        this.filterModel = new DashboardFilterModel();
    }

    getName(): string {
        return "Driven";
    }
    getPlayStoreLink(): string {
        return "https://getdriven.mycariq.com";
    }

    getDashboardFilters(): FilterModel[] {
        let allFilters:FilterModel[] = this.filterModel.getAllFilters();
        return allFilters;
    }

    getCompanyName(): string {
        return "CarIQ technologies ltd";
    }
}
