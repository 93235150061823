import { Component, OnInit, Inject } from '@angular/core';
import { Report } from '../pages/download-report/download-report.component';
import { ReportsComponent } from '../reports/reports.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import {LoaderService} from './../services/LoaderService';
import {MatFormFieldModule} from '@angular/material/form-field';
import { UserService } from './../services/user.service';
import { BaseComponent } from "./../core/baseui/BaseComponent";
import Swal from 'sweetalert2';
import { Dialogue1Component } from '../dialogue1/dialogue1.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  IsmodelShow: boolean;
  url:any;
  temp_data: any = [];
  temp: any;
  FinalURL: string;
  filename: any;
  heading: any;
  ReportFile: any;

  constructor(public dialogRef: MatDialogRef<Dialogue1Component>,@Inject(MAT_DIALOG_DATA) 
  public data: ReportsComponent, public sanitizer: DomSanitizer) {}

  ngOnInit() {
    console.log("downloaded data");
    console.log(this.data);
    this.url = this.data;
    this.temp_data =  this.data;
    this.temp = this.sanitizer.bypassSecurityTrustResourceUrl(this.temp_data.url);
    this.filename = this.temp_data.Filename;
    this.heading = this.temp_data.heading;
    var str = this.heading;
    console.log("Original data: ",str);
    str = str.slice(0, -8);
     this.ReportFile = str;
     console.log("after data: ",this.ReportFile);
    //this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);


  }
  onNoClick(): void {
    this.dialogRef.close();
   }

   /* Download Html file */

FileDownload(){
  this.FinalURL = '<iframe _ngcontent-c7="" style="height:100%; width:100%" frameborder="0" id="frame" src="'+ this.temp_data.url +'"></iframe>'
  this.finaldownload(this.FinalURL);
}

finaldownload(data){
  const a = document.createElement('a');
  document.body.appendChild(a);
  const blob = new Blob([data], { type: 'application/text' }),
    url = window.URL.createObjectURL(blob);
  console.log(navigator);
  var str = this.filename;
    console.log("Original data: ",str);
    str = str.slice(0, -5);
    var file = str;
    console.log("After truncate: ",file);
  if (navigator.appVersion.toString().indexOf('.NET') > 0 && window.navigator.msSaveBlob) {
    console.log('from Explorer', window.navigator);
    let val = window.navigator.msSaveBlob(blob, file + '.html');
    console.log(val)
  }
  else {
    a.href = url;
    a.download = file  + '.html';
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
}
