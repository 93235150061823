import {DateUtils} from '../utils/DateUtils';
import {Injectable} from '@angular/core';
import {RestClient} from '../rest_client/RestClient'

@Injectable()
export class StatisticsService {

    /**
     * Constructor
     * 
     * @param restClient 
     */
    constructor(private restClient: RestClient) {
        
    }

    /**
     * Get spotlight statistics
     * 
     * @param authHeader 
     * @param owner 
     * @param domain 
     */

    public getSpotlightStatistics(authHeader:string, owner:string, domain:string) {

        let endPoint = `/atomAdmin/exporters/exportMultiple/AccountManagerStatistics/SpotlightStats/1/100`;

        let body = {
            "owner":owner,
            "domain":domain
        }

        return this.restClient.execPOSTRequest(endPoint, authHeader, body);
    }

     /**
     * Get Statistics for owner
     * 
     * @param username 
     * @param authHeader 
     */
    public getStatisticsForOwner(username:string, authHeader:string) {
        let endPoint = `/atomAdmin/exporters/exportMultiple/AccountManagerStatistics/ByOwner/1/100`;
        let body = {
            "owner":username
        }
        return this.restClient.execPOSTRequest(endPoint, authHeader, body);
    }


    /**
     * Get statistics for username and date
     * 
     * @param authHeader 
     * @param username 
     * @param date 
     */
    public getStatisticsByOwnerAndDate(authHeader:string, username:string) {
        let endPoint = `/atomAdmin/exporters/exportMultiple/AccountManagerStatistics/ByOwnerAndDay/1/100`;
        let body = {
            "owner":username,
            "date": DateUtils.formatDate(new Date(), DateUtils.FORMAT_DEFAULT)
        }
        return this.restClient.execPOSTRequest(endPoint, authHeader, body);
    }

}