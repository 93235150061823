import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyExporterService } from '../.././services/policy-exporter.service';
import { DashboardService } from '../.././pages/sample-component/DashboardService';
import { UserService } from '../.././services/user.service'
import { Domain } from '../.././core/domain/Domain'
import { DomainManager } from '../.././core/domain/DomainManager'
import { AuthService } from './../../core/auth/AuthService';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/dialog/dialog.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public domainData;
  public domain:string;
  public domains;
  username:string;
  authHeader:string;
  public user:any;
  animal: string;
  name: string;
  tickerdate = new Date();
 
  foods = [
    {value: 'ICICI', viewValue: 'ICICI'},
    {value: 'IL-Assist', viewValue: 'IL-Assist'},
    {value: 'Tagic', viewValue: 'TAGIC'},
    {value: 'Driven_dev', viewValue: 'DRIVEN_DEV'}
  ];

  constructor(private route :  ActivatedRoute, 
    private policyService: PolicyExporterService,
    private dashboardService: DashboardService,
    private userService: UserService,private authService: AuthService,public dialog: MatDialog) { }

  ngOnInit() {
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.execGetDomainService(this.username,this.authHeader);
    this.execGetUserService(this.username, this.authHeader);
    this.tickerdate.setDate(this.tickerdate.getDate() - 1);

  }

  public execGetUserService(username:string, authHeader:string) {
    console.log("execGetUserService called");

    this.userService.getUser(username, authHeader).subscribe(
      data => {
        var userData = data.json();
        console.log("User details:  user: " + userData);

        this.user = userData;

        this.execGetDomainService(this.username, this.authHeader);
      },
      err => {
        console.log("Error while fetching user : " + err);
      },
      () => {
        console.log("Get user service completed!");
      }
    );
  }

 /*  public logout() {
    console.log("Logout clicked");

    this.authService.logout();
    this.dashboardService.invalidate();
  }
 */

  public execGetDomainService(username:string, authHeader:string) {
    console.log("execGetDomainService called");

    this.userService.getDomain(username, authHeader).subscribe(
      data=> {
        this.domainData = data.json();
        this.domains = DomainManager.getDomains(this.domainData);
        console.log("Domain details : domain : " );
        console.log(this.domainData);
        if(this.dashboardService.getDomain() == null) {
          this.domain = this.domainData[0].name;
          this.dashboardService.setDomain(this.domain);
        } else {
          this.domain = this.dashboardService.getDomain();
        }

        console.log("************* Final Domain: ");
        console.log(this.domain);
      },
      err => {
        console.error("Error while fetching domain");
      },
      () => {
        console.log("Get domain service completed!");
      }
    )
  }
  logout(){
    this.authService.logout();
    this.dashboardService.invalidate(); 
  
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass :'myclass',
      width: '430px',
      height:'430px',
      
        
      data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

}