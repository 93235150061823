import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-dialog',
  template: `
  <div class="loaderbox" style="background:red">
          <div class="preloader m-l-30">
                  <div class="spinner-layer">
                      <div class="circle-clipper left">
                          <div class="circle"></div>
                      </div>
                      <div class="circle-clipper right">
                          <div class="circle"></div>
                      </div>
                  </div>
              </div>  
      <p class="m-b-10 m-t-10">Authenticating with server...</p>
  
</div>
`,
  styles: []
})
export class ProgressDialogComponent implements OnInit {

  constructor() { 
    
  } 

  ngOnInit() {
  }

}
