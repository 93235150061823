import { Component, OnInit,Inject, Injector } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {LoaderService} from './../services/LoaderService';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ReportsComponent } from '../reports/reports.component';
import { UserService } from './../services/user.service';
import { BaseComponent } from "./../core/baseui/BaseComponent";
import Swal from 'sweetalert2';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-dialogue1',
  templateUrl: './dialogue1.component.html',
  styleUrls: ['./dialogue1.component.css'],
  providers : [LoaderService,UserService]
})
export class Dialogue1Component implements OnInit {

  public authHeader: string;
  domain;
  file:any;

  public text:string = "";
  username: string;

  constructor(

    public dialogRef: MatDialogRef<Dialogue1Component>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ,public loaderservice:LoaderService,public userService:UserService,public injector:Injector,
    
    ) 
    {
      this.username = localStorage.getItem("USERNAME");
      this.domain = localStorage.getItem("domainName");
     }

    onNoClick(): void {
    this.dialogRef.close();
  
   
    
     }

    ngOnInit() {
    this.authHeader = localStorage.getItem("AUTH");
    console.log("Auth header retrived from local : " + this.authHeader);
    this.domain = localStorage.getItem("domainName");

    console.log("domain is" + this.domain);
    this.loadUsers(this.username,this.authHeader);

    
  }


  public loadUsers(username: string, authHeader: string) {
    console.log("SampleComponent: loadUsers called");
    console.log('this is domain' + this.domain);
    this.userService.getDomain(this.username, this.authHeader).subscribe(
      data => {

      console.log("Domain is &&&&&&&&&&&&&&&&&&");
      console.log(data.json());
      let res = data.json();
      console.log(res[0].name);
      this.domain = res[0].name;
      console.log('this is domain ***********************'+this.domain);
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("getDomain service completed!");
      }
    )
  }


   upload(){
 
     this.loaderservice.uploadFile1(this.authHeader,this.domain,this.file, this.text).subscribe(
      data=>{
       
        console.log('file Uploaded Successfully');
         this.dialogRef.close();
         Swal.fire('File Uploaded successfully')
       
      }
   )
  } 


/**
* Handle file input
*/
public handleFileInput(files: FileList) {
  this.file = files.item(0);



  console.log(this.file);
} 

}
