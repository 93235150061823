import { Component, OnInit, Inject } from '@angular/core';
//import { MatDialog } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
export interface PeriodicElement {
  name: string;
  entry_date: string;
  mobile: number;
  email: string;
  // priority:string;
  Effective_Date: string;
  city: string;
  stage: string;
  last_action: string;
  action: string;
}
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
policy_no:any;

  constructor(public dialogRef: MatDialogRef<DialogComponent>,@Inject(MAT_DIALOG_DATA) public data : any) {
     
console.log('data');
console.log(data);

  }
  onNoClick(): void {
  
    this.dialogRef.close();
  }
  ngOnInit() {
    
  }

}
