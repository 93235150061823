import { RestClient } from './../rest_client/RestClient';
//import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material'
import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { LoginService } from '../services/login.service';
//import { Router } from '@angular/router';
import { AuthService } from '../core/auth/AuthService';
import { StringUtils } from '../utils/StringUtils'
import { BaseComponent } from '../core/baseui/BaseComponent';
import { ProgressDialogComponent } from '../pages/utils/progress-dialog/progress-dialog.component'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';





@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, RestClient]
})
export class LoginComponent implements OnInit {
//username: string;
//password: string;

public responseMessage;
public username:string = "";
public password:string = "";
pswd_error = false;
public auth : string = "";
public domain: any;
heroForm =new  FormGroup({
  loginusername : new FormControl('',Validators.required),
  password : new FormControl('',Validators.required)
});

  constructor(private loginService: LoginService, private router: Router, private formBuilder: FormBuilder,
    private zone:NgZone, injector : Injector, public authService : AuthService, private spinner:NgxSpinnerService) 
    { 
     // super(injector);

      // if(this.authService.isLoggedIn) {
      //   this.navigate('atomx');
      // }
  }
  ngOnInit() {
  }
  /* doLogin():void {
    if(this.username == 'demo' && this.password == 'demo123'){
     this.router.navigate(["/ubi"]);
    }else {
      alert("Invalid credentials");
    }
    
  }
}
 */
public doLogin() {
  this.spinner.show();
  console.log("Login button clicked");
  this.pswd_error = false;
  if(this.username == null || this.password == null) {
    //If username and password is empty then show user warning message.
    return;
  }
  // let dialogRef = super.showDialog(ProgressDialogComponent, false);

  let encryptedPass = StringUtils.convertToMD5(this.password);
  console.log(encryptedPass);
  
  //disable button and enable progressbar

  //Call API, and take a action based on response
    this.loginService.login(this.username, encryptedPass).subscribe(
        data => {
          
            //disable progress bar

            this.responseMessage = data.json();
            console.log('SERVER RESPONSE: ' , data.toString);
            console.log('SERVER RESPONSE: ' , this.responseMessage);

            this.auth = StringUtils.getAuthHeader(this.username, encryptedPass);
            localStorage.setItem("AUTH", this.auth);
            localStorage.setItem("USERNAME", this.username);
            
            
            this.authService.login();

            //dialogRef.close();

            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            // let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : 'atomx';
            this.spinner.hide();
            this.router.navigate(["/hub"]);
            //this.navigate(redirect);
        },
        err => {
          //show user authentication is failed
            console.log(err);
            // dialogRef.close();
            // super.showAlert("Wrong username or password!");
            this.pswd_error = true;
            this.spinner.hide();

        },
        () => {
            console.log('Login service completed');             
        }
    );
  }

  public navigate(url) {
    console.log("navigate called");
    this.zone.run( () => {
      this.router.navigate([url]);
    });
  }
}
