import {Utils} from '../../utils/Utils';
import { Desposition } from "./Desposition";


/**
 * Holds all despositions
 */
export class DespositionManager {
    public despositions:Desposition[] = [];

    /**
     * Default constructor
     */
    constructor() {
        this.despositions.push(new Desposition("NOT_INTERESTED", "I don't need the device."));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Discouraged by car dealer"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Worried about car warranty"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Using other device"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Lid doesn't close even with cable"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Hurts the leg"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Doesn't want to make any alterations"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Low comfort level with installing the device"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Procrastinating user"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Terms and conditions not acceptable"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Smartphone not available"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Phone purchased abroad : Cannot download app"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Customer unable to use smart phone"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Customer is busy always"));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Customer does not want to request for device."));
        this.despositions.push(new Desposition("NOT_INTERESTED", "Customer not very keen in device installation."));

        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Invalid/Incorrect number"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Mobile and/ or email ID number is missing"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Wrong number"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Language barrier"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Ringing Not Received"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Switched off"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Not reachable/Out of service"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Line busy"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Call waiting"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Call declined"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Call dropped"));
        this.despositions.push(new Desposition("NOT_CONTACTABLE", "Call disconnected abruptly"));

        this.despositions.push(new Desposition("Snooze", "Out of town for more than 1 week"));
        this.despositions.push(new Desposition("Snooze", "I will self install."));
        this.despositions.push(new Desposition("Snooze", "Don't call me. I will call you."));
        this.despositions.push(new Desposition("Snooze", "Call back after 10 days."));
        this.despositions.push(new Desposition("Snooze", "Vehicle is given for servicing. Call back later."));
        this.despositions.push(new Desposition("Snooze", "Device misplaced. Customer will search for device."));
        this.despositions.push(new Desposition("Snooze", "I will visit a mechanic and install later."));

        this.despositions.push(new Desposition("Active", "Call back"));
        this.despositions.push(new Desposition("Active", "Verified"));
        this.despositions.push(new Desposition("Active", "Password Noted"));
        this.despositions.push(new Desposition("Active", "Device requested"));
        this.despositions.push(new Desposition("Active", "Onboarded"));
        this.despositions.push(new Desposition("Active", "Customer insists on field installation."));
        this.despositions.push(new Desposition("Active", "Waiting on courier company."));
        this.despositions.push(new Desposition("Active", "Second device shipped"));

        this.despositions.push(new Desposition("Invalid order", "Test"));
        this.despositions.push(new Desposition("Invalid order", "Policy Cancelled"));
        this.despositions.push(new Desposition("Invalid order", "Duplicate"));
        this.despositions.push(new Desposition("Invalid order", "Policy expired"));
        this.despositions.push(new Desposition("Invalid order", "Carnot"));
        
    }

    /**
     * Get despositions;
     */
    public getDespositions() : Desposition[] {
        return this.despositions;
    }

    /**
     * Get all unique types
     */
    public getTypes() : string[] {
        let types:string[] = [];
        return Utils.getValuesForProperty(this.despositions, "type");
    }

    /**
     * Get all despositions for given type
     * @param type 
     */
    public getByType(type:string) : Desposition[] {
        let despositionsByType:Desposition[] = [];
        for(let i = 0; i < Object.keys(this.despositions).length; i++) {
            let desposition = this.despositions[i];
            if(desposition.type == type)
                despositionsByType.push(desposition);
        }
        return despositionsByType;
    }
}