import { Component, OnInit, Inject } from '@angular/core';
import { AccountDetailsComponent } from '../account-details/account-details.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-odometer-detail',
  templateUrl: './odometer-detail.component.html',
  styleUrls: ['./odometer-detail.component.css']
})
export class OdometerDetailComponent implements OnInit {

  temp;

  constructor(public dialogRef: MatDialogRef<AccountDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer) { 

      
    }
    
    
    onNoClick(): void {
      this.dialogRef.close();
    }

  ngOnInit() {
    console.log("downloaded data");
    console.log(this.data);
    this.temp = this.data;
    this.temp = this.sanitizer.bypassSecurityTrustResourceUrl(this.temp.url);
  }

  
}
