import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import ExportingModule from 'highcharts/modules/exporting';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementRef, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UserService } from '../services/user.service'
import { PolicyExporterService } from '../services/policy-exporter.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as FusionCharts from 'fusioncharts';
import { DashboardService } from '.././pages/sample-component/DashboardService';
import { DomainManager } from '.././core/domain/DomainManager'
import { AuthService } from '../core/auth/AuthService';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import 'jquery-mapael';
import { Http, Headers, RequestOptions } from '@angular/http';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';
import { Observable } from 'rxjs/Rx'
import { distinct } from 'rxjs-compat/operator/distinct';
import { timeout } from 'rxjs-compat/operator/timeout';
/* import { setTimeout } from 'timers'; */
import * as _ from 'lodash';
import { state } from '@angular/animations';
import html2canvas from 'html2canvas';

ExportingModule(Highcharts);
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-riskscore',
  templateUrl: './riskscore.component.html',
  styleUrls: ['./riskscore.component.css']
})
export class RiskscoreComponent implements OnInit {


  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  cal;
  distance = "500";
  overSpeedingPercent = "20";
  travelTimeInNight = "500";
  idlingPercent = "10";
  rashBrakingCount = "10";
  rashAccelerationCount = "10";
  total_score: any;
  values: any;
  // value1 = '10';
  // value2 = '10';
  // value3 = '20';
  // value4 = '10';
  // value5 = '500';
  // value6 = '500';
  total;
  alldata;
  detail;
  username: string;
  authHeader: string;
  public domainData;
  public domain: string;
  public domains
  public user: any;

  // onEnter1(value1: any) { this.value1 = value1; }
  // onEnter2(value2: any) { this.value2 = value2; }
  // onEnter3(value3: any) { this.value3 = value3; }
  // onEnter4(value4: any) { this.value4 = value4; }
  // onEnter5(value5: any) { this.value4 = value5; }
  // onEnter6(value6: any) { this.value5 = value6; }

  constructor(private spinner: NgxSpinnerService, private userService: UserService, private policyService: PolicyExporterService,
    private changeDetect: ChangeDetectorRef, private route: ActivatedRoute
    , private dashboardService: DashboardService, private authService: AuthService, public dialog: MatDialog, private http: Http) {

    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.domain = localStorage.getItem("domainName");

    console.log('******Domain***********************************:' + this.domain);

  }

  ngOnInit() {
    this.spinner.show();
    this.authHeader = localStorage.getItem("AUTH");
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.execGetDomainService(this.username, this.authHeader);
    this.execGetUserService(this.username, this.authHeader);
    this.route.params.subscribe(params => {
      this.domain = params['domain'];
      this.riskscore();
      //this.riskscalculation();
      this.spinner.hide();

    });
    //score
    //this.onclick();
  }

  public execGetUserService(username: string, authHeader: string) {

    this.userService.getUser(username, authHeader).subscribe(
      data => {
        var userData = data.json();
        this.user = userData;
        this.execGetDomainService(this.username, this.authHeader);
      },
      () => {
        //console.log("Error while fetching user : " + err);
      },
      () => {
        //console.log("Get user service completed!");
      }
    );
  }

  public execGetDomainService(username: string, authHeader: string) {
    //console.log("execGetDomainService called");

    this.userService.getDomain(username, authHeader).subscribe(
      data => {
        this.domainData = data.json();
        this.domains = DomainManager.getDomains(this.domainData);
        //console.log("Domain details : domain : ");
        //console.log(this.domainData);
        if (this.dashboardService.getDomain() == null) {
          this.domain = this.domainData[0].name;
          this.dashboardService.setDomain(this.domain);
        } else {
          this.domain = this.dashboardService.getDomain();
        }
        //console.log("************* Final Domain: ");
        //console.log(this.domain);
      },
      () => {
        //console.error("Error while fetching domain");
      },
      () => {
        //console.log("Get domain service completed!");
      }
    )
  }

  /**
   * on Score button click event
   */
  // public onclick() {
  //   console.log("function call");
  //   this.total = this.value1 + this.value2 + this.value3 + this.value4 + this.value5 + this.value6;
  //   this.total_score = this.total / 10;
  //   console.log("function call");
  //   console.log(this.total_score);
  // }

  /* download map image
  */
  downloadImage() {
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'Hitmap.png';
      this.downloadLink.nativeElement.click();
    });
  }

  /**
   * Riskscoring chart
   */
  riskscore() {
    this.policyService.getRiskScore(this.authHeader, this.username, this.domain)
      .subscribe(data => {
        this.detail = data.json();
        console.log("count all data");
        console.log(this.detail);
        this.values = this.detail.parameterDefinitions;
        let dat = this.values[0].maxValue;
        console.log(dat);

        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
          var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
          ];
          return path;
        };

        var categories = ['Rash Breacking', 'Rash Acceleration', 'Overspeeding', 'Idealing', 'Night Driving', 'Total Driving']

        Highcharts.chart('container', {
          chart: {
            type: 'bar'
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Risk Scoring Factors'
          },
          xAxis: [{
            categories: categories,
            //reversed: false,
            labels: {
              step: 1,
              style: {
                color: 'red'
              }
            }
          }, { // mirror axis on right side
            // categories: categories,
            opposite: true,
            // linkedTo: 0,
            // labels: {
            //   step: 1,
            //   style: {
            //     color: 'red'
            //   }
            // }
          }],
          yAxis: {
            title: {
              text: null
            },
            // labels: {
            //   formatter: function () {
            //     return Math.abs(this.value) + '';
            //   }
            // }
          },
          plotOptions: {
            series: {
              stacking: 'normal',
            },
            column: {
              pointWidth: 10,
              pointPadding: 0.6,
              borderWidth: 0

            }
          },

          series: [{
            name: 'Weightage',
            type: 'bar',
            pointWidth: 15,

            color: 'rgb(255,126,0)',
            data: [
              this.values[0].weightage, this.values[1].weightage, this.values[2].weightage, this.values[3].weightage, this.values[4].weightage, this.values[5].weightage
            ]
          }],
          exporting: {
            buttons: {
              contextButton: {
                symbol: 'download',
                symbolStroke: 'rgb(41, 195, 190)'
              }
            }
          }
        });
      })

  }

  /**
   * Risk calculation
   */
  riskscalculation() {
    this.policyService.DrivingScore(this.distance, this.overSpeedingPercent, this.travelTimeInNight, this.idlingPercent, this.rashBrakingCount, this.rashAccelerationCount, this.authHeader, this.username)
      .subscribe(data => {
        this.cal = data.json();
        console.log(" all data");
        console.log(this.cal);
      })

  }

}
