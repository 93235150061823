import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class TagService {

    
        constructor(private restClient: RestClient) {
        }
    
        /**
         * Create new tag
         * 
         * @param name 
         * @param description 
         * @param authHeader 
         */
        createTag(name, description, authHeader) {  
            console.log("TagService: createTag called : name : " + name +", description : " + description);


            //authenticate API.
            let createTagAPI:string = "/tag/add";

            let headers = new Headers();
            headers.append('Content-Type','application/json');

            
    
            let body = {
                "name": name,
                "description": description
            }
    
            return this.restClient.execPOSTRequest(createTagAPI, authHeader, body);
        }


        /**
         * Get all tags by pagination and authHeader
         * 
         * @param pageNo 
         * @param pageSize 
         * @param authHeader 
         */
        getAllTags(pageNo:number, pageSize:number, authHeader) {
            //Get all tags endpoint
            let getAllTagsAPI:string = `/tag/getAll/${pageNo}/${pageSize}`;

            return this.restClient.execGETRequest(getAllTagsAPI, authHeader, false);
        }


        /**
         * Get tags by object id.
         * 
         * @param objectId 
         * @param authHeader 
         */
        getTagsByObjectId(signature, domainName, authHeader, className) {

            signature = signature.replace(/\//g, "XX");

            let getTagsByObjectAPI="/tag/getTagsByProxySignature/"+domainName+"/"+signature+"/"+className;

            return this.restClient.execGETRequest(getTagsByObjectAPI, authHeader, false);
        }


        /**
         * Tag given object by tag name
         * 
         * @param tagName 
         * @param objectId 
         * @param authHeader 
         */
        tagGivenObjectId(tagName, domainName, signature, authHeader, proxy) {
            
            let body  = {
                "tagname": tagName,
                "signature": signature,
                "domainName": domainName,
                "objectType": proxy
            }

            let tagObjectAPI = `/tag/createAndTagProxy`;
            return this.restClient.execPOSTRequest(tagObjectAPI, authHeader, body);
        }

        untagObject(tagName, domainName, signature, authHeader, className) {

            let untagObjectAPI = `/tag/untagProxyByProxyDetails`;

            let body = {
                "tagname": tagName,
                "signature": signature,
                "domainName": domainName,
                "objectType": className
            }

            return this.restClient.execDELETERequest(untagObjectAPI, body, authHeader);
        }
}
