import { AuthGuard } from './core/auth/AuthGuard';
import { ProgressDialogComponent } from './pages/utils/progress-dialog/progress-dialog.component';
import { AdminBSBTemplate } from './core/adminbsb';
import { DynamicComponentLoader } from './pages/pagehelpers/DynamicComponentLoader';

import { RestClient } from './rest_client/RestClient';
import { APPBootComponent } from './app-boot/app-boot.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http'; 
import { FormControlService } from '../../src/app/core/dynamicformgroup/FormControlService';
import { AuthService } from '../../src/app/core/auth/AuthService';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { SidebarComponent } from './common/sidebar/sidebar.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { RouterModule, Routes } from '@angular/router';
import { ExporterService } from "./services/ExporterService";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule ,ChartsModule} from 'angular-bootstrap-md';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatSnackBarModule, MatMenu, MatMenuTrigger, MatPaginatorModule } from '@angular/material';  
import { PolicyExporterService } from '../../src/app/services/policy-exporter.service';
import {DashboardService} from '../../src/app/pages/sample-component/DashboardService';
import {MatMenuModule} from '@angular/material/menu';
import { DynamicFormComponent } from "./core/dynamicformgroup/dynamic-form-component/DynamicFormComponent";
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import {MatButtonModule,
   MatCheckboxModule,
   MatFormFieldModule,
   MatSelectModule,
   MatGridListModule,
} from '@angular/material';

import { BrowserModule } from '@angular/platform-browser';

import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogComponent } from './dialog/dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {} from 'chart.js';


//import { SampleComponentComponent } from './pages/sample-component/sample-component.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRippleModule, MatCommonModule} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import { MyDatePickerModule } from 'mydatepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import { MatIconModule } from "@angular/material/icon"
import { UserService } from './services/user.service';
import { TimelineService } from './services/timeline.service';
import { UserDetailBaseComponent } from './pages/user-detail/UserDetailBaseComponent';
import { UserStageDetailComponent } from './pages/user-detail/user-stage-details.component';
import { UpdateOwnerComponent } from './pages/utils/update-owner/update-owner.component';
import { UserTimelineDetailComponent } from './pages/user-timeline-detail/user-timeline-detail.component';
import {CdkTableModule} from '@angular/cdk/table';
//import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { ReportsComponent } from './reports/reports.component';
import { Dialogue1Component } from './dialogue1/dialogue1.component';
import { ViewComponent } from './view/view.component';
import { GraphComponent } from './graph/graph.component';
import { DeepdiveComponent } from './deepdive/deepdive.component';
import { SampleComponentComponent } from './pages/sample-component/sample-component.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { RiskscoreComponent } from './riskscore/riskscore.component';
import { OdometerDetailComponent } from './odometer-detail/odometer-detail.component';

// Add dependencies to FusionChartsModule
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme)

@NgModule({
declarations: [
AppComponent,
LoginComponent,
SidebarComponent,
NavbarComponent,
APPBootComponent,
DialogComponent,
DashboardComponent,
SampleComponentComponent,
ProgressDialogComponent,
UserStageDetailComponent,
UpdateOwnerComponent,
UserTimelineDetailComponent,
DynamicFormComponent,
AccountDetailsComponent,
AccountManagerComponent,
ReportsComponent,
Dialogue1Component,
ViewComponent,
GraphComponent,
DeepdiveComponent,
RiskscoreComponent,
OdometerDetailComponent

/* MatPaginatorModule */
],
imports: [
  HttpModule,
  HttpClientModule,
BrowserModule,
MatToolbarModule,
AppRoutingModule,
NgxSpinnerModule,
FormsModule, 
ReactiveFormsModule,
MatButtonModule, 
MatCheckboxModule,
MatFormFieldModule,
MatSelectModule,
MatSidenavModule,
MatCardModule,
BrowserModule, 
ChartsModule,
MatSnackBarModule,
MatPaginatorModule,
FusionChartsModule,
/* MatMenuTrigger,
 */MatTableModule,
MatDatepickerModule,
MatRadioModule,
MatTableModule,
MatDialogModule,
MatAutocompleteModule,
BrowserModule,
MatInputModule,
MatGridListModule,
MDBBootstrapModule ,ChartsModule,
HttpModule,
HttpClientModule,
MatMenuModule,
MatPaginatorModule,
MatDialogModule,   
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTableModule,
    MatFormFieldModule,
    MyDatePickerModule,
    MatRadioModule,
    MatIconModule,
  /*  ider MatSnackBarModule, */
    MatChipsModule,
    MatToolbarModule, MatTabsModule, MatRippleModule, MatCheckboxModule, 
    MatSidenavModule, NgxSpinnerModule, MatButtonToggleModule,
    CdkTableModule,
    BrowserAnimationsModule
],
providers: [
  RestClient,
  ExporterService,
  FormControlService,
  AuthService,
  PolicyExporterService,
  DashboardService,
  UserService, 
  DynamicComponentLoader,
  AdminBSBTemplate,
  UserService,
  TimelineService,
  AuthGuard,
  { provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} },
  { provide: LoginComponent, useClass:LoginComponent  }
],
bootstrap: [AppComponent],
entryComponents: [
    DialogComponent,
    ProgressDialogComponent,
    UserStageDetailComponent,
    AccountDetailsComponent,
    Dialogue1Component,
    ViewComponent,
    OdometerDetailComponent
  ],

})
export class AppModule { }