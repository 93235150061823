import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-boot',
  templateUrl: './app-boot.component.html',
  styleUrls: ['./app-boot.component.css']
})
export class APPBootComponent implements OnInit {
map:any;
  constructor() { }

  ngOnInit() {

    /*
  * Google map initalization. load map when page is loaded
 */

  }

}
