
export class PolicyHelper {

    /**
     * No of calls for current policy
     * @param policyJSON 
     */
    public static getNumberOfCalls(policyJSON: any, objectType):number {
        let callCount = 0;
        if(policyJSON.timeline == undefined || policyJSON.timeline == null)
          return callCount;
        for(var i = 0; i < Object.keys(policyJSON.timeline).length; i++) {
            let timeline = policyJSON.timeline[i];  
            if(timeline.type == objectType && timeline.stage == policyJSON.State) 
                callCount++;
        }
        return callCount;
    }

    /**
     * Append calls in stage for each policy and return.
     * 
     * @param policies 
     */
    public static appendCallsInStageToJSON(policies:any) : any {
        for(let i = 0; i < Object.keys(policies).length; i++) {
            let object = policies[i];
            let stage = object.State;
            let callCount = PolicyHelper.getNumberOfCalls(object, "Phone call");
            let callsInStage = stage + " (" + callCount + ")";
            object.callsInStage = callsInStage;
        }
        return policies;
    }
}