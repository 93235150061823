import {Injectable} from '@angular/core';
import {RestClient} from '../rest_client/RestClient'

@Injectable()
export class AppointmentService {

    /**
     * Constructor
     * 
     * @param restClient 
     */
    constructor(private restClient: RestClient) {
        
    }

    /**
     * Create a new Appointment with given date
     * 
     * @param appointmentDate 
     * @param authHeader 
     */
    public createAppointment(authHeader:string, appointmentDate:string,
        signature:string, domain:string) {
        
        let endPoint = "/appointment/create";

        //Create proxy Object In Object
        let proxyObjectIn = {
            signature: signature,
            domainName: domain,
            objectType: "InsurancePolicy"
        }
        
        //Create Appointment body
        let appointmentBody = {
            proxyObjectIn: proxyObjectIn,
            appointmentOn: appointmentDate,
            status: 'CREATED'
        }

        return this.restClient.execPOSTRequest(endPoint, authHeader, appointmentBody);
    }

    /**
     * Reschedule appointment
     * 
     * @param authHeader 
     * @param appointmentDate 
     * @param signature 
     * @param objectType 
     * @param domain 
     */
    public rescheduleAppointment(authHeader:string, appointmentDate:string,
            signature:string, domain:string, id:number ) {
        let endPoint = "/appointment/update/" +id;
                
        //Create proxy Object In Object
        let proxyObjectIn = {
            signature: signature,
            domainName: domain,
            objectType: "InsurancePolicy"
        }
                        
        //Create Appointment body
        let appointmentBody = {
            proxyObjectIn: proxyObjectIn,
            appointmentOn: appointmentDate,
            status: 'RESCHEDULED'
        }

        return this.restClient.execPUTRequest(endPoint, authHeader, appointmentBody);
    }

    /**
     * Mark appointment as completed!
     * 
     * @param authHeader 
     * @param signature 
     * @param objectType 
     * @param domain 
     */
    public completeAppointment(authHeader:string, signature:string, domain:string, id:number ) {
        let endPoint = "/appointment/update/" + id;
        //Create proxy Object In Object
        let proxyObjectIn = {
            signature: signature,
            domainName: domain,
            objectType: "InsurancePolicy"
        }

        //Create Appointment body
        let appointmentBody = {
            proxyObjectIn: proxyObjectIn,
            status: 'COMPLETE'
        }
        return this.restClient.execPUTRequest(endPoint, authHeader, appointmentBody);
    }
}