/**
 * Abstract class to hold a form element
 * 
 */
import { FormControl, ValidatorFn } from "@angular/forms";

export abstract class AForm<T> extends FormControl {
    value:T;
    key:string;
    label:string;
    required:boolean;
    controlType:string;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string
      } = {}, validatorOrOpts?: ValidatorFn) {
          super(options, validatorOrOpts);
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.controlType = options.controlType || '';
    }
}