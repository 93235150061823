import { MatDialogConfig } from '@angular/material';
import { Component, Input, Output, EventEmitter, Injector } from '@angular/core';
import { BaseComponent } from '../../core/baseui/BaseComponent';

@Component({
  selector: 'app-employee',
  template: ''
})
export class DynamicComponent extends BaseComponent {

  public constructor(injector: Injector) {
    super(injector);
  }

  /**
* getDialogConfig
*
*/
  public getDialogConfig(component: any, inputData?: any, dialogWidth?: any, classobj?: any): any {
    if (dialogWidth === undefined) {
      dialogWidth = '1100px';
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = dialogWidth;
    dialogConfig.data = inputData;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(model => {
      if (classobj != undefined) {
        classobj.ngOnInit();
      }
      return model;
    });
  }

}
