import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router'

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';

import { Role } from '../role/Role'
import { Domain } from '../domain/Domain'

@Injectable()
export class AuthService {
  isLoggedIn = false; 

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  role:Role;

  domain:Domain;

  constructor(private router: Router, private zone:NgZone) {
      let username = localStorage.getItem("USERNAME");
      let auth = localStorage.getItem("AUTH");
     // let domain = localStorage.getItem("domain");
    
     
      if(username == "" || auth == "") {
        this.isLoggedIn = false;
      } else if(username == null || auth == null) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
  }

  login() {
    this.isLoggedIn = true;
  }

  logout(): void {
    this.invalidate();
    this.navigate('login');
  }

  invalidate() {
    localStorage.setItem("USERNAME", "");
    localStorage.setItem("AUTH", "");
   
    this.isLoggedIn = false;
  }

  logoutAtomX() : void {
    this.invalidate();
    this.navigate('atomx/login');
  }

  public navigate(url) {
    console.log("navigate called");
    this.zone.run( () => {
      this.router.navigate([url]);
    });
  }

  public getRole() : Role {
      return this.role;
  }

  public setRole(role : Role) {
    this.role = role;
  }

  public getDomain() : Domain {
    return this.domain;
  }

  public setDomain(domain: Domain)  {
    this.domain = domain;
    
  }

  public getBasicAuth() : any {
    return localStorage.getItem("AUTH")
  }

  public getUsername() : any {
    return localStorage.getItem("USERNAME");
  }
}