import {
    ComponentFactoryResolver,
    Injectable,
    Inject,
    Component,
    Type,
    ReflectiveInjector
  } from '@angular/core'
  
  //import { SampleComponentComponent } from '../sample-component/sample-component.component'
  import { DynamicComponent } from '../../pages/pagehelpers/DynamicComponent'
  import { Router } from '@angular/router'
  
  export interface DynamicLoadable {
    ngOnInit();
  }

  @Injectable()
  export class DynamicComponentLoader {
  
    private rootViewContainer: any;

    constructor(private factoryResolver: ComponentFactoryResolver, private router: Router) {

    }
  
    setRootViewContainerRef(viewContainerRef) {
      this.rootViewContainer = viewContainerRef
    }
  
    addDynamicComponent(dynamicComponent: Type<DynamicComponent>, routeStr) {
      const factory = this.factoryResolver
                          .resolveComponentFactory(dynamicComponent);
      const component = factory
        .create(this.rootViewContainer.parentInjector)
      this.rootViewContainer.insert(component.hostView)
//      this.router.navigate([routeStr, "abc"]);
    }
  
  }