export class DashboardFilterModel {
    public allCustomers:FilterModel; 
    public notVerified:FilterModel;
    public appDownload:FilterModel;
    public notRequested:FilterModel;
    public shipDevice:FilterModel;
    public fieldAssist:FilterModel;
    public tierBCustomers:FilterModel;
    public deviceRTO:FilterModel;
    public deviceDelivered:FilterModel;
    public onboardDevice:FilterModel;
    public connectDevice:FilterModel;
    public forfeedback:FilterModel;
    public withfeedback:FilterModel; 
    public ncCustomers:FilterModel;
    public niCustomers:FilterModel;
    public snoozedCustomers:FilterModel;
    public invalidCustomers:FilterModel;

    constructor() {
        this.allCustomers = new FilterModel("All customers", "Default Filter", "userlist", "list", ['all-users']);
        this.notVerified = new FilterModel("Customers not verified", "Default Filter", "userlist", "clear", ['not_verified']);
        this.appDownload = new FilterModel("Customers to app download", "Default Filter", "userlist", "file_download", ['call_customer']);
        this.notRequested = new FilterModel("Customers not requested device", "Default Filter", "userlist", "phonelink_erase", ['Downloaded App']);
        this.shipDevice = new FilterModel("Unshipped Orders", "Default Filter", "userlist","local_shipping", ['ship_device']);
        this.fieldAssist = new FilterModel("Field Assist Requested", "Default Filter", "userlist", "device_hub", ['field-assist-requested']);
        this.tierBCustomers = new FilterModel("Tier B city customers", "Default Filter", "userlist","location_city", ['tier-b-customers']);
        this.deviceDelivered = new FilterModel("Delivered orders", "Default Filter", "userlist", "location_city", ['device-delivered']);
        this.deviceRTO = new FilterModel("Device RTO", "Default Filter", "userlist", "subdirectory_arrow_left", ['device-rto']);
        this.onboardDevice = new FilterModel("Customers to onboard device", "Default Filter", "userlist","cloud_done", ['onboard_device']);
        this.connectDevice = new FilterModel("Customers to connect device", "Default Filter", "userlist","battery_full", ['connect_device']);
        this.forfeedback = new FilterModel("Customers for feedback", "Default Filter", "userlist","feedback", ['customer_feedback']);
        this.withfeedback = new FilterModel("Customers with feedback", "Default Filter", "userlist","games", ['feedback']);
        this.ncCustomers = new FilterModel("Customers not contactable", "Default Filter", "userlist","person", ['not_contactable']);
        this.niCustomers = new FilterModel("Customers not interested", "Default Filter", "userlist","error", ['not_intrested']);
        this.snoozedCustomers = new FilterModel("Snoozed Customers", "Default Filter", "userlist","alarm", ['snoozed-users']);
        this.invalidCustomers = new FilterModel("Invalid Customers", "Default Filter", "userlist","voice_over_off", ['invalid-users']);
    }

    public getAllFilters():FilterModel[] {
        let array:FilterModel[] = [];
        array.push(this.allCustomers);
        array.push(this.notVerified);
        array.push(this.appDownload);
        array.push(this.notRequested);
        array.push(this.shipDevice);
        // array.push(this.fieldAssist);
        array.push(this.tierBCustomers);
        array.push(this.deviceDelivered);
        array.push(this.deviceRTO);
        array.push(this.onboardDevice);
        array.push(this.connectDevice);
        array.push(this.forfeedback);
        array.push(this.withfeedback);
        array.push(this.ncCustomers);
        array.push(this.niCustomers);
        array.push(this.snoozedCustomers); 
        array.push(this.invalidCustomers);
        
        return array;
    }
}

export class FilterModel {
    public name:string;
    public category:string;
    public link:string;
    public extras;
    public icon:string;

    constructor(_name:string, _category:string, _link: string, _icon:string, _extras:any[]) {
        this.name = _name;
        this.category = _category;
        this.link = _link;
        this.extras = _extras;
        this.icon = _icon;
    }

    public getName() :string {
        return this.name;
    }

    public getCategory() {
        return this.category;
    }

    public getLink() {
        return this.link;
    }

    public getExtras() {
        return this.extras;
    }

    public getIcon() {
        return this.icon;
    }
}