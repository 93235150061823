import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class NotificationService {

    
        constructor(private restClient: RestClient) {
        }
    
        /**
         * Send sms to user
         * 
         * @param mUsername 
         * @param subject 
         * @param message 
         * @param authHeader 
         */
        public sendSms(mUsername, subject, message, authHeader, stage, proxy) {  
            console.log("NotificationService: sendSms : mUsername: " 
            + mUsername +", subject: " + subject + ", message: " + message);
            this.sendNotification("SMS", mUsername, subject, message, authHeader, stage, proxy);
        }


        /**
         * Send Email to user
         * 
         * @param mUsername 
         * @param subject 
         * @param message 
         * @param authHeader 
         */
        public sendEmail(mUsername, subject, message, authHeader, stage, proxy) {
            console.log("NotificationService: sendEmail : mUsername: " 
            + mUsername +", subject: " + subject + ", message: " + message);

            this.sendNotification("Email", mUsername, subject, message, authHeader, stage, proxy);
        }




        /**
         * 
         * Send generic notification to user of type (Email, SMS)
         * 
         * @param notificationType 
         * @param mUsername 
         * @param subject 
         * @param message 
         * @param authHeader 
         */
        public sendNotification(notificationType, mUsername, subject, message, authHeader, stage, proxy) {
            console.log("NotificationService: sendNotification: notificationType : "
             + notificationType + ", username: " + mUsername + ", subject: " 
             + subject + ", message: " + message);


            let smsAPI = `/admin/loader/loadSingle/Notification Loader`;
            
            let headers = new Headers();
            headers.append('Content-Type','application/json');
    
            let body = {
                username : mUsername,
                notificationType : notificationType,
                subject : subject,
                shortMessage : message,
                longMessage : message,
                stage:stage,
                proxyObject : proxy            
            }
    
            return this.restClient.execPOSTRequest(smsAPI, authHeader, body);
        }
}
