import { UserService } from './../services/user.service';
//import { StringUtils } from './StringUtils';
/* import { swal } from 'sweetalert2';
 */import { Md5 } from 'ts-md5/dist/md5';
import Swal, * as swal from 'sweetalert2';


export class StringUtils {

    public static convertToMD5(str) {
        return Md5.hashStr(str);
    }

    public static getAuthHeader(username, password) {
        return 'Basic ' + btoa(username + ':' + password);
    }
    
    public static splitPhoneNumbers(phoneNumbers: string) {
        phoneNumbers = phoneNumbers.replace('[','');
        phoneNumbers = phoneNumbers.replace(']', '');
        phoneNumbers = phoneNumbers.replace('0-0,','');
        var array = phoneNumbers.split(',');
        var numbers = "";
        for(var i = 0; i < array.length; i++) {
        var arrayNumber = array[i];
        if(arrayNumber != '0') {
            numbers = numbers + arrayNumber + ","; 
        }
        }
        return numbers.slice(0, numbers.length - 1);
    }

    /**
     * Get valid email from string array;
     * 
     * @param email 
     */
    public static getValidEmail(email:string) {

        if(email == undefined)
            return "";

        let noParenthEmail = email.substring(0, email.length);
        let emailArray:string[] = noParenthEmail.split(",");
        
        let finalEmail:string = ""
        for(var i = 0; i < emailArray.length; i++) {
            if(emailArray[i] != "0") {
                finalEmail = emailArray[i];
                break;
            }
        }
        finalEmail = StringUtils.replaceAll(finalEmail, "\\[", "");
        finalEmail = StringUtils.replaceAll(finalEmail, "\\]", "");
        return finalEmail;
    }


    /**
     * Replace all occurrences of given value with another value
     * 
     * @param str 
     * @param find 
     * @param replace 
     */
    public static replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }


    displayDialog(type, title, msg) {
        Swal.fire({
            position: 'top-end',
            type: type,
            title: title,
            text:msg,
            showConfirmButton: false,
            timer: 1500
          })
        }

}