import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AForm } from "../AForm";
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AppDateAdapter } from "../../AppDateFormat";

export const APP_DATE_FORMATS =
{
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form-component.html',
  styleUrls: ['./dynamic-form.component.css'],
  providers:[
    {provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class DynamicFormComponent {

  @Input() formControl: AForm<any>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.formControl.key].valid; }
}
