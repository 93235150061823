
export class Utils {

    public static getRandomNumber(max) {
        console.log("Utils: getRandomNumber called");

        return Math.floor(Math.random() * max);
    }

    public static getRandomColor() {
        console.log("Utils: getRandomColor called");
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
      
    public static getDarkRandomColor() {
        console.log("Utils: getDarkRandomColor called");
            var letters = '012345'.split('');
            var color = '#';        
            color += letters[Math.round(Math.random() * 5)];
            letters = '0123456789ABCDEF'.split('');
            for (var i = 0; i < 5; i++) {
                color += letters[Math.round(Math.random() * 15)];
            }
            return color;
        
    }

    public static parseJSON(response) {
        console.log("parseJSON : " + response);
        var _parseJSON = function(response) {
            return response.text().then(function(text) {
                console.log("parseJSON : text: " + text);
              return text ? JSON.parse(text) : {};
            })
          }
        return _parseJSON;
    }
      
    public static downloadFile(data, filename, type) {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
        }
    }

  /**
   * Retrive on all instance of TicketSubject in array. 
   * find and return all unique values for given property from Array.
   */
  public static getValuesForProperty(array:Array<any>, property : string) {
    let values = [];
    for(let i = 0; i < Object.values(array).length; i++) {
        let obj = array[i];
        let properties:Array<string> = Object.getOwnPropertyNames(obj);
        for(let j = 0; j < Object.values(properties).length; j++) {
            if(property === properties[j]) {
                let entry:any = obj[property];
                if(entry == undefined)
                    break;
                if(!Utils.containsElement(values, entry)) {
                    values.push(entry);
                    break;
                }                
            }
        }
    };
    return values;
  }


  /**
   * Find array contain given element or not
   * 
   * @param array 
   * @param value 
   */
  public static containsElement(array:Array<any>, value) : boolean {
      for(let i = 0; i < Object.values(array).length ; i++) {
            if(array[i] == value) {
                return true;
            }
      }
    return false;
  }


  /**
   * Get modified value
   * input: 3001/159969471/00/000
   * ouput: 3001%2F160001136%2F00%2FB00
   */
  public static get2FValue(value:string) : string {
    return value.replace(new RegExp('/', 'g'), '%2F');
  }

  public static getValueFromArray(arr) : string {
      let value = "";

      for(let i = 0; i < Object.keys(arr.messages).length; i++) {
        let object = arr.messages[i];
        value = value + "\n" + object;
      }
      return value;
  }
}