import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Injector , Inject} from '@angular/core';
import { AdminBSBTemplate } from '../../core/adminbsb';
import { RouterModule, Routes , ActivatedRoute} from '@angular/router';
import { PolicyExporterService } from '../../services/policy-exporter.service';
import { TagService } from '../../services/TagService';
import { StringUtils } from '../../utils/StringUtils';
import { ProgressDialogComponent } from '../../pages/utils/progress-dialog/progress-dialog.component';
import { EmailDialogComponent } from '../utils/email-component/email-dialog.component';
import { BaseComponent } from '../../core/baseui/BaseComponent';
import { TimelineService } from '../../services/timeline.service';
import { AuthService } from "../../core/auth/AuthService";
import { Role } from "../../core/role/Role";

declare var $:any;

@Component({
  selector: 'app-user-base-detail',
  template: '',
  providers: [ TagService ]  
})
export class UserDetailBaseComponent extends BaseComponent implements OnInit {
  public adminbsb:AdminBSBTemplate;
  public route:ActivatedRoute;
  public static tagService:TagService;
  public static staticUser:any = [];
  public user:any = null;
  public policyNumber:string;
  public static authHeader:string;
  public isVerified:boolean = false;
  public isDownloaded:boolean = false;
  public isRequested:boolean = false;
  public isShipped:boolean = false;
  public isDelivered:boolean = false;
  public isOnboarded:boolean = false;
  public isConnected:boolean = false;
  public isFeedback:boolean = false;
  private COURIER_XPRESSBEES:string = "xpressbees";
	private COURIER_INDIA_POST:string = "india-post";
  private COURIER_DTDC:string = "dtdc";
  public tags:any;
  public userTag:string = "";
  public static sService;
  public username:string;
  public authHeader:string;
  public timelineService:TimelineService;
  public isNotContactable:boolean = false;
  public isNotInterested:boolean = false;
  public isDuplicate:boolean = false;
  public priority:string = "Medium";
  public nonContactableUserTimeline:any;
  public notInterestedUserTimeline:any;
  public duplicateUserTimeline:any;
  public currentStatus:string = "";
  public role:Role;
  public authService: AuthService;
  public domain:string;
  public tagStatus:string = "";
  public isTagFieldInProgress:boolean = false;
  public timelines:any;
  public requested_date:any;
  public appointment:any;
  public updatableUser:any = "";
  odometer: any;

  constructor(injector: Injector,     public policyService?: PolicyExporterService ) {
      super(injector);

      UserDetailBaseComponent.sService = injector.get(PolicyExporterService);
      this.adminbsb = injector.get(AdminBSBTemplate);
      this.route = injector.get(ActivatedRoute);
      UserDetailBaseComponent.tagService = injector.get(TagService);
      this.username = this.authService.getUsername();
      this.authHeader = this.authService.getBasicAuth();
      this.timelineService = injector.get(TimelineService);
      this.authService = injector.get(AuthService);
  }

  ngOnInit() {

    UserDetailBaseComponent.authHeader = localStorage.getItem("AUTH");

    this.getPolicyByPolicyNumber(this.policyNumber); 

    // this.route.params.subscribe( params => {
    //   this.policyNumber = params["search"];
    //   this.domain = params["domain"];
    //   this.getPolicyByPolicyNumber(this.policyNumber); 
    // });
    this.scrollToTop();
  } 

  public tagObject(tagName) {
    console.log("tagObject called");
    this.tagStatus = "Saving tag "+tagName+"..."; this.isTagFieldInProgress = true;
    UserDetailBaseComponent.tagService.tagGivenObjectId(tagName, UserDetailBaseComponent.staticUser.domainName, UserDetailBaseComponent.staticUser.policyNumber, this.authHeader, "InsurancePolicy")
    .subscribe(data=>{
      console.log("object tagged : response : " + data.json());
      this.tagStatus = "Saved!";
    }, error=>{
      console.error("Error while tagging object : " + error);
      this.tagStatus = error;
    }, ()=>{
      console.log("tag obejct service completed!");
    });
  }

  public untagObject(tagName) {
    console.log("untagObject called");
    this.tagStatus = "Removing tag "+tagName+"..."; this.isTagFieldInProgress = true;
    UserDetailBaseComponent.tagService.untagObject(tagName, UserDetailBaseComponent.staticUser.domainName, UserDetailBaseComponent.staticUser.policyNumber, UserDetailBaseComponent.authHeader, "InsurancePolicy")
    .subscribe(data=>{
      console.log("object untagged : response : " + data.json());
      this.tagStatus = "Removed!"
    }, error=>{
      console.error("Error while untagging object : " + error);
      this.tagStatus = error;
    }, ()=>{
      console.log("untag obejct service completed!");
    });

  }

  ngAfterViewInit() {
    console.log("Views are loaded for UserDetailComponent");

    let hasLoaded = this.adminbsb.hasTemplateLoaded();

    if(hasLoaded) {
      this.adminbsb.initAll();

      console.log("Template loading completed");
    }else {
      console.log("User detail has loaded is false");
    }
  }

  // Get Policy Details
  public getPolicyByPolicyNumber(policyNumber:string) {
    console.log("getPolicyByPolicyNumber called : policy number : " + policyNumber);
    super.getUserBySignature((data:any)=> {
      console.log("data &&&&&&&&&&: " );
      console.log(data);
      UserDetailBaseComponent.staticUser = data;
      this.user = UserDetailBaseComponent.staticUser;

      console.log("user all data : ");
      console.log(this.user);

      this.getOdometerList(this.user.CarId);

      this.updatableUser = this.user;
      this.timelines = this.user.timeline;
      this.findAndInitUserState();
      this.getTagForUser(UserDetailBaseComponent.staticUser.policyNumber,
      UserDetailBaseComponent.staticUser.domainName);
      this.role = this.authService.getRole();
      this.appointment = this.user.appointments.length;
      console.log("appointment lenght is: " + this.appointment);
      this.setCustomerDetails();
      
      if(this.role != null) {
          console.log("Is this user read-only : " + this.role.isReadOnly(""));
      }
      this.hidePreloader();
    }, policyNumber, this.domain);
  }

  // Set Customer details
  setCustomerDetails(){    
       // this.two_days_date = formatDate(this.two_days_date, 'MMM d, y ', 'en-US');
    this.requested_date.setDate( this.user.ShippedOn.getDate() -2 );
  }

  getOdometerList(CarId){
  /* get odometer details */
    let data = this.policyService.getOdometerDetails(this.authHeader, this.username,this.domain, CarId)
      .subscribe(data => {
        console.log("odometer data : " + data);
        this.odometer = data.json();
        console.log("**************odometer Details*****************");
        console.log(this.odometer);

      },
        error => {
          console.log(" finished with error : " + error);
          // this.pageItemCount = 0;
          // this.changeDetect.detectChanges();
        },
        () => {
          console.log("service completed!");
          // this.hidePreloader();
          // this.changeDetect.detectChanges();
        }
      );

  }

  public findAndInitUserState() {
    console.log("findAndInitUserState called");
    if(UserDetailBaseComponent.staticUser.State == "Not Verified") {
      this.currentStatus = "Customer not verified";
    }

    if(UserDetailBaseComponent.staticUser.State == "User Created") {
      this.isVerified = true;
      this.currentStatus = "Customer to app download";
    } 

    if(UserDetailBaseComponent.staticUser.State == "Downloaded App") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.currentStatus = "Customer to request device";
    }

    if(UserDetailBaseComponent.staticUser.State == "Device Requested") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.isRequested = true;
      this.currentStatus = "Customer to ship device";
    }

    if(UserDetailBaseComponent.staticUser.State == "Device Shipped"
      || UserDetailBaseComponent.staticUser.State == "Device RTO") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.isRequested = true;
      this.isShipped = true;
      this.currentStatus = "Customer to onboard device";
    }

    if(UserDetailBaseComponent.staticUser.State == "Device Delivered") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.isRequested = true;
      this.isShipped = true;
      this.isDelivered = true;
      this.currentStatus = "Customer to onboard device";
    }


    if(UserDetailBaseComponent.staticUser.State == "Device OnBoarded") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.isRequested = true;
      this.isShipped = true;
      this.isDelivered = true;
      this.isOnboarded = true;
      this.currentStatus = "Customer to connect device";
    }
    
    if(UserDetailBaseComponent.staticUser.State == "Car Connected"
        || UserDetailBaseComponent.staticUser.State == "Due for renewal"
        || UserDetailBaseComponent.staticUser.State == "Due for retrieval") {
      this.isVerified = true;
      this.isDownloaded = true;
      this.isRequested = true;
      this.isShipped = true;
      this.isDelivered = true;
      this.isOnboarded = true;
      this.isConnected = true;
      this.currentStatus = "Customer for feedback";
      if(UserDetailBaseComponent.staticUser.State == "Due for renewal") {
        this.currentStatus = "Customer to renew policy";
      }
      if(UserDetailBaseComponent.staticUser.State == "Due for retrieval") {
        this.currentStatus = "Customer to retrieve device";
      }
    }

    let timelines = UserDetailBaseComponent.staticUser.timeline;
      let timelineObject = this.getLatestTimelineWithTag(timelines);
      if(timelineObject == null) return;
      if(timelineObject.tag == "NOT_INTERESTED") {
        this.isNotInterested = true;
        this.currentStatus = "Customer not interested";
        this.notInterestedUserTimeline = timelineObject;
      }
      if(timelineObject.tag == "NOT_CONTACTABLE") {
        this.isNotContactable = true;
        this.nonContactableUserTimeline = timelineObject;
        this.currentStatus = "Customer not contactable";
      }
      if(timelineObject.tag == "DUPLICATE") {
        this.isDuplicate = true;
        this.duplicateUserTimeline = timelineObject;
        this.currentStatus = "Duplicate Policy";
      }
      if(timelineObject.tag == "Test") {
        this.currentStatus = "Test Policy";
      }
      if(timelineObject.tag == "Cancelled") {
        this.currentStatus = "Cancelled Policy";
      }
      if(timelineObject.tag == "Snooze") {
        this.currentStatus = "Snooze";
      }
      if(timelineObject.tag == "Invalid order") {
        this.currentStatus = "Invalid Policy";
      }
  }


  /**
   * Get timeline with latest tag.
   */
  private getLatestTimelineWithTag(timelines:any) : any {
    for(let i = 0; i < Object.keys(timelines).length; i++) {
      let timelineObject = timelines[i];
      if(timelineObject.tag != null && timelineObject != ""
                                          && timelineObject != undefined) {
          return timelineObject;
      }
    }
    return null;
  }


  /**
   * Get phone call count
   * 
   * @param policy 
   */
  public getPhoneCallCount(policy:any) {
      return this.getObjectCount(policy, "Phone call");
  }

  public getObjectCount(policy, objectType) {
    let objectCount = 0;

    if(policy.timeline == undefined || policy.timeline == null)
      return 0;
    
        for(var i = 0; i < Object.keys(policy.timeline).length; i++) {
          if(policy.timeline[i].type == objectType) 
            objectCount++;
        }
    return objectCount;
  }


  /**
   * Get tags for this user
   * 
   * @param policyId policyId is an object id 
   */
  public getTagForUser(policyNumber:number, domainName:string) {
    console.log("Get tag for this user");

    UserDetailBaseComponent.tagService.getTagsByObjectId(policyNumber, domainName, UserDetailBaseComponent.authHeader, "InsurancePolicy").subscribe(data=>{
      let tags = data.json();
      console.log("tag of this user : " + tags);
      this.tags = tags;
      this.prepareTagList(this.tags);
    }, err=>{
      console.error("Error while fetching tags : " + err);
    }, ()=>{
      console.log("Get tag for user service completed!");
    });
  }


  public prepareTagList(tags) {
    console.log("This is tag preparation phase");

    for(var i = 0; i < Object.keys(tags).length; i++) {
      if(this.isPriorityTag(tags[i].name)) {
        this.priority = tags[i].name;
        continue;
      }
      $('#tagInputbox').tagsinput('add', tags[i].name);
    }   
  }

  public isPriorityTag(tagName : string) {
    console.log("isPriorityTag : tagName : " + tagName);

    if(tagName == "Medium" || tagName == "High" || tagName == "Low")
      return true;
    return false;
  }


  /**
   * Email user
   * 
   * @param policy 
   * @param CustomerEmail 
   */
  public email(email) {    
        

        let proxy = {
          signature: UserDetailBaseComponent.staticUser.policyNumber,
          domainId: UserDetailBaseComponent.staticUser.domainId,
          objectType: "InsurancePolicy"      
        };

        let validEmail = StringUtils.getValidEmail(email);
    
        let data = {username: validEmail, type : "Email", stage: UserDetailBaseComponent.staticUser.State, proxy: proxy, isEmailed: false};

        let dialogRef = super.showDialogWithSizeAndInput(EmailDialogComponent, false, '600px', '1000px', data);
        dialogRef.afterClosed().subscribe(result=> {
          console.log("Email dialog closed!");
          this.getPolicyByPolicyNumber(this.policyNumber);
          
        });
  }
    
  
  /**
       * 
       * @param policy 
       * @param CustomerEmail 
       */
      public sms(mobile) {    
        
            let proxy = {
              signature: UserDetailBaseComponent.staticUser.policyNumber,
              domainId: UserDetailBaseComponent.staticUser.domainId,
              objectType: "InsurancePolicy"      
            };

            let validEmail = StringUtils.getValidEmail(""+ mobile + "");
        
            let data = {username: validEmail, type : "SMS", stage: UserDetailBaseComponent.staticUser.State, proxy: proxy};
        
            super.showDialogWithSizeAndInput(EmailDialogComponent, false, '600px', '600px', data);
      }

      /* public flagNotContactable() {
        if(this.isNotContactable) {
          super.confirmDialog("Mark user as contactable", "Mark user as contactable if communication with user is possible.")
          .then((value) => {
              if(value.value) {
                this.removeComment(this.nonContactableUserTimeline);
              }
          });
        } else {
          super.confirmWithNote("Mark Not Contactable with note:").then((value)=>{
            if(value.hasOwnProperty('dismiss') == true 
              && (value.dismiss.toString() == "cancel" || value.dismiss.toString() == "esc") )
            return;
            if(value.value != "" && value.value != null) {
              this.addComment(value.value, "NOT_CONTACTABLE");
            } else {
              super.showAlert("Please add valid note.");
            }
          });
        }
      }
 */
      /* public flagNotInterested() { 
        if(this.isNotInterested) {
          super.confirmDialog("Mark user as Interested!", "Mark user as interested if user has said so.")
          .then((value) => {
              if(value.value) {
                this.removeComment(this.notInterestedUserTimeline);
              }
          });
        } else {
          super.confirmWithNote("Mark Not Interested with note:").then((value)=>{
            if(value.hasOwnProperty('dismiss') == true 
              && (value.dismiss.toString() == "cancel" || value.dismiss.toString() == "esc") )
            return;
            if(value.value != "" && value.value != null) {
              this.addComment(value.value, "NOT_INTERESTED");
            } else {
              super.showAlert("Please add valid note.");
            }
          });
        }
      } */

      /**
       * Create timeline for Not-Interested & Not-contactable with message
       */
      public addComment(message, tag) {
        console.log("addComment: Comment button clicked");
        
        console.log("user details : " + JSON.stringify(this.user));
        this.timelineService.createTimeline(this.user.PolicyId, this.user.policyNumber, message, 
          "Phone call", tag, this.user.State, this.user.domainId, this.username, this.authHeader)
          .subscribe(data => {
            console.log("data : " + data.json());
            if(tag == "NOT_CONTACTABLE")
              this.isNotContactable = true;
            if(tag == "NOT_INTERESTED")
              this.isNotInterested = true;
            if(tag == "DUPLICATE")
              this.isDuplicate = true;
            this.getPolicyByPolicyNumber(this.user.policyNumber);
            // super.showAlert(this.user.policyNumber + " marked as " + tag);
          }, err =>{
            console.log("Error while adding comment, error : " + err);
            
            super.showAlert("Error while marking " + this.user.policyNumber + " as " + tag)
          }, () => {
            console.log("Add comment service successful!");
          });
      }

      public removeComment(userTimeline:any) {
          this.timelineService.removeTimeline(userTimeline.id, this.authHeader)
            .subscribe(data=>{
              if(this.isNotContactable)
                this.isNotContactable = false;
              if(this.isNotInterested) {
                this.isNotInterested = false;
              }
              if(this.isDuplicate) {
                this.isDuplicate = false;
              }
              this.getPolicyByPolicyNumber(this.policyNumber)
            }, err=>{
              super.showAlert("Error while marking contactable user : " + JSON.stringify(err));
            }, ()=>{
              console.log("removeTimeline request completed!");
            });
      }

      public changePriority(priority:string) {
        if(this.priority == priority) 
          return;
        let tempTag = this.priority;
        this.priority = priority;

        this.untagObject(tempTag);
        this.tagObject(this.priority);
      }

      updateOwner() {
        
      }


      /**
       * open aftership cariq page with AWB number
       */
      public trackShipment() {

        if(this.user.Shipment_AWB == null)
          return;

        let courier = this.getCourier(this.user.Shipment_AWB);

        window.open("https://cariq.aftership.com/ " + courier + "/"  + this.user.Shipment_AWB, "_blank");
      }


      public getCourier(awb:string) : string {
        if (awb.startsWith("P"))
          return this.COURIER_DTDC;
    
        if (awb.startsWith("E"))
          return this.COURIER_INDIA_POST;
    
        return this.COURIER_XPRESSBEES;
      }

      /**
       * Mark user as duplicate
       */
      /* public markDuplicate() {
        if(this.isDuplicate) {
          super.confirmDialog("Unmark duplicate", "")
          .then((value) => {
              if(value.value) {
                this.removeComment(this.duplicateUserTimeline);
              }
          });
        } else {
          super.confirmWithNote("Mark duplicate with note:").then((value)=>{
            if(value.hasOwnProperty('dismiss') == true 
              && (value.dismiss.toString() == "cancel" || value.dismiss.toString() == "esc") )
            return;
            if(value.value != "" && value.value != null) {
              this.addComment(value.value, "DUPLICATE");
            } else {
              super.showAlert("Please add valid note.");
            }
          });
        } 
      }*/
}
