import {AuthService} from '../../../core/auth/AuthService';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../../services/NotificationService'
//import { Messages, Message } from '../../../constants/Messages'

declare var $:any;

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './email-dialog-component.html',
  styleUrls: ['./email-dialog-component.css'],
  providers: [NotificationService]
})
export class EmailDialogComponent implements OnInit {

  
  public emailType:string;

  public username:string;

  public subject:string = "";

  public msg:string = "";

  public uiError:string = "";

  public authHeader:string = "";

  public isSuccess:boolean = true;

  public stage:string;

  public proxy:any;

  //public messages:Message[];

  constructor(public dialogRef: MatDialogRef<EmailDialogComponent>, private authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any, private nService:NotificationService) { 
      this.emailType = data.type;
      console.log("Input type give is : " + data.type);
      this.username = data.username;
      this.stage = data.stage;
      this.proxy = data.proxy;
      this.authHeader = this.authService.getBasicAuth();

  } 

  ngOnInit() {
    this.dialogRef.beforeClose().subscribe(data=>{
        data.isEmailed = this.isSuccess;
    });    

    $.trumbowyg.svgPath = '../assets/icons.svg';
    $('#trumbowyg-div').trumbowyg();    

    /* let msg = new Messages();
    if(this.emailType == "Email") {
      this.messages = msg.getMessagesByType(Messages.TYPE_CANNED_EMAIL);
    } else {
      this.messages = msg.getMessagesByType(Messages.TYPE_CANNED_SMS);
    } */
  }

  public send():void {
    console.log("EmailDialogComponent: send clicked");

    if(this.emailType == 'Email') {
      this.msg = $('#trumbowyg-div').trumbowyg('html');
    }

    if(this.emailType == 'Email' && this.subject == "") {
      this.isSuccess = false;
      this.uiError = "Subject cannot be empty!";
      return;
    } 
    if(this.msg == "") {
      this.isSuccess = false;
      this.uiError = "Message cannot be empty!";
      return;
    }

    this.isSuccess = true;
    this.uiError = "Sending " + this.emailType + "...";

    this.nService.sendNotification(this.emailType, this.username, 
      this.subject, this.msg, this.authHeader, this.stage, this.proxy).subscribe(
        data => {
          let dataJson = data.json();
          let message = this.emailType + " processed with message["+ dataJson.messages +"]";
          console.log(message);
          this.isSuccess = true;
          this.uiError = message;
          this.subject = "";
          this.msg = "";
        },
        err => {
          console.log("Error while sending notification : " + err);
          this.isSuccess = false;
          this.uiError = "Error while sending " + this.emailType + ": " + JSON.stringify(err);
        },
        () => {
          console.log("Send Notification service completed!");
        }
      );
  }

  /* public onTemplateSelected(m:Message) {
    console.log("EmailDialogComponent: onTemplateSelected selected!");
    this.msg = m.getMessage();
    $('#trumbowyg-div').trumbowyg('html', m.getMessage());
    this.subject = m.getTitle();
  } */
}

