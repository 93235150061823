import { FilterModel, DashboardFilterModel } from '../DashboardFilterModel';
import { Domain } from './Domain'

export class TagicDomain implements Domain {
    getIcon(): string {
        return "ic_autosafe.png";
    }
    getMobileAppName(): string {
        return "TATA AIG Autosafe";   
    }
    getDomainTelematicsProductName(): string {
        return "Tata AIG General Insurance";
    }

    public filterModel:DashboardFilterModel;
    
    private tagicFilters:FilterModel[] = [];
        
    constructor() {
        this.filterModel = new DashboardFilterModel();
            
        this.tagicFilters.push(this.filterModel.allCustomers);
        this.tagicFilters.push(this.filterModel.notVerified);
        this.tagicFilters.push(this.filterModel.appDownload);
        this.tagicFilters.push(this.filterModel.shipDevice);
        this.tagicFilters.push(this.filterModel.deviceDelivered);
        this.tagicFilters.push(this.filterModel.deviceRTO);
        this.tagicFilters.push(this.filterModel.connectDevice);
        this.tagicFilters.push(this.filterModel.forfeedback);
        this.tagicFilters.push(this.filterModel.withfeedback);
        this.tagicFilters.push(this.filterModel.ncCustomers);
        this.tagicFilters.push(this.filterModel.niCustomers);
        this.tagicFilters.push(this.filterModel.snoozedCustomers);
        this.tagicFilters.push(this.filterModel.invalidCustomers);
    }
    
    getDashboardFilters(): FilterModel[] {
        let allFilters:FilterModel[] = this.filterModel.getAllFilters();
        return allFilters;
    }

    getName(): string {
        return "TAGIC";
    }
    getPlayStoreLink(): string {
        return "https://play.google.com/store/apps/details?id=com.cariq.mobility.autosafe";
    }
    
    getCompanyName(): string {
        return "Tata AIG General Insurance Company Limited";
    }
}
