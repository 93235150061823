import {DashboardService} from '../sample-component/DashboardService';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AdminBSBTemplate } from '../../core/adminbsb'
import { TimelineService } from '../../services/timeline.service'
import { PolicyExporterService } from '../../services/policy-exporter.service'
import {TagsInputComponents} from 'bootstrap-tagsinput';
import { Role } from "../../core/role/Role";
import { AuthService } from "../../core/auth/AuthService";

import { Desposition } from "../../core/desposition/Desposition";
import { DespositionManager } from "../../core/desposition/DespositionManager";

declare var TagsInput:any;
declare var $:any;


@Component({
  selector: 'app-user-timeline-detail',
  templateUrl: './user-timeline-detail.component.html',
  styleUrls: ['./user-timeline-detail.component.css'],
  providers: [ TimelineService],
  
})
export class UserTimelineDetailComponent implements OnInit {

  @Input()
  user : any;

  @ViewChild('commentView') commentView;

  public comment:string = "";
  public stage:string = "User verified";
  public type:string = "Phone call";
  public commentTag:string;
  public username:string;
  public authHeader:string;
  public proxyId:number;

  public uiError:string;
  public isSuccess:boolean = true;
  public role:Role;

  public dManager:DespositionManager;
  public despositionTypes:string[] = [];
  public despositions:Desposition[] = [];
  public selectedDespositionType:string;
  public selectedDesposition:string;

  public commentTypes:string[] = ["Phone call", "Comment", "Feedback"];

  constructor(public adminbsb: AdminBSBTemplate, public timelineService : TimelineService,
    private policyService: PolicyExporterService, public authService:AuthService,
    private dashboardService:DashboardService) { 
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.role = authService.getRole();

    this.dManager = new DespositionManager();
    this.despositionTypes = this.dManager.getTypes();
    this.selectedDespositionType = this.despositionTypes[0];
    this.despositions = this.dManager.getByType(this.despositionTypes[0]);
  }

  ngOnInit() { 
    
  }

  ngAfterViewInit() {
    console.log("Views are loaded for User timeline componenet");

    this.adminbsb.loadTag();

    $('#commentTag').tagsinput({
      maxTags: 1
    });
  }

  public addComment() {
    console.log("addComment: Comment button clicked");

    if(this.comment == "") {
      this.isSuccess = false;
      this.uiError = "Comment cannot be empty!";
      return;
    }

    this.uiError = "Saving comment, please wait..."
    console.log("user details : " + JSON.stringify(this.user));
    this.timelineService.createTimeline(this.user.PolicyId, this.user.policyNumber, this.comment, 
      this.type, this.commentTag, this.user.State, this.user.domainId, this.username, this.authHeader)
      .subscribe(data => {
        console.log("data : " + data.json()); 
        this.getPolicyByPolicyNumber(this.user.policyNumber);
        this.isSuccess = true;
        this.uiError = "Comment added successfuly!";
      }, err =>{
        console.log("Error while adding comment, error : " + err);
        this.isSuccess = false;
        this.uiError = "Error while saving comment : " + err;
      }, () => {
        console.log("Add comment service successful!");
      });
    this.comment = "";
    this.commentTag = "";
  }

  public onTypeSelected(type:string) {
    console.log("onTypeSelected called: selected type is : " + type);

    this.type = type;
  }
  


  public onStageSelected(stage:string) {
    console.log("onStageSelected called: selected stage is : " + stage);

    this.stage = stage;
  }

  public getPolicyByPolicyNumber(policyNumber:string) {
    console.log("getPolicyByPolicyNumber called : policy number : " + policyNumber);

    let username = localStorage.getItem("USERNAME");
    console.log("get policy for username " + username);

    this.policyService.getPolicyBySearchTerm(policyNumber, this.authHeader, username, this.dashboardService.domainName)
    .subscribe(data=>{
      console.log("data : " + data);
      this.user = data.json();
      this.comment = "";
      this.commentTag = ""
    },
    error=>{
      console.log("getPolicyByPolicyNumber service finished with error : " + error);
    },
    ()=>{
      console.log("Get policy by policy number api completed!");
    }
    );
  }


  public getIcon(timelineObj:any) {
    console.log("getIcon method called!");

    if(timelineObj == undefined) 
      return null;

    //if email return email icon
    if(timelineObj.type == "Email") {
      return "assets/ic_email.png";
    }

    if(timelineObj.type == "SMS") {
      return "assets/ic_sms.png";
    }

    if(timelineObj.type == "Phone call") {
      return "assets/ic_phone_call.png"
    }

    if(timelineObj.type == "Comment") {
      return "assets/ic_comment.png"
    }
  } 

  /**
   * On desposition type selected
   */
  public onDespositionTypeSelected(despositionType:string) {
    this.selectedDespositionType = despositionType;
    this.commentTag = this.selectedDespositionType;
    this.despositions = this.dManager.getByType(this.selectedDespositionType);
  }

  /**
   * On Desposition selected.
   * 
   * @param desposition 
   */
  public onDespositionSelected(desposition:string) {
    this.selectedDesposition = desposition;
    this.comment = desposition;
    this.commentView.nativeElement.disabled = true;
  }
}
