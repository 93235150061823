import { Domain } from './Domain'
import { BagicDomain } from './BagicDomain';
import { DrivenDomain } from './DrivenDomain';
import { ILAssistDomain } from './ILAssistDomain';
import { TagicDomain } from "./TagicDomain";

import { CarIQDevDomain } from "./CarIQDevDomain";

export class DomainManager {

    private static _instance: DomainManager;    

    public static DOMAIN_ILASSIST = 'IL-Assist';
    public static DOMAIN_DRIVESMART = 'Drivesmart';


    private static domain:Domain;
    private DomainManager() {

    }

    public static getDomainInstance() : Domain {
        return this.domain;
    }

    /**
     * Get domain from domain name.
     * 
     * @param domainName 
     */
    public static getDomain(domainName) : Domain {
        if(domainName == 'IL-Assist') 
            this.domain = new ILAssistDomain();
        else if(domainName == 'Drivesmart') 
            this.domain =  new BagicDomain();
        else if(domainName == 'Driven') 
            this.domain = new DrivenDomain();
        else if(domainName == 'TAGIC') 
            this.domain = new TagicDomain();
        else if(domainName == 'Driven-Dev') 
            this.domain = new CarIQDevDomain();
        else
            throw new Error("Domain with domain name " + domainName + " not found!");

        return this.domain;
    }    

    /**
     * Get domains objects from domain jsons
     * 
     * @param domainsJson 
     */
    public static getDomains(domainsJson:any) : Domain[] {
        let domains:Domain[] = [];
        for(let i = 0; i < Object.keys(domainsJson).length; i++) {
            let domainJson = domainsJson[i];
            domains.push(DomainManager.getDomain(domainJson.name));
        }
        return domains;
    }

    public static get getInstance() {
        return this._instance || (this._instance = new this());        
    }
}