import {AbstractControl} from '@angular/forms/src/model';
import { Injectable }   from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AForm } from "./AForm";
import { DateUtils } from "../../utils/DateUtils";


@Injectable()
export class FormControlService {
  constructor() { }

  toFormGroup(forms: AForm<any>[] ) : FormGroup { 
    let group: any = {};

    forms.forEach(form => {
      group[form.key] = form;
      // group[form.key] = form.required ? 
      // new FormControl({value: form.value, type:form.controlType} || '', Validators.required)
      //       : new FormControl({ value: form.value, type:form.controlType } || '');
    });
    return new FormattableFormGroup(group);
  }
}


/***
 * Formattable form group 
 */
export class FormattableFormGroup extends FormGroup {
  constructor(public cntrls: {
    [key: string]: AForm<any>;
  }){
      super(cntrls);
  } 
  getRawValue(): any {
    let rawValue = {};
    let keys:string[] = Object.keys(this.cntrls);
    for(let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let control:AForm<any> = this.cntrls[key];

        if(control.controlType == 'dateform') {
          rawValue[key] = DateUtils.formatDate(control.value, DateUtils.FORMAT_DEFAULT);
          continue;
        }
        rawValue[key] = control.value;
    }
    return rawValue;
  }
}