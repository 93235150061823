import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class UserService {
    
        constructor(private restClient: RestClient) {
        }
    
        /**
         * Get user by username
         * 
         * @param username 
         * @param authHeader 
         */
        getUser(username, authHeader) {  
            console.log("getUser called: username : " + username);
            let headers = new Headers();
            headers.append('Content-Type','application/json');
    
            let getUserAPI = `/user/get/${username}`;
    
            return this.restClient.execGETRequest(getUserAPI,authHeader, false);
        }


        getDomain(username, authHeader) {
            console.log("getDomain called: username : " + username);
            
            let getDomainAPI = `/domain/getAccessibleDomainsForUser/${username}`;
            return this.restClient.execGETRequest(getDomainAPI, authHeader, false);
        }

        getAllUsersForDomain(domainName, authHeader) {
            console.log("getAllUsersForDomain called: domainName : " + domainName) ;
            let getUsersAPI = `/domain/getPrivilegedUsersForDomain/${domainName}`;
            return this.restClient.execGETRequest(getUsersAPI, authHeader, false);
        }

        /**
         * Get all domains api
         */
        getAllDomains(authHeader) {
            console.log("getAllDomains called: authHeader");
            let allDomainsAPI = `/domain/getAll/1/100000000`;
            return this.restClient.execGETRequest(allDomainsAPI, authHeader, false);
        }
}
