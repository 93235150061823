import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import ExportingModule from 'highcharts/modules/exporting';

ExportingModule(Highcharts);
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {
    total_score: any;
    value1 = '5';
    value2 = '10';
    value3 = '4';
    value4 = '7';
    value5 = '2';
    value6 = '1';
    total;
  onEnter1 (value1: string) {this.value1 = value1;}
  onEnter2 (value2: string) {this.value1 = value2;}
  onEnter3 (value3: string) {this.value1 = value3;}
  onEnter4 (value4: string) {this.value1 = value4;}
  onEnter5 (value5: string) {this.value1 = value5;}
  onEnter6 (value6: string) {this.value1 = value6;}
  
  constructor() { 
   
    
    
  }

  ngOnInit() {

    //score
    this.onclick();


/**
 * risk scoring chart
 */

    Highcharts.chart('container', {
      chart: {
          type: 'bar'
      },
      title: {
          text: 'Scoring Factors'
      },
      xAxis: {
          categories: ['Rash Breacking', 'Rash Acceleration', 'Overspeeding', 'Idealing', 'Night Driving', 'Total Driving']
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Scoring'
          }
      },
      
      legend: {
          reversed: true
      },
      plotOptions: {
          series: {
            stacking: 'normal',   
          },
          column: {
            pointWidth: 10,
            pointPadding: 0.6,
            borderWidth: 0
            
          }
      },
      series: [{
          type: 'bar',
          name: 'Score',
          pointWidth: 15,
          //color: 'rgb(255,0,0)',
          color:'rgb(255,126,0)',
          data: [5, 10, 4, 7, 2,1]
      }]
  });




 var categories = ['Rash Breacking', 'Rash Acceleration', 'Overspeeding', 'Idealing', 'Night Driving', 'Total Driving']

  Highcharts.chart('container', {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Risk Scoring Factors'
    },
    xAxis: { // mirror axis on right side
      categories: categories,
      opposite: true,
      reversed: false,
      linkedTo: 0,
      labels: {
        step: 1
      }
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value) + '';
        }
      }
    },
  
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
  
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + ', age ' + this.point.category + '</b><br/>' +
          'Population: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
      }
    },
  
    series: [{
      name: 'Male',
      type: 'bar',
      data: [
        -2.2, -2.1, -2.2, -2.4,
        -2.7, -3.0, -3.3, -3.2,
        -2.9, -3.5, -4.4, -4.1,
        -3.4, -2.7, -2.3, -2.2,
        -1.6, -0.6, -0.3, -0.0,
        -0.0
      ]
    }]
  });
  























//speedometer

//   Highcharts.chart('container1', {

//     chart: {
//         type: 'gauge',
//         plotBackgroundColor: null,
//         plotBackgroundImage: null,
//         plotBorderWidth: 0,
//         plotShadow: false
//     },

//     title: {
//         text: 'Speedometer'
//     },

//     pane: {
//         startAngle: -150,
//         endAngle: 150,
//         background: [{
//             backgroundColor: {
//                 linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
//                 stops: [
//                     [0, '#FFF'],
//                     [1, '#333']
//                 ]
//             },
//             borderWidth: 0,
//             outerRadius: '109%'
//         }, {
//             backgroundColor: {
//                 linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
//                 stops: [
//                     [0, '#333'],
//                     [1, '#FFF']
//                 ]
//             },
//             borderWidth: 1,
//             outerRadius: '107%'
//         }, {
//             // default background
//         }, {
//            // backgroundColor: '#DDD',
//             borderWidth: 0,
//             outerRadius: '105%',
//             innerRadius: '103%'
//         }]
//     },
//     // the value axis
//     yAxis: {
//         min: 0,
//         max: 200,

//         minorTickInterval: 'auto',
//         minorTickWidth: 1,
//         minorTickLength: 10,
//         minorTickPosition: 'inside',
//         minorTickColor: '#666',

//         tickPixelInterval: 30,
//         tickWidth: 2,
//         tickPosition: 'inside',
//         tickLength: 10,
//         tickColor: '#666',
//         labels: {
//             step: 2,
//             rotation: 'auto'
//         },
//         title: {
//             text: 'km/h'
//         },
//         plotBands: [{
//             from: 0,
//             to: 120,
//             color: '#55BF3B' // green
//         }, {
//             from: 120,
//             to: 160,
//             color: '#DDDF0D' // yellow
//         }, {
//             from: 160,
//             to: 200,
//             color: '#DF5353' // red
//         }]
//     },

//     series: [{
//         type: 'gauge',
//         name: 'Speed',
//         data: [80],
//         tooltip: {
//             valueSuffix: ' km/h'
//         }
//     }]

// },
// function (chart) {
//     if (!chart.renderer.forExport) {
//         setInterval(function () {
//             var point = chart.series[0].points[0],
//                 newVal,
//                 inc = Math.round((Math.random() - 0.5) * 20);

//             newVal = point.y + inc;
//             if (newVal < 0 || newVal > 200) {
//                 newVal = point.y - inc;
//             }

//             point.update(newVal);

//         }, 3000);
//     }
// }
// );



}


public onclick(){
  console.log("function call");
 this.total= this.value1 + this.value2 + this.value3 + this.value4 + this.value5 + this.value6;
 this.total_score = this.total/100;
 console.log("function call");
 console.log(this.total_score );
}
  
}
