import { StringUtils } from '../../utils/StringUtils';
import { UserDetailBaseComponent } from './../user-detail/UserDetailBaseComponent';
import { Domain } from '../../core/domain/Domain';
import { Component, ViewChild, NgModule, ChangeDetectorRef, OnInit, Type, Injector, ChangeDetectionStrategy } from '@angular/core';
import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { PolicyExporterService } from '../../services/policy-exporter.service';
import { DynamicLoadable } from '../../pages/pagehelpers/DynamicComponentLoader'
import { DynamicComponent } from '../../pages/pagehelpers/DynamicComponent'
import { ProgressDialogComponent } from '../../pages/utils/progress-dialog/progress-dialog.component'
import { OWL_DATE_TIME_FORMATS, DateTimeAdapter } from 'ng-pick-datetime';
import * as Moment from "moment";
import { AdminBSBTemplate } from '../../core/adminbsb'
import { IMyDpOptions } from 'mydatepicker';
import { DateUtils } from '../../utils/DateUtils'
import { Utils } from '../../utils/Utils'
import { MatPaginator, PageEvent, MatSort, MatTableDataSource } from '@angular/material';
import { TimelineService } from '../../services/timeline.service'
import { DashboardService } from '../sample-component/DashboardService'
import { UserService } from '../../services/user.service'
import { PolicyHelper } from "../../core/PolicyHelper";
import { filter } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { UserStageDetailComponent } from '../user-detail/user-stage-details.component';
//import { AccountDetailsComponent } from 'acc';
import { StatisticsService } from "../../services/StatisticsService";
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountDetailsComponent } from 'src/app/account-details/account-details.component';
import { CarIQDevDomain } from 'src/app/core/domain/CarIQDevDomain';
//import { ChartsComponent } from '../../charts/charts.component';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-sample-component',
  templateUrl: './sample-component.component.html',
  styleUrls: ['./sample-component.component.css'],
  providers: [StringUtils, StatisticsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SampleComponentComponent extends DynamicComponent implements OnInit {
  all_user: any;
  Cannected_Cars: any;
  Cust_Not_intrested: any;
  Cust_Not_Contactable: any;
  pageSize: number = 10;
  PolicyNumber: any;
  carId: any;
  public overallSpotlightStatCount: number = 0;
  public preShipmentmentStatCount: number = 0;
  public postShipmentStatCount: number = 0;
  public appointmentStatCount: number = 0;
  dashboardService: any;
  card_count: any =[];


  public userdetails(user) {
    console.log("clicked");
    console.log(user);


    let proxy = {
      signature: user.policyNumber,
      domainId: user.domainId,
      objectType: "InsurancePolicy"
    };

    let validEmail = StringUtils.getValidEmail(user.Staging_customerEmail);
    //let data = {username: validEmail, type : "Email", stage: user.State, proxy: proxy, isEmailed: false};
    let data = { users: user, owners: this.users };
    let dialogRef = super.showDialogWithSizeAndInput(AccountDetailsComponent, true, '560px', '900px', data);
    dialogRef.afterClosed().subscribe(result => {
      console.log("Email dialog closed!. result: " + result);
      let retUser = result.user;

      //If appointment sheduling was successful then remove this item from spotlight list.


      //After removing(or not removing in case appointment not set or any item removable)
      //action not taken. 
      // this.dataSource = new MatTableDataSource(this.spotlights);
    });
  }


  displayedColumns = ['name', 'stagingCreatedOn', 'mobile', 'email', 'city'];
  // displayedColumns = ['name', 'stagingCreatedOn', 'mobile', 'email', 'priority', 'city', 'owner', 'callsCount', 'activity_by', 'actions'];
  dataSource: MatTableDataSource<Policy>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public policyDetailsList: any;

  public query = "hello";

  public authHeader: string;

  public filterStr: string = "createdAfter";

  public filtername: string;

  public callType: string;
  public selectedUser: any;

  public selectedFilter = "All Users";
  public createdAfter = '2017-01-01 00:00:00';
  // public createdAfter = new Date();
  public min = new Date(2018, 1, 12, 10, 30);
  public max = new Date(2018, 3, 21, 20, 30);
  public final_array: any = [];
  public isVerificationList: boolean = false;

  public priorityFilter: string;
  public username: string;

  public userFilter: any;

  public users = null;

  public pageItemCount: number = 0;

  public categoryType: String;
  public category: string;
  public fromDate: any = '2017-01-01 00:00:00';
  public toDate: any = new Date();
  pageEvent: PageEvent;
  datasource: null;
  pageIndex: number;

  length: number = 50000;

  public domain: string;
  public spotligh_color;
  all_customer: any;

  constructor(private policyService: PolicyExporterService, injector: Injector,
    private route: ActivatedRoute, private changeDetect: ChangeDetectorRef,
    public adminbsb: AdminBSBTemplate,
    private timelineService: TimelineService,
    private userService: UserService,
    private showdialog: StringUtils, private statisticsService: StatisticsService,
    private spinner: NgxSpinnerService
  ) {
    super(injector);

    this.getSpotlightStats();


    this.createdAfter = this.dashboardService.getCreatedAfter();
    this.pageIndex = this.dashboardService.getPageIndex();
    this.pageSize = this.dashboardService.getPageSize();
    this.callType = this.dashboardService.getCallFilter();
    this.priorityFilter = this.dashboardService.getPriority();
    this.username = localStorage.getItem("USERNAME");
    this.domain = localStorage.getItem("domainName");

    this.userFilter = this.dashboardService.getUserFilter();

  }

  ngOnInit() {
    this.spinner.show();
    console.log("Sample componenet loading completed!");
    this.authHeader = localStorage.getItem("AUTH");
    console.log("Auth header retrived from local : " + this.authHeader);
    this.carDrivingDetails();

    if (this.paginator != null) {
      this.paginator.pageIndex = this.pageIndex;
      this.paginator.pageSize = this.pageSize;
    }
    this.getPolicyDetails();
    this.loadUsers(this.username, this.authHeader);


    this.carDrivingDetails();
    this.getBucketList('createdAfter');

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);

    //init sort to custom sorting of fields
    this.initSort();
  }

  ngAfterViewInit() {
    this.adminbsb.loadAdminBSB();

    if (this.datasource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initSort();


    }
  }

  private getFilter(filterStr: string) {
    console.log("Input filter str : " + filterStr);

    if (filterStr == "not_verified") {
      this.isVerificationList = true;
      return "Not Verified";
    } else {
      this.isVerificationList = false;
    }
    if (filterStr == "all-users") {
      return "All Users";
    }
    if (filterStr == "call_customer") {
      return "User Created";
    }
    if (filterStr == "ship_device") {
      return "Device Requested";
    }
    if (filterStr == "tier-b-customers") {
      return "ByTierAorB";
    }
    if (filterStr == "field-assist-requested") {
      return "FieldAssist Requested";
    }
    if (filterStr == "device-delivered") {
      return "Device Delivered"
    }
    if (filterStr == "onboard_device") {
      return "Device Shipped";
    }
    if (filterStr == "connect_device") {
      return "Device OnBoarded";
    }
    if (filterStr == "customer_feedback") {
      return "Car Connected";
    }
    if (filterStr == "not_contactable") {
      return "Not contactable";
    }
    if (filterStr == "not_intrested") {
      return "Not interrested";
    }
    if (filterStr == "feedback") {
      return "Feedback";
    }
    if (filterStr == "device-rto") {
      return "Device RTO"
    }
    if (filterStr == "snoozed-users") {
      return filterStr;
    }
    return filterStr;
  }

  public onUserFilterSelected(selectedUser) {
    console.log("onUserFilterSelected called " + selectedUser);

    this.userFilter = selectedUser;
    this.dashboardService.setUserFilter(this.userFilter);
    this.dashboardService.setPageIndex(0);
    this.pageIndex = 0;
    this.getPolicyDetails();
  }


  public loadUsers(username: string, authHeader: string) {
    console.log("SampleComponent: loadUsers called");

    this.userService.getDomain(this.username, this.authHeader).subscribe(
      data => {
        let domain = data.json();
        console.log('DomainQQQQQQQQQQQQQQQQQQQQQQQQQQQQ:' + this.domain);
        console.log(" domain response data : ");
        console.log(domain);
        this.domain = domain[0].name;
        console.log(this.domain);
        this.getpolicyCounts('stage', 'AllCustomers', 0);
         this.getpolicyCounts('stage', 'Car Connected', 1);
        this.getpolicyCounts('state', 'NOT_INTERESTED', 2);
        this.getpolicyCounts('state', 'NOT_CONTACTABLE', 3);
        console.log(domain);
        this.userService.getAllUsersForDomain(this.domain, this.authHeader).subscribe(
          usersData => {
            //this.showdialog.displayDialog("success", "API success", "Successfully call APIs");

            this.users = [];
            this.users.push({ username: "Everyone" },
              { username: "None" });
            let values = usersData.json();
            this.all_customer = (<any>values).length;
            for (let i = 0; i < Object.keys(usersData.json()).length; i++) {
              let value = values[i];
              if (value.username == this.username) {
                value.username = "Me";
                //Add 'Me' on second position in dropdown
                this.users.splice(1, 0, value);
                continue;
              }
              this.users.push(value);
            }
            console.log(this.users);
          },
          usersErr => {
            console.log(usersErr);
          },
          () => {
            console.log("getAllUsersForDomain comspleted!");
          }
        )
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("getDomain service completed!");
      }
    )
  }

  public getBucketList(filter_name) {
    this.spinner.show();
    this.filterStr = filter_name;
    this.getPolicyDetails();
    console.log("Function call");
    console.log(this.filterStr);
    this.spotligh_color = "#3b78dc";
    // this.spinner.hide();
  }

  public getBucket(filter_name) {
    this.spinner.show();
    this.filterStr = filter_name;
    this.getPolicyDetails();
    console.log("Function call");
    console.log(this.filterStr);
    this.spotligh_color = "#ff7043";
    //this.spinner.hide();

  }
  public Bucket(filter_name) {
    this.spinner.show();
    this.filterStr = filter_name;
    this.getPolicyDetails();
    console.log("Function call");
    console.log(this.filterStr);
    this.spotligh_color = "#ff921c";
    // this.spinner.hide();

  }
  public BucketList(filter_name) {
    this.spinner.show();
    this.filterStr = filter_name;
    this.getPolicyDetails();
    console.log("Function call");
    console.log(this.filterStr);
    this.spotligh_color = "#9ccc65";
    //this.spinner.hide();

  }
  public DefultBucket(filter_name) {
    this.spinner.show();
    this.filterStr = filter_name;
    this.getPolicyDetails();
  }

  private getPolicyDetails(event?: PageEvent) {
    // this.scrollToTop();

    if (event != null) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
    if (this.paginator != null) {
      this.paginator.pageSize = this.pageSize;
      this.paginator.pageIndex = this.pageIndex;
    }
    this.dashboardService.setPageIndex(this.pageIndex);
    this.dashboardService.setPageSize(this.pageSize);

    this.showPreloader();
    this.policyDetailsList = "";

    this.selectedFilter = this.getFilter(this.filterStr);
    // this.setPriorityToAll();
    //this.dashboardService.setStage(this.selectedFilter);
    this.filtername = this.selectedFilter;
    //let dateStr: any = DateUtils.formatDate(this.createdAfter, 'YYYY-MM-DD hh:mm');
    let username = localStorage.getItem("USERNAME");

    if (this.selectedFilter == 'createdAfter')
      this.priorityFilter = 'All';
    console.log("*************date Before call************");
    console.log(this.createdAfter);
    this.policyService.getPolicyDetails("2017-01-01 00:00:00", this.pageIndex + 1, this.authHeader,
      this.selectedFilter, username, this.callType, this.pageSize,
      this.priorityFilter, this.userFilter, this.domain)
      .subscribe(data => {
        console.log("data : " + data);
        // let jsonData = Utils.parseJSON(data);
        this.spinner.hide();
        let jsonData = data.json();

        var array = [];
        array.push(jsonData);
        this.policyDetailsList = array[0];

        this.addDatasourceToTable(this.policyDetailsList);
        console.log("final bucket data is : ");
        console.log(this.dataSource);

        this.pageItemCount = Object.keys(jsonData).length;

        this.hidePreloader();
      },
        error => {
          console.log("getPolicyDetails service finished with error : " + error);
          // loadingCntrl.dismiss();
          // this.uiHelper.presentToast("Error while loading policies");
          this.hidePreloader();
          this.pageItemCount = 0;
          this.changeDetect.detectChanges();
        },
        () => {
          console.log("getPolicyDetails service completed!");
          this.hidePreloader();
          this.changeDetect.detectChanges();
        }
      );

  }

  allCustomers: any;
  carConnected: any;
  niCustomers: any;
  ncCustomers: any;


  /* 
  Spotlight count
  */
  getpolicyCounts(catType, cat, index) {
    console.log("domain+++++++:" + this.domain);
    this.policyService.getpolicyCount(catType, cat, this.domain, this.fromDate, this.toDate, this.authHeader)
      .subscribe(data => {
        console.log("data : " + data);
        let can_car = data.json()
        this.final_array[index] = can_car[0];
        console.log(this.final_array[index]);
        // can_car.category = cat;
        // this.final_array.push(can_car);
        console.log("final array: " + this.final_array);
        let temp = this.final_array;
        console.log("*********TotalCout***********");
        this.allCustomers = this.final_array[0]['AllCustomers'];
        this.carConnected = this.final_array[1]['Car Connected'];
        this.niCustomers = this.final_array[2]['NOT_INTERESTED'];
        this.ncCustomers = this.final_array[3]['NOT_CONTACTABLE'];
        console.log( this.allCustomers);
        console.log(this.carConnected);
        console.log(this.niCustomers);
        console.log(this.ncCustomers);
      },
        error => {
          console.log("getPolicycount service finished with error : " + error);
          this.pageItemCount = 0;
          this.changeDetect.detectChanges();
        },
        () => {
          console.log("getPolicycount service completed!");
          this.hidePreloader();
          this.changeDetect.detectChanges();
        }
      );



  }


  /**
     * Get spotlight statistics
     */
  public getSpotlightStats() {
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.filterStr = localStorage.getItem("defaultFilter");
    this.domain;

    this.userService.getDomain(this.username, this.authHeader).subscribe(data => {
      //(data);
      console.log("Domain is &&&&&&&&&&&&&&&&&&");
      console.log(data.json());
      let res = data.json();
      console.log(res[0].name);
      this.domain = res[0].name;
      console.log(this.domain);
    })

    console.log("Spotlight api params");
    console.log(this.authHeader);
    console.log(this.username);
    console.log(this.domain);
    this.statisticsService.getSpotlightStatistics(this.authHeader, this.username, this.domain)
      .subscribe(data => {
        console.log("************************userdata**********************");
        console.log(data);
        let res = data.json();
        console.log(res);
        //alert("api called");
        let dataObj = data.json();
        this.preShipmentmentStatCount = dataObj[0].count;
        this.postShipmentStatCount = dataObj[1].count;
        this.appointmentStatCount = dataObj[2].count;
        this.overallSpotlightStatCount = dataObj[3].count;
      }, err => {
        let errObj = err.json();
        //super.showAlert(errObj);
      }, () => { console.log("Statistics service for spotlight completed") });
  }


  public isDownloading: boolean = false;


  /**
   * Iterate on policy array create policy objects 
   * and attach those objects with Angular table as datasource.
   * 
   * @param policyArr 
   */
  public addDatasourceToTable(policyArr: any) {
    // Create 100 users
    const policy: Policy[] = [];
    for (let i = 0; i < Object.keys(policyArr).length; i++) {
      let policyJSON = policyArr[i];
      policy.push(createNewPolicy(policyJSON));
    }

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(policy);
    this.dataSource.sort = this.sort;
    this.initSort();
  }

  public showPreloader() {
    // $('.loaderbox').fadeIn();
  }

  public hidePreloader() {
    // $('.loaderbox').fadeOut();
  }

  public splitPhoneNumbers(phoneNumbers: string) {
    if (phoneNumbers == null)
      return;
    return StringUtils.splitPhoneNumbers(phoneNumbers);
  }

  public dateselected() {
    console.log("date selected clicked");

    this.getPolicyDetails();
  }


  public search(event) {
    console.log("search button clicked");
    this.spinner.show();
    this.getPolicyBySearchButton(event);
    console.log("******************serched succefully**********************");


  }

  /* 
  policysearch term
  */

  public getPolicyBySearchButton(searchTerm: string) {
    console.log("getPolicyBySearchQuery called : policy number : " + searchTerm);
    let username = localStorage.getItem("USERNAME");
    console.log("get policy for username " + username);
    this.spinner.show();
    this.policyService.getPolicyBySearchQuery(searchTerm, this.authHeader,
      username, this.dashboardService.domainName)
      .subscribe(data => {
        console.log("data : " + data);
        console.log('@#$@#$@#@$@#@$@$#@##@$#$@#@$');
        var array = [];
        array.push(data.json());
        this.policyDetailsList = array[0];
        this.spinner.hide();
        this.addDatasourceToTable(this.policyDetailsList);
        this.changeDetect.detectChanges();
      },
        error => {
          console.log("getPolicyBySearchQuery: service finished with error : " + error);
          this.hidePreloader();
          let array = [];
          this.addDatasourceToTable(array);
          this.changeDetect.detectChanges();
          this.spinner.hide();
        },
        () => {
          console.log("getPolicyBySearchQuery: Get policy by policy number api completed!");
          console.log('length' + length);
          this.spinner.hide();
        }
      );
  }




  /**
   * Email user
   * 
   * @param policy 
   * @param CustomerEmail 
   */
  public email(user) {

    let proxy = {
      signature: user.policyNumber,
      domainId: user.domainId,
      objectType: "InsurancePolicy"
    };

    let validEmail = StringUtils.getValidEmail(user.Staging_customerEmail);

    let data = { username: validEmail, type: "Email", stage: user.State, proxy: proxy, isEmailed: false };

    // let dialogRef = super.showDialogWithSizeAndInput(EmailDialogComponent, false, '600px', '1000px', data);
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log("Email dialog closed!");

    // });
  }

  /**
   * SMS user
   *
   * @param policy 
   * @param CustomerEmail 
   */
  public sms(user) {

    let proxy = {
      signature: user.policyNumber,
      domainId: user.domainId,
      objectType: "InsurancePolicy"
    };

    let validPhoneNo = StringUtils.getValidEmail(user.Staging_customerMobileNumber);

    let data = { username: validPhoneNo, type: "SMS", stage: user.State, proxy: proxy };

    //super.showDialogWithSizeAndInput(EmailDialogComponent, false, '600px', '600px', data);
  }

  public getEmailCount(policy: any) {
    return this.getObjectCount(policy, "Email");
  }

  public getObjectCount(policy, objectType) {
    let objectCount = 0;

    for (var i = 0; i < Object.keys(policy.timeline).length; i++) {
      if (policy.timeline[i].type == objectType && policy.State == this.selectedFilter)
        objectCount++;
    }
    if (objectCount == 0)
      return "";
    return objectCount;
  }

  public getSmsCount(policy: any) {
    return this.getObjectCount(policy, "SMS");
  }

  public getPhoneCallCount(policy: any) {

    return this.getObjectCount(policy, "Phone call");
  }

  public getCommentCount(policy: any) {
    return this.getObjectCount(policy, "Comment");
  }

  public onCallSelected(callType: string) {
    console.log("onCallSelected called");
    this.callType = callType;
    this.getPolicyDetails();
  }

  public onPrioritySelected(priority: string) {
    console.log("onPrioritySelected called");
    this.priorityFilter = priority;
    this.dashboardService.setPriority(this.priorityFilter);
    this.dashboardService.setPageIndex(0);
    this.pageIndex = 0;
    this.getPolicyDetails();
  }

  public openCommentView(policy) {
    console.log("openCommentView called");
    this.selectedUser = policy;
  }




  public getPriorityImage(priority) {
    if (priority == "Medium") {
      return "assets/ic_priority_medium.png";
    }

    if (priority == "High") {
      return "assets/ic_priority_high.png";
    }

    if (priority == "Low") {
      return "assets/ic_priority_low.png";
    }

    return "assets/ic_priority_medium.png";
  }



  //search
  searchuser() {
    console.log("search button clicked");
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");

    filter = input.value.toUpperCase();

    ul = document.getElementById("myUL");

    li = ul.getElementsByTagName("tr");

    for (i = 0; i < li.length; i++) {
      a = li[i];

      txtValue = a.textContent || a.innerText;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
        console.log("inside the search function");
      } else {
        li[i].style.display = "none";
      }
    }

  }

  /* car Driving details */

  carDrivingDetails() {
    this.policyService.CarperformanceDetails(this.PolicyNumber, this.authHeader, this.username, this.domain)
      .subscribe(data => {

        let car_details = data.json();
        console.log("car_details");
        console.log(car_details);
        console.log("**************************");


      })
  }


  /**
   * Customize data source to sort by by date on user activeOn
   */
  public initSort() {

    //for now init sort is not defined, since temporary returning from method.
    return;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'activity_by':
          if (item.activeDate != "") {
            let date = new Date(item.activeDate);
            return date;
          }
          return new Date();
        default: return item[property];
      }
    };
  }
}


/** Builds and returns a new Policy. */
function createNewPolicy(policyJSON: any): Policy {
  if (policyJSON == null) {
    return null;
  }

  let obj = {
    name: policyJSON.Staging_customerFirstName + " " + policyJSON.Staging_customerLastName,
    mobile: policyJSON.Staging_customerMobileNumber,
    email: policyJSON.Staging_customerEmail,
    policyNo: policyJSON.policyNumber,
    actions: "",
    policyObj: policyJSON,
    stagingCreatedOn: DateUtils.formatDate(policyJSON.StagingCreatedOn, DateUtils.FORMAT_DATE),
    priority: policyJSON.priority,
    activity_by: getOwner(policyJSON),
    owner: policyJSON.owner == null ? 'None' : policyJSON.owner,
    city: policyJSON.Staging_customerCity,
    activeOn: DateUtils.getDateDifference(getActiveOn(policyJSON)),
    activeDate: getActiveOn(policyJSON),
    callsCount: PolicyHelper.getNumberOfCalls(policyJSON, "Phone call")
  };

  return obj;
}

function getOwner(policyJSON: any): string {
  let timelineCount = Object.keys(policyJSON.timeline).length;
  if (timelineCount == 0)
    return "";
  let recentTimeline = policyJSON.timeline[0];
  return recentTimeline.user.username;
}

function getActiveOn(policyJSON: any): string {

  let timelineCount = Object.keys(policyJSON.timeline).length;
  if (timelineCount == 0)
    return "";
  let recentTimeline = policyJSON.timeline[0];

  if (recentTimeline.user.policyNumber == "dummy2013") {
    console.log("Found user");
  }

  return recentTimeline.createdOn;
}

export interface Policy {
  policyNo: string;
  name: string;
  email: string;
  mobile: string;
  actions: string;
  stagingCreatedOn: string;
  priority: string;
  owner: string;
  activity_by: string;
  city: string;
  activeOn: string;
  policyObj: any;
  activeDate: any;
  callsCount: any;
}

