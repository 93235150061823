import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class TimelineService {

    //create timeline API.
    createTimelineAPI:string = "/timeline/create";
    
    public atomUser:string; 

        constructor(private restClient: RestClient) {
            this.atomUser = localStorage.getItem("USERNAME");
        }
    
        createTimeline(proxyId, signature, message, type, tag, stage, domainId, username, authHeader) {  
            console.log("TimelineService: createTimeline called : proxyId : " + proxyId 
            +", message : " + message + ", type : " + type + ", tag : " + tag 
            + ", stage : " + stage + ", username : " + username);
            let headers = new Headers();
            headers.append('Content-Type','application/json');
    
            let body = {
                message : message,
                type : type,
                tag : tag,
                stage : stage,
                username : username,
                proxyObject : {
                    signature: signature,
                    domainId: domainId,
                    objectType: "InsurancePolicy"
                }
            }
    
            return this.restClient.execPOSTRequest(this.createTimelineAPI, authHeader, body);
        }

        /**
         * Delete timeline 
         * 
         * @param policyNumber 
         * @param domainId 
         * @param createdOn 
         * @param authHeader 
         */
        public removeTimeline(id:string, authHeader:string) {
            console.log("deleteTimeline called");

            let deleteTimelineAPI = `/timeline/remove/${id}`;

            return this.restClient.execDELETERequestNew(deleteTimelineAPI, authHeader);
        }
}
