import { AuthService } from './../../core/auth/AuthService';
import { AuthGuard } from './../../core/auth/AuthGuard';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/pages/sample-component/DashboardService';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyExporterService } from '../../services/policy-exporter.service';
import { UserService } from '../../services/user.service';
import { DomainManager } from '../../core/domain/DomainManager'
import { Domain } from 'src/app/core/domain/Domain';
import { Role } from 'src/app/core/role/Role';
declare var $: any;
declare var Waves:any;
declare var jQuery : any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  foods = [
    {value: 'ICICI', viewValue: 'ICICI'},
    {value: 'IL-Assist', viewValue: 'IL-Assist'},
    {value: 'Tagic', viewValue: 'TAGIC'},
    {value: 'Driven_dev', viewValue: 'DRIVEN_DEV'}
  ];
  public domainData;
  username:string;
  authHeader:string;
  public createdAfter =  new Date();
  public selectedFilter:string;
  public filterStr:string;
  public callType:string;
  public priorityFilter:string;
  public userFilter:any;
  public domain:string; 
  public domains:Domain[];
  public role:Role;
  public activeTheme;

  public users = [];
  public spotlights:any;
  public dataSource:any;
  user: any;

  constructor(
     private authService: AuthService,
    private dashboardService: DashboardService ,
    private route :  ActivatedRoute, 
    private policyService: PolicyExporterService,
    private userService: UserService,
    private router: Router
    ) { }

  ngOnInit() {
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.execGetDomainService(this.username,this.authHeader);
    console.log("DashboardComponent loaded");

    // this.loadChildComponent();
    
    // localStorage.setItem("defaultFilter", "call_customer");
    /* $('#reportContainer').hide();
    $('#loaderContainer').hide();

    Waves.attach('.dropdown-item', ['waves-block']); */
    
    this.execGetUserService(this.username, this.authHeader);
  }

  logout(){
    //alert("Logout");
    // this.auth.checkLogin('/login');

    this.authService.logout();
    this.dashboardService.invalidate(); 
  
  }

  public execGetDomainService(username:string, authHeader:string) {
    console.log("execGetDomainService called");

    this.userService.getDomain(username, authHeader).subscribe(
      data=> {
        this.domainData = data.json();
        this.domains = DomainManager.getDomains(this.domainData);
        console.log("Domain details : domain : " );
        console.log(this.domainData);
        if(this.dashboardService.getDomain() == null) {
          this.domain = this.domainData[0].name;
          this.dashboardService.setDomain(this.domain);
        } else {
          this.domain = this.dashboardService.getDomain();
        }

        console.log("************* Final Domain: ");
        console.log(this.domain);

      },
      err => {
        console.error("Error while fetching domain");
      },
      () => {
        console.log("Get domain service completed!");
      }
    )
  }

  public execGetUserService(username:string, authHeader:string) {
    console.log("execGetUserService called");

    this.userService.getUser(username, authHeader).subscribe(
      data => {
        var userData = data.json();
        console.log("User details:  user: " + userData);

        this.user = userData;

        this.execGetDomainService(this.username, this.authHeader);
      },
      err => {
        console.log("Error while fetching user : " + err);
      },
      () => {
        console.log("Get user service completed!");
      }
    );
  }

 

  /**
   * Set theme color
   */
  public setColor() {
    var $body = $('body');
    $body.removeClass(this.activeTheme);
    if(this.domain == 'Drivesmart') {
      this.activeTheme = 'theme-indigo';
    } else if(this.domain == 'Driven') {
      this.activeTheme = 'theme-cyan';
    } else if(this.domain == 'IL-Assist') {
      this.activeTheme = 'theme-deep-orange';
    } else if(this.domain == 'TAGIC') {
      this.activeTheme = 'theme-green';
    }
    $body.addClass(this.activeTheme); 
  }

  /**
   * Domain selected, refresh UI
   * @param domain 
   */
  public onDomainSelected(domain:string) {
    console.log("onDomainSelected " + domain);

    this.domain = domain;
    this.dashboardService.setDomain(domain);
    this.execGetUserService(this.username, this.authHeader);
    console.log("Current route: " + this.router.url);
    this.setColor();
    this.router.navigate(['dashboard'], { replaceUrl: true });
  }
}
