import {MatMenu} from '@angular/material';
import {ViewChild} from '@angular/core';
import {AuthService} from '../../../core/auth/AuthService';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UserService } from "../../../services/user.service";
import { PolicyExporterService } from "../../../services/policy-exporter.service";
import { BaseComponent } from '../../../core/baseui/BaseComponent'
import { Injector } from '@angular/core'

@Component({
  selector: 'app-update-owner',
  templateUrl: './update-owner.component.html',
  styleUrls: ['./update-owner.component.css'],
  exportAs: 'ownerComponent'
})
export class UpdateOwnerComponent extends BaseComponent implements OnInit {

  @ViewChild(MatMenu) menu: MatMenu;  

  @Input()
  user:any;    

  @Output() ownerUpdated = new EventEmitter<string>();

  private authHeader:any;
  private username:any;

  selectedUser:string = "";

  owner: any;  

  initialOwner:string;

  options = [
  ];

  constructor(public userService: UserService,
     public exporterService:PolicyExporterService, public injector : Injector) {
    super(injector);
    this.authHeader = this.authService.getBasicAuth();
    this.username = this.authService.getUsername();
  }


  ngOnInit() {
    console.log("UpdateOwnerComponent: ngOnInit: user: " + this.user);

    this.owner = this.user.owner;
    if(this.owner == null) {
      this.selectedUser = "None";
    } else {
      this.selectedUser = this.owner;
    }
    this.initialOwner = this.selectedUser;

    this.userService.getDomain(this.username, this.authHeader).subscribe(
      data=>{
        let domain = data.json();
          console.log(domain);
        this.userService.getAllUsersForDomain(domain[0].name, this.authHeader).subscribe(
          usersData=>{
            let users = this.removeCurrentUserFromList(usersData.json());
            this.options = users;
          },
          usersErr =>{
            console.log(usersErr);
          },
          ()=>{
            console.log("getAllUsersForDomain completed!");
          }
        )
      },
      err=>{
          console.error(err);
      },
      ()=>{
          console.log("getDomain service completed!");
      }
    )
  }


  /**
   * Update owner of the current policy
   */
  public onSelect() {
    console.log("updateOwner called: user: " + this.user +", selectedUser : " + this.selectedUser);

    if(this.initialOwner == this.selectedUser) {
      return;
    }
    this.initialOwner = this.selectedUser;
    
    this.exporterService.updateProxyDetails(this.user.domainName, "InsurancePolicy", this.user.policyNumber,
              this.selectedUser, this.user.domainId, this.authHeader).subscribe(
                  data=>{
                    console.log("updateProxyDetails: data: " + data.json());
                    super.showSnackBar("Task assigned to " + this.selectedUser);
                    this.ownerUpdated.next(this.selectedUser);
                  },
                  err=>{
                    console.error(err);
                    super.showSnackBar("Error while changing owner to " + this.selectedUser);
                  },
                  ()=>{
                    console.log("updateProxyDetails service completed!");
                  }
    )
  }

  public removeCurrentUserFromList(usersData: any): any[] {

    let finalUsers = [];

    for(let i = 0; i < Object.keys(usersData).length ; i++) {
      let user = usersData[i];
      if(user.username != this.username)
        finalUsers.push(user);
    } 

    return finalUsers;
  }
}
