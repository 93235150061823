import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';  
import { Http, Headers, RequestOptions } from '@angular/http';  
import { Server } from "../constants/SERVER"

@Injectable()
export class RestClient {

    /**
     * Parameterized constructor
     * @param http  injected http object as this client object
     */
    constructor(private http: Http) {
        //blank impelementation.
    }


    /**
     * Execute GET request
     * 
     * @param url           Execute get request
     * @param authHeader    Authentication header of user
     */
    public execGETRequest(url, authHeader?:any, overrideBaseUrl?:any) : any {
        console.log("RestClient: execGETRequest called: url : " + url +", authHeader  : " + authHeader);
        //Prepare header values
        let headers = new Headers();
        headers.append('Content-Type','application/json');

        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }
        //Create request object
        let options = new RequestOptions({ headers : headers});

        console.log(options);

        //return object of type Observable.
        if(overrideBaseUrl) {
            console.log("api before call");
            let temp =  this.http.get(url, options);
            console.log(temp);
            return temp;
        } else {
            let finalUrl : string = Server.BASE_URL + url;
            console.log("Final URL: " + finalUrl);
            return this.http.get(finalUrl, options);
        }
    }


    /**
     * Execute POST request
     * 
     * @param url               url for post request
     * @param authHeader        auth header of authenticated user
     * @param body              data to post to server
     */
    public execPOSTRequest(url, authHeader, body) {
        console.log("RestClient : execPostRequest called : url : " + url 
        + ", authHeader:" + authHeader+", body : " + body);

        let headers = new Headers();
        headers.append('Content-Type','application/json');
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }

        let finalUrl = Server.BASE_URL + url;

        console.log("RestClient: execPOSTRequest: Final url is : " + finalUrl);
        return this.http.post(finalUrl, JSON.stringify(body), {headers : headers});
    }


        /**
     * Execute POST request
     * 
     * @param url               url for post request
     * @param authHeader        auth header of authenticated user
     * @param body              data to post to server
     */
    public execPOSTRequestFile(url, authHeader, body) {
        console.log("RestClient : execPostRequest called : url : " + url 
        + ", authHeader:" + authHeader+", body : " + body);

        let headers = new Headers();
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }

        let finalUrl = Server.BASE_URL + url;

        console.log("RestClient: execPOSTRequest: Final url is : " + finalUrl);
        return this.http.post(finalUrl, body, {headers : headers});
    }

    public execPOSTRequestWithFullUrl(url, authHeader, body) {
        console.log("RestClient : execPostRequest called : url : " + url 
        + ", authHeader:" + authHeader+", body : " + body);

        let headers = new Headers();
        headers.append('Content-Type','application/json');
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }
        headers.append('Access-Control-Allow-Origin','*');

        console.log("RestClient: execPOSTRequest: Final url is : " + url);
        return this.http.post(url, JSON.stringify(body), {headers : headers});
    }


    /**
     * Execute PUT request
     * 
     * @param url 
     * @param authHeader 
     * @param body 
     */
    public execPUTRequest(url, authHeader, body) {
        console.log("RestClient : execPUTRequest called : url : " + url 
        + ", authHeader:" + authHeader+", body : " + body);

        let headers = new Headers();
        headers.append('Content-Type','application/json');
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }

        let finalUrl = Server.BASE_URL + url;

        console.log("RestClient : execPUTRequest: Final url is : " + finalUrl);
        return this.http.put(finalUrl, JSON.stringify(body), {headers : headers});
    }





    /**
     * Execute DELETE request
     * 
     * @param url 
     * @param authHeader 
     * @param body 
     */
    public execDELETERequest(url, body, authHeader) {
        console.log("RestClient : execDELETERequest called : url : " + url 
        + ", authHeader:" + authHeader);

        let headers = new Headers();
        headers.append('Content-Type','application/json');
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }

        let finalUrl = Server.BASE_URL + url;

        console.log("RestClient : execDELETERequest: Final url is : " + finalUrl);
        return this.http.delete(finalUrl, new RequestOptions({
            headers: headers,
            body: body
         }));
    }


    /**
     * Delete request without 
     * 
     * @param url 
     * @param authHeader 
     */
    public execDELETERequestNew(url, authHeader) {
        let headers = new Headers();
        headers.append('Content-Type','application/json');
        if(authHeader != null) {
            headers.append('Authorization', authHeader);
        }

        let finalUrl = Server.BASE_URL + url;
        let options = new RequestOptions({ headers : headers});
        return this.http.delete(finalUrl, options);
    }
}