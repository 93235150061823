import { RestClient } from '../rest_client/RestClient'
import { Injectable } from '@angular/core';
import { StringUtils } from '../utils/StringUtils'
import { PageEvent } from '@angular/material';


@Injectable()
export class ExporterService {

  constructor(private restClient: RestClient) {
  }

  /**
   * Enlist all exporters
   * 
   * @param authHeader 
   */
  public enlistExporters(authHeader: string) {
    let endPoint = "/atomAdmin/exporters/enlist";

    return this.restClient.execGETRequest(endPoint, authHeader, false);
  }

  /**
   * Download exported data
   * 
   * @param authHeader 
   * @param body 
   */
  public downloadExportedData(authHeader: string, exporterName: string, queryType: string, body: any, fileType: string, async: boolean) {
    let downloadEndPoint = `/atomAdmin/exporters/export/${exporterName}/${queryType}/${fileType}`;

    let asyncEndPoint = `/atomAdmin/exporters/download/async/${exporterName}/${queryType}/${fileType}`;

    let endPoint = async ? asyncEndPoint : downloadEndPoint;

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }


  /**
   * GEt all downloads
   */
  /* public getAllDownloads(authHeader:string, pageNumber:number, pageSize:number) {
    let endPoint = `/exportedData/enlist/${pageNumber}/${pageSize}`

    return this.restClient.execGETRequest(endPoint, authHeader, false);
  } */

  public getAllDownloads(authHeader: string, domain:string, pageNumber: number, pageSize: number, startDate: any, endDate: any) {

    console.log("Start Date" + startDate);
    console.log("End Date" + endDate);
/*     let endPoint = '/exportedData/enlist/uploadedFiles/IL-Assist/' + startDate + "/" + endDate + "/" + pageNumber + "/" + pageSize;
 */    

    let endPoint = '/exportedData/enlist/uploadedFiles/' + domain + "/" + startDate + "/" + endDate + "/" + pageNumber + "/" + pageSize;

 return this.restClient.execGETRequest(endPoint, authHeader, false);
  }

  public getReportSearch(authHeader: string, domain:string, key:string, pageNumber: number, pageSize: number ){

    let endPoint= '/exportedData/enlist/uploadedFilesByKeyWord/' + domain + "/" + key + "/" + pageNumber + "/" + pageSize;
   
  
    return this.restClient.execGETRequest(endPoint, authHeader, false);
 
  }



}
