import { Component, OnInit, Injector, Input, OnDestroy, ViewChild, ElementRef, Inject, HostListener } from '@angular/core';
import { DateUtils } from './../utils/DateUtils';
import { Domain } from './.././core/domain/Domain';
import { RestClient } from '../rest_client/RestClient'
import { Utils } from "./../utils/Utils";
import { FormGroup, FormControl } from "@angular/forms";
import { Http, Headers, RequestOptions } from '@angular/http';

import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { FormControlService } from "./../core/dynamicformgroup/FormControlService";
import { FormFactory } from "./../core/dynamicformgroup/factory/FormFactory";
import { AForm } from "./../core/dynamicformgroup/AForm";
import { BaseComponent } from "./../core/baseui/BaseComponent";
import { ExporterService } from "./../services/ExporterService";
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseContentType, } from '@angular/http';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { UserService } from './../services/user.service';
import { LoaderService } from './../services/LoaderService';
import { DashboardService } from '../pages/sample-component/DashboardService';
import { DOCUMENT } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import * as moment from 'moment';
import {/* default as _rollupMoment, */ Moment } from 'moment';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
export interface DialogData {
  animal1: 'panda' | 'unicorn' | 'lion';
}

declare var $: any;


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatInput } from '@angular/material';
import { Dialogue1Component } from '../dialogue1/dialogue1.component';
import { ViewComponent } from '../view/view.component';
import { PolicyExporterService } from '../services/policy-exporter.service';
import { HttpClient } from 'selenium-webdriver/http';
import * as jsPDF from 'jspdf'




export interface Report {
  //name of report
  exporterName: string;

  //report created on
  createdOn: string;

  //status of report
  status: string;

  //query type used while generating report
  queryType: string;

  //input json posted while generating report
  inputJson: string;

  //file url
  fileUrl: string;

  exporterDescription: string;

  /* input: any; */


}

export interface DialogData {
  animal: string;
  name: string;
}
export interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],

  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, ExporterService, DashboardService]
})

export class ReportsComponent extends BaseComponent implements OnInit {

  public displayedColumns: string[] = ['status-icon', 'reportName', 'createdOn', 'status', 'queryType', 'inputJson', 'fileUrl'];
  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2022, 0, 1);
  public domain;
  value = 'Clear me';
  public key;
  animal: string;
  name: string;
  public username: string;
  public users = null;
  public authHeader: string;
  //misReport:any;
  all_customer: any;
  urlArray:any;
  date = new FormControl(moment());
  domain1: string;
  selectedyear: any;
  selectedmonth: any;
  status = false;
  start_date = "2018-01-01 00:00:00";
  end_date = "2020-04-31 00:00:00";

  dateStatus: any = true;

  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Type' },
    { value: 'pizza-1', viewValue: 'ALL' },
    { value: 'tacos-2', viewValue: 'type2' }
  ];
  sourtce1: any;
  source1: any;
  fileUrl: any;
  file: string;
  source2: any;
  FinalURL: any;
  filename: any;
  tempData: any;
  finalReport: any;
  /* console.log(this.date); */

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedyear = ctrlValue.year();
    console.log('year' + ctrlValue.year());
    this.date.setValue(ctrlValue);

  }

// prevent to Right click for security reason
// download report
// Pramod pudale - 16 JUN
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
  event.preventDefault();
 }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    console.log("Selected date value is : " + ctrlValue);

    ctrlValue.month(normalizedMonth.month());
    this.selectedmonth = ctrlValue.month();
    console.log('month' + ctrlValue.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.key = this.selectedmonth + '/' + this.selectedyear;
    //Call getReports()
    console.log("Calling reports");
    // this.start_date = this.selectedyear + "-" + this.selectedmonth + "-" + "01";
    console.log(this.key);
    // this.getReports();
    this.getReportsMIS();
    // this.getReports();
  }
  @ViewChild('content') content: ElementRef;


  @ViewChild('myInput') myInput: ElementRef;

  @ViewChild('dp', {
    read: MatInput
  }) fromInput: MatInput;



  resetForm() {
    this.fromInput.value = '';
  }


  public reports: Report[] = [];
  public misReport:any;
  public urlData: Report[] = [];
  constructor(public injector: Injector, public exporterService: ExporterService, private spinner: NgxSpinnerService
    , public dialog: MatDialog, public userService: UserService, private activatedroute: ActivatedRoute, private http: Http
    , public dashboardService: DashboardService, public router: Router, private policyService: PolicyExporterService, public sanitizer: DomSanitizer
    , @Inject(DOCUMENT) document) {

    super(injector);

    this.username = localStorage.getItem("USERNAME");
    this.domain = localStorage.getItem("domainName");

  }

  ngOnInit() {
    /*  this.getReports(); */
    this.authHeader = localStorage.getItem("AUTH");
    this.domain = localStorage.getItem("domainName");

    console.log("Auth header retrived from local : " + this.authHeader);


    this.activatedroute.params.subscribe(data1 => {
      console.log("input params " + data1['filter'])
      /*  this.domain = data1['domain']; */
      this.domain1 = data1['domain'];
      /*  this.authHeader=params.header; */
      // console.log("get domainname from param" + this.domain1);
      // console.log('********************************************')
      /*  this.getPolicyDetails(); */
      this.loadUsers(this.username, this.authHeader);
      console.log("Date formating")
      var newdate = new Date();
      this.end_date = moment(newdate).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.end_date);

      var d = new Date();
      d.setMonth(d.getMonth() - 1);
      this.start_date = moment(d).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.start_date);


    });


    this.loadUsers(this.username, this.authHeader);
    this.getReports();
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);

    $(function () {
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      for (let i = new Date().getFullYear(); i > 2018; i--) {
        $.each(months, function (index, value) {
          $('#yearMonthInput').append($('<option />').val(index + "_" + i).html(value + " " + i));
        });
      }
    });


  }


  public loadUsers(username: string, authHeader: string) {
    console.log("SampleComponent: loadUsers called");
    console.log('this is domain' + this.domain);
    this.userService.getDomain(this.username, this.authHeader).subscribe(
      data => {

        console.log("Domain is &&&&&&&&&&&&&&&&&&");
        console.log(data.json());
        let res = data.json();
        console.log(res[0].name);
        this.domain = res[0].name;
        this.getReportsMISV2();
        console.log('this is domain ***********************' + this.domain);
      },
      err => {
        console.error(err);
      },
      () => {
        console.log("getDomain service completed!");
      }
    )
  }

  /* get MIS report data */

  getReportsMIS() {
    console.log("Get report API is called");
    let startDate = moment(new Date(this.selectedyear, this.selectedmonth, 1)).format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment(new Date(this.selectedyear, this.selectedmonth + 1, 0)).format("YYYY-MM-DD HH:mm:ss");

    this.policyService.getMISReport(this.authHeader, this.username, this.domain, startDate, endDate)
      .subscribe(data => {
        console.log("inside the MIS report");
        var temp = data.json();
        //this.misReport = temp;
        console.log(temp);
        console.log("MIS Report json:");
        this.finalReport = [];
        for(let j = 0; j< temp.length; j++){
          let row = temp[j];
         if(row.fileUrl != null){
           this.finalReport.push(row);
           //console.log("this.finalReport:" + this.finalReport)
         }
         
        }
        console.log("sorted data");
        console.log(this.finalReport);
        console.log("old data:");
        console.log(temp);

        for(let i = 0; i< this.finalReport.length; i++){
           console.log("inside the for loop:" +i);
          this.urlArray =  this.finalReport[i].fileUrl;
          console.log("URL Array:" + this.urlArray);
          //this.finalReport = [];
          this.tempData = this.urlArray.includes(".html.zip");

          if(this.tempData == true){
            this.finalReport[i].isZip = true;
          }else{
            console.log("else candition");
            this.finalReport[i].isZip = false;
            console.log(this.finalReport);
          }
      }
      })
  }



    /* get MIS report data */
    // for first time this api is called V2 and afer selecting year and month getReportMIS API is calling
    getReportsMISV2() {
      this.policyService.getMISReport(this.authHeader, this.username, this.domain, this.start_date, this.end_date)
        .subscribe(data => {
          console.log("inside the MIS report");
          var temp = data.json();;
          this.misReport = temp;
          console.log(temp);
        
          this.finalReport = [];
        for(let j = 0; j< temp.length; j++){
          let row = temp[j];
         if(row.fileUrl != null){
           this.finalReport.push(row);
           //console.log("this.finalReport:" + this.finalReport)
         }
         
        }
        console.log("sorted data");
        console.log(this.finalReport);
        console.log("old data:");
        console.log(temp);

        for(let i = 0; i< this.finalReport.length; i++){
           console.log("inside the for loop:" +i);
          this.urlArray =  this.finalReport[i].fileUrl;
          console.log("URL Array:" + this.urlArray);
          //this.finalReport = [];
          this.tempData = this.urlArray.includes(".html.zip");

          if(this.tempData == true){
            this.finalReport[i].isZip = true;
          }else{
            console.log("else candition");
            this.finalReport[i].isZip = false;
            console.log(this.finalReport);
          }
      }
        })
    }

  /**
   * Get top 20 reports;
   * 
   * @param authHeader 
   */
  public getReports(): void {
  
    let startDate = moment(new Date(this.selectedyear, this.selectedmonth, 1)).format("YYYY-MM-DD HH:mm:ss");
    let endDate = moment(new Date(this.selectedyear, this.selectedmonth + 1, 0)).format("YYYY-MM-DD HH:mm:ss");
    this.exporterService.getAllDownloads(this.authService.getBasicAuth(), this.domain, 1, 499, startDate, endDate)
      .subscribe(data => {
        let dataArray = data.json();
        console.log("APi Response");
        /*  console.log(this.reports); */
        this.finalReport = [];
        for (let i = 0; i < Object.keys(dataArray).length; i++) {
          let reportJson = dataArray[i];
          this.finalReport.push(this.createReport(reportJson));
        }
        console.log(this.reports);


      }, error => { super.showAlert("Error while fetching list of reports: " + error) }
        , () => { console.log("Report dowloading completed!") });
  }

  /**
   * Create a report
   * @param reportJson 
   */
  public createReport(reportJson: any): Report {
    if (reportJson == null)
      return null;
    return {
      exporterName: reportJson.exporterName,
      createdOn: DateUtils.epocToDate(reportJson.createdOn),
      status: reportJson.status,
      queryType: reportJson.queryType,
      inputJson: reportJson.inputJSON.substring(13, reportJson.inputJSON.lastIndexOf('}')),
      fileUrl: reportJson.fileUrl,
      exporterDescription: reportJson.exporterDescription


    }
  }

  /**
   * Get status icon
   * @param report 
   * @param status 
   */
  public getStatusIcon(status): string {
    if (status == 'Complete')
      return 'assets/ubi/ic_success.png';

    if (status == 'Failed')
      return 'assets/ubi/ic_failure.png';

    if (status == 'In Progress')
      return 'assets/ubi/ic_gear_loader.gif';
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(Dialogue1Component, {
      width: '100%',
      height: '100%',

      data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
      // this.getReports();
    });
  }

  openDialognew(url, heading, filename) {
    console.log("open dialog");
    console.log("URL:" +url);
    let dialogRef = super.showDialogWithSizeAndInput(ViewComponent, true, '660px', '1300px',
      {
        url: url,
        heading: heading,
        Filename: filename
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
      // this.getReports();
    });
  //}
  }
  dropClick() {
    console.log("dropdown work");
  }


  searchuser() {
    console.log("search button clicked");
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");

    filter = input.value.toUpperCase();

    ul = document.getElementById("myUL");

    li = ul.getElementsByTagName("tr");

    for (i = 0; i < li.length; i++) {
      a = li[i];

      txtValue = a.textContent || a.innerText;

      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
        console.log("inside the search function");
      } else {
        li[i].style.display = "none";
      }
    }

  }

  shwAtt(strPath) {
    var varExt = strPath.split('.');
    //alert(varExt.length);
    if (varExt[varExt.length - 1] == "txt") {
      window.open(strPath);
      alert("IF mehode called ");

    }
    else {
      var iframe;
      iframe = document.getElementById("frame");
      console.log(iframe.outerHTML);
      console.log("iframe[0]");
      console.log(JSON.stringify(iframe));

      if (iframe == null) {
        iframe = document.createElement('iframe');
        alert(iframe);
        iframe.id = "frame";
        iframe.style.visibility = 'hidden';
        let temp = document.body.appendChild(iframe);
        alert(temp);

      }
      iframe.src = strPath;
      console.log(iframe.src);
    }
    return false;
  }

  // search
  search() {

    this.dateStatus = false;
    this.exporterService.getReportSearch(this.authService.getBasicAuth(), this.domain, this.key, 1, 499)
      .subscribe(data => {

        let dataArray = data.json();

        console.log(dataArray);
        console.log('search clicked');

        this.finalReport = [];

        for (let i = 0; i < Object.keys(dataArray).length; i++) {
          let reportJson = dataArray[i];
          this.finalReport.push(this.createReport(reportJson));
        }

        console.log("Afer MIS report PUSh");
        console.log(this.finalReport)


      }

      );

  }

  focusFunction() {
    console.log('focused is selected');

    this.dateStatus = false;
  }

  focusOutFunction() {
    console.log('out focused is selected');
    this.dateStatus = true;
    this.getReportsMIS();


  }
  downloadFile(url) {
    console.log("inside the download function");
    let link = document.createElement("a");
    link.download = "Report";
    link.href = url;
    link.click();
  }


  showReport(url) {

    console.log("url is :");
    console.log(url);
    // window.location(url)
    window.location.href = url;

  }


  /* Download Html file */

  FileDownload(URL, filename) {
    this.FinalURL = '<iframe _ngcontent-c7="" style="height:100%; width:100%" frameborder="0" id="frame" src="' + URL + '"></iframe>'
    this.filename = filename;
    this.finaldownload(this.FinalURL, this.filename);
  }

  finaldownload(data, name) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const blob = new Blob([data], { type: 'application/text' }),
      url = window.URL.createObjectURL(blob);
    console.log(navigator);
    var str = name;
    console.log("Original data: ",str);
    str = str.slice(0, -5);
    var file = str;
    console.log("After truncate: ",file);
    if (navigator.appVersion.toString().indexOf('.NET') > 0 && window.navigator.msSaveBlob) {
      console.log('from Explorer', window.navigator);
      let val = window.navigator.msSaveBlob(blob, file + '.html');
      console.log(val)
    }
    else {
      a.href = url;
      a.download = file + '.html';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }


}
