import {Observable} from 'rxjs';
import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 
import { PageEvent } from '@angular/material';

@Injectable()
export class LoaderService {

  constructor(private restClient: RestClient) {
  }


  /**
   * Enlist all loaders
   * 
   * @param authHeader 
   * 
   */
  public enlist(authHeader:string) : any {
    let endPoint = `/admin/loader/enlist`;

    return this.restClient.execGETRequest(endPoint, authHeader, false);
  }

  public loadSingle(authHeader:string, loader:string, body:any) {

    let endPoint = `/admin/loader/loadSingle/${loader}`;

    return this.restClient.execPOSTRequest(endPoint, authHeader, body);
  }

  /**
   * Upload file
   * 
   * @param authHeader 
   * @param loader 
   * @param file 
   */
  public uploadFile(authHeader:string, loader:string, file:File) {
    let endPoint = `/admin/loader/load/${loader}`; 

    const formData: FormData = new FormData();
    formData.append('csvfile', file);

    return this.restClient.execPOSTRequestFile(endPoint, authHeader, formData);
  }





  /**
   * Upload file
   * 
   * @param authHeader 
   * @param domain
   * @param file 
   */
  public uploadFile1(authHeader:string, domain:string, file:File, description:string) {
    console.log("uploadFile1:");
    console.log("authHeader" + authHeader);
    console.log("domain: " + domain);
    console.log("file: " +file); 

    let endPoint = `/exportedData/load/` + domain;
   

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('text', description);

    return this.restClient.execPOSTRequestFile(endPoint, authHeader, formData);
  }
}



