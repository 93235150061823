import { Component, OnInit, Injector, Inject } from '@angular/core';
import { AdminBSBTemplate } from '../../core/adminbsb'
import { RouterModule, Routes , ActivatedRoute} from '@angular/router';
import { PolicyExporterService } from '../../services/policy-exporter.service';
import { TagService } from '../../services/TagService'
import { StringUtils } from '../../utils/StringUtils';
import { ProgressDialogComponent } from '../../pages/utils/progress-dialog/progress-dialog.component'
import { EmailDialogComponent } from '../utils/email-component/email-dialog.component'
import { UserDetailBaseComponent } from '../user-detail/UserDetailBaseComponent'
import { StagingService } from '../../services/StagingService'
import { TimelineService } from '../../services/timeline.service'
import { AuthService } from '../../core/auth/AuthService'
import { Role } from "../../core/role/Role";
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

declare var $:any;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-stage-details.component.html',
  styleUrls: ['./user-detail.component.css'], 
  providers: [ TagService, StagingService, TimelineService ] 
})
export class UserStageDetailComponent extends UserDetailBaseComponent implements OnInit {

  public authHeader:string;

  myControl: FormControl = new FormControl();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  
  constructor(
    private injector: Injector, 
    private stagingService:StagingService,
    public dialogRef: MatDialogRef<UserStageDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      super(injector);
      this.authHeader = localStorage.getItem("AUTH");
      console.log("data!!!!!!!!!!!!!: " + data);
      console.log(data);
      this.policyNumber = data.policy;
      this.domain = data.domain;
  }

  public ngOnInit() {
    super.ngOnInit(); 
    this.initTag();

  }

  public updatePolicy() {
    console.log("updatePolicy called");

    if(this.user.hasOwnProperty('timeline')) {
      delete this.user['timeline'];
    }

    this.stagingService.load(this.user, this.authHeader)
    .subscribe( data=> {
      console.log(JSON.stringify(data));
      super.showAlert("Updated successfuly");
    }, err=>{
      console.log(JSON.stringify(err));
      super.showAlert("Error while updating policy:  " + JSON.stringify(err));
    }, ()=>{
      console.log("updatePolicy service completed!");
    });
  }

  public verify() {
    console.log("verify called");
    console.log(this.user);

    if(this.user.hasOwnProperty('timeline')) {
      delete this.user['timeline'];
    }


   /*  super.confirmDialog("Confirm verification!", "Once verfied, Later it cannot be undone")
    .then((result) =>{
      if(result.value) {
        console.log("Verify clicked");

        this.user.Verified = true;
        
            this.stagingService.load(this.user, this.authHeader)
            .subscribe( data=> {
              console.log(JSON.stringify(data));
              super.showAlert("Verified successfuly");
              this.getPolicyByPolicyNumber(this.policyNumber);
            }, err=>{
              console.log(JSON.stringify(err));
              super.showAlert("Error while verifying policy:  " + JSON.stringify(err));
            }, ()=>{
              console.log("updatePolicy service completed!");
            });
      } else {
        console.log("Verify not clicked");
      }
    });
  } */
  }
  public initTag() { 
    
     //Add tag listener
     $('#tagInputbox').on('itemAdded', function(event) {
        console.log("Tag added : name : " + event.item);
       let tagName = event.item;
        UserStageDetailComponent.tagObjectLocalFunc(tagName);
    });
       //Remove tag listener
     $('#tagInputbox').on('itemRemoved', function(event) {
       console.log("Tag removed : name : " + event.item);
      // UserDetailBaseComponent.untagObject(event.item);
      });
  }
  static tagObjectLocalFunc(tagName: any): any {
    throw new Error("Method not implemented.");
  }

  // static tagObjectLocalFunc(abc) {
  //   console.log("myfunction called : abc : " + abc);
  //   UserDetailBaseComponent.tagObject(abc);
  // }

  public onOwnerChanged(event:any) {
    console.log("Owner changed");
    console.log(event);
    this.user.owner = event;
  }

  public onMarkChanged(event:any) {
    console.log("Mark changed");
    this.getPolicyByPolicyNumber(this.policyNumber);
  }

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log(value);

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push({name: value.trim()});

      //give call to server
      this.tagObject(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    console.log(tag);
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.untagObject(tag.name);
  }
}