/// <reference types="@types/googlemaps" />
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../services/user.service'
import { PolicyExporterService } from '../services/policy-exporter.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as FusionCharts from 'fusioncharts';
import { DashboardService } from '.././pages/sample-component/DashboardService';
import { DomainManager } from '.././core/domain/DomainManager'
import { AuthService } from '../core/auth/AuthService';
import { MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import 'jquery-mapael';
import { Http, Headers, RequestOptions } from '@angular/http';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';
import { Observable } from 'rxjs/Rx'
import { distinct } from 'rxjs-compat/operator/distinct';
import { timeout } from 'rxjs-compat/operator/timeout';
/* import { setTimeout } from 'timers'; */
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import ExportingModule from 'highcharts/modules/exporting';
import { NgxSpinnerService } from 'ngx-spinner';
import { ElementRef, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { state } from '@angular/animations';
//var moment = require('moment/moment');
import * as moment from 'moment';
 import * as jsPDF from 'jspdf';
// import 'jspdf-autotable';
//declare var jsPDF: any;
import * as $ from "jquery";

ExportingModule(Highcharts);
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);



export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {


  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('content') content: ElementRef;

  public domainData;
  public domain: string;
  public domains
  public user: any;
  animal: string;
  name: string;
  // temp_data: any = data2;
  foods = [
    { value: 'ICICI', viewValue: 'ICICI' },
    { value: 'IL-Assist', viewValue: 'IL-Assist' },
    { value: 'Tagic', viewValue: 'TAGIC' },
    { value: 'Driven_dev', viewValue: 'DRIVEN_DEV' },

  ];
  selected = 'option1';
  selected1 = 'option1';
  stateArray: any = [];
  public pageItemCount: number = 0;
  public fromDate: any;
  public toDate: any;
  data1: any = [];
  public fromDate1: any = '2019-01-01 00:00:00';
  public toDate1: any = new Date();

  //dateObj = new Date();
  // fromDate1= this.toDate1.setMonth(this.toDate1.getMonth() - 6);
  // myDate = moment(this.fromDate1).format('E, dd MMM yyyy HH:mm:ss z``');
  //moment.utc(fromDate1).toDate();

  f_array: any = [];
  f_n1: any = [];
  f_n2: any = [];
  f_n3: any = [];
  final_array: any = [];
  final_array1: any = [];
  final_array2: any = [];
  final_array3: any = [];
  final_array4: any = [];
  final_array5: any = [];
  final_array6: any = [];
  monthsArray: any = [];
  newMonthArray: any = [];
  f_a: any = [];
  TotalDistance_array: any = [];
  SafetyDistance_array: any = [];
  dataSource: any = [];
  dataSource1: any = [];
  dataSource2: any = [];
  satisfactionChart: any = [];
  data_test: any = [];
  final_array7: any = [];
  username: string;
  authHeader: string;
  arraydata: any;
  total: number = 0;
  all_cust: any;
  cars_connect: any;
  mapael: any;
  map: any;
  all: any;
  cars: any;
  all_delivered: any;
  all_onboard: any;
  all_shipped: any;
  all_verified: any;
  jQuery: any;
  $: any;
  label: any;
  width = 600;
  height = 210;
  type = "msline";
  dataFormat = "json";
  type1 = "funnel";
  ss
  last_item: any;
  arraydata1: any;
  last_item1: any;
  key = 'AIzaSyCGXD1MdkjAp90mkmwEfjsuoO-hTvYW60I';
  type2 = "stackedcolumn2d";
  width1 = 600;
  height1 = 195;
  dataSource5;
  statedata: any;
  policyCount:any;
  counts: any;
  saftycount: any;
  type3 = "mscolumn2d";
  //dataSource4 = data;
  infowindow: any;
  locations;
  stateCount: any;
  ActiveVehCount: any;
  RenwRate: any;

  constructor(private userService: UserService, private policyService: PolicyExporterService,
    private changeDetect: ChangeDetectorRef, private route: ActivatedRoute
    , private dashboardService: DashboardService, private authService: AuthService, public dialog: MatDialog, private http: Http, private spinner: NgxSpinnerService) {
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.domain = localStorage.getItem("domainName");

    console.log('******Domain***********************************:' + this.domain);
  }

  ngOnInit() {

    this.authHeader = localStorage.getItem("AUTH");
    this.username = localStorage.getItem("USERNAME");
    this.authHeader = localStorage.getItem("AUTH");
    this.execGetDomainService(this.username, this.authHeader);
    this.execGetUserService(this.username, this.authHeader);
    this.route.params.subscribe(params => {
      this.domain = params['domain'];
      this.loadUsers();

      console.log("date formating")
      var newdate = new Date();
      this.toDate1 = moment(newdate).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.toDate1);

      var d = new Date();
      d.setMonth(d.getMonth() - 11);
      this.fromDate1 = moment(d).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.fromDate1);

      console.log("date formating done");
      this.getMonths();

    });
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);


  }

  public execGetUserService(username: string, authHeader: string) {

    this.userService.getUser(username, authHeader).subscribe(
      data => {
        var userData = data.json();
        this.user = userData;
        this.execGetDomainService(this.username, this.authHeader);
      },
      () => {
        //console.log("Error while fetching user : " + err);
      },
      () => {
        //console.log("Get user service completed!");
      }
    );
  }

  public execGetDomainService(username: string, authHeader: string) {
    //console.log("execGetDomainService called");

    this.userService.getDomain(username, authHeader).subscribe(
      data => {
        this.domainData = data.json();
        this.domains = DomainManager.getDomains(this.domainData);
        //console.log("Domain details : domain : ");
        //console.log(this.domainData);
        if (this.dashboardService.getDomain() == null) {
          this.domain = this.domainData[0].name;
          this.dashboardService.setDomain(this.domain);
        } else {
          this.domain = this.dashboardService.getDomain();
        }
        //console.log("************* Final Domain: ");
        //console.log(this.domain);
      },
      () => {
        //console.error("Error while fetching domain");
      },
      () => {
        //console.log("Get domain service completed!");
      }
    )
  }

  /*
   * Google map initalization. load map when page is loaded
  */

  getStateLocation() {
    let lat;
    let long;
    let carcount
    this.locations = [
      { state1: "Andhra Pradesh", lat: 15.9129, long: 79.7400, carcount: 0 },
      { state1: "Assam", lat: 26.2006, long: 92.9376, carcount: 0 },
      { state1: "Bihar", lat: 25.0961, long: 85.3131, carcount: 0 },
      { state1: "Chandigarh", lat: 30.7333, long: 76.7794, carcount: 0 },
      { state1: "Chhattisgarh", lat: 21.2787, long: 81.8661, carcount: 0 },
      { state1: "Delhi", lat: 28.7041, long: 77.1025, carcount: 0 },
      { state1: "Goa", lat: 15.2993, long: 74.1240, carcount: 0 },
      { state1: "Gujarat", lat: 22.2587, long: 71.1924, carcount: 0 },
      { state1: "Haryana", lat: 29.0588, long: 76.0856, carcount: 0 },
      { state1: "Himachal Pradesh", lat: 31.1048, long: 31.1048, carcount: 0 },
      { state1: "Jharkhand", lat: 23.6102, long: 85.2799, carcount: 0 },
      { state1: "Karnataka", lat: 15.3173, long: 75.7139, carcount: 0 },
      { state1: "Kerala", lat: 10.8505, long: 76.2711, carcount: 0 },
      { state1: "Madhya Pradesh", lat: 22.9734, long: 78.6569, carcount: 0 },
      { state1: "Maharashtra", lat: 19.7515, long: 75.7139, carcount: 0 },
      { state1: "Manipur", lat: 24.6637, long: 93.9063, carcount: 0 },
      { state1: "Meghalaya", lat: 25.4670, long: 91.3662, carcount: 0 },
      { state1: "Nagaland", lat: 26.1584, long: 94.5624, carcount: 0 },
      { state1: "Punjab", lat: 31.1471, long: 75.3412, carcount: 0 },
      { state1: "Rajasthan", lat: 27.0238, long: 74.2179, carcount: 0 },
      { state1: "Tamil Nadu", lat: 11.1271, long: 78.6569, carcount: 0 },
      { state1: "West Bengal", lat: 22.9868, long: 87.8550, carcount: 0 },
      { state1: "Sikkim", lat: 27.5330, long: 88.5122, carcount: 0 },
      { state1: "Telangana", lat: 18.1124, long: 79.0193, carcount: 0 },
      { state1: "Tripura", lat: 23.9408, long: 91.9882, carcount: 0 },
      { state1: "Uttar Pradesh", lat: 26.8467, long: 80.9462, carcount: 0 },
      { state1: "Uttarakhand", lat: 30.0668, long: 79.0193, carcount: 0 },
      { state1: "Puducherry", lat: 11.9416, long: 79.8083, carcount: 0 },
      { state1: "Odisha", lat: 20.9517, long: 85.0985, carcount: 0 },
      { state1: "JAMMU & KASHMIR", lat: 33.7782, long: 76.5762, carcount: 0 },
      { state1: "Daman and Diu", lat: 20.4283, long: 72.8397, carcount: 0 },
      //{ state1: "Dadra and Nagar Haveli", lat: 20.1809, long: 73.0169, carcount: 0 },

    ]
    console.log("getsate methode called");
    let thisComponent = this;
    thisComponent.stateCount;
    console.log(thisComponent.stateCount);

    this.map = new google.maps.Map(document.getElementById('gmap'), {
      center: new google.maps.LatLng(22.9734, 78.6569),
      zoom: 5,
      minZoom: 5,
      maxZoom: 5,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: false,
    });

    var totalcount= 0;
     var misCount = 0;
     console.log("state count:" +thisComponent.stateCount.length);

    for (let index = 0; index < thisComponent.stateCount.length; index++) {
      let row = thisComponent.stateCount[index];
      let state = row.StateName;
      let count = row.CarsCount;
      let T_Dist = row.TotalDistance;
      let S_Dist = row.SafetyDistance;
      // console.log("*******display data**********");
      // console.log(state);
      // console.log(count);


      for (let j = 0; j < this.locations.length; j++) {
        let staticsdata = this.locations[j];

        if (state == staticsdata.state1) {
          staticsdata.carcount = count;
          totalcount = totalcount + count;
        }else{
          
          //console.log("in else candition");
          misCount = misCount + row.CarsCount;
          console.log(state +":" + count);
        }

      }

     
    }

    console.log("Total count:" +totalcount);
    console.log("missing count:" +misCount);
    
    let k;
    let markers: any = [];
    for (k = 0; k < this.locations.length; k++) {
      this.setMarkersAndInfowindow(this.map, this.locations[k]);

    }

  }

  // set markers and infowindow
  setMarkersAndInfowindow(map, locations) {
    console.log("Clicked on marker");

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(locations.lat, locations.long),
      label: locations.carcount + "",
      icon: "assets/ubi/f.png",
      map: map,
      title: locations.state1
    });

    let contents = "<div> <h4> " + locations.state1 + "</h4></div> ";

    marker.addListener('click', function () {
      let infowindow;

      infowindow = new google.maps.InfoWindow({
        content: contents
      });
      infowindow.open(map, marker);
    });

  }

  /**
   * logout 
   */
  logout() {
    this.authService.logout();
    this.dashboardService.invalidate();
  }


  // Active vehicle API
  
 
  getActiveVeh(){
    let enddate = new Date();
    let enddate1 = moment(enddate).format('YYYY-MM-DD');
    let strtdate = enddate.setDate(enddate.getDate() - 30);
    let startdate = moment(strtdate).format('YYYY-MM-DD');
    
    // console.log("*********New Active Vehicles end date********:" +enddate);
    // console.log("*********New Active Vehicles statrt date********:" +strtdate);

    this.policyService.getActiveVehicles(this.authHeader, this.username, this.domain, startdate,enddate1)
      .subscribe(data => {
      // console.log("Get Active vehicles");
        let active = data.json();
        this.ActiveVehCount = active.activeVehiclesCount;
        console.log(this.ActiveVehCount);
      })
  }


  // get renewwal rate
  getRenewalRate(){
    let enddate = new Date();
    let enddate1 = moment(enddate).format('YYYY-MM-DD');
    let strtdate = "2021-04-01";
   // let startdate = moment(strtdate).format('YYYY-MM-DD');
    
    this.policyService.getRenewalRate(this.authHeader, this.username, this.domain, strtdate, enddate1)
      .subscribe(data => {
        // console.log("Get Renewal rate");
        let rate = data.json();
        this.RenwRate=rate.policyRenewalPercentage;
        // console.log(this.RenwRate);
      })
  }

  /**
   * opendialog
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'myclass',
      width: '430px',
      height: '430px',
      data: { name: this.name, animal: this.animal }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.animal = result;
    });
  }

  getTelimaticAndPHYUPolicy() {
    this.policyService.getPHYUAndTelematicsCount(this.authHeader, this.username, this.domain)
      .subscribe(data => {
       console.log("*********Get telematics and PHYU policy");
        this.policyCount = data.json();
        console.log(this.policyCount);



      //pie chart
      // Radialize the colors
      Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [
          // Arrow stem
          'M', x + w * 0.5, y,
          'L', x + w * 0.5, y + h * 0.7,
          // Arrow head
          'M', x + w * 0.3, y + h * 0.5,
          'L', x + w * 0.5, y + h * 0.7,
          'L', x + w * 0.7, y + h * 0.5,
          // Box
          'M', x, y + h * 0.9,
          'L', x, y + h,
          'L', x + w, y + h,
          'L', x + w, y + h * 0.9
        ];
        return path;
      };

// Build the chart
Highcharts.chart('container4', {
  chart: {
      type: 'pie',
      options3d: {
          enabled: true,
          alpha: 45,
          beta: 0
      }
  },
  exporting: {
    buttons: {
      contextButton: {
        symbol: 'download',
        symbolStroke: 'rgb(41, 195, 190)'
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
      text: 'Policies By Types'
  },
  accessibility: {
      // point: {
      //     valueSuffix: '%'
      // }
  },
  tooltip: {
      pointFormat: '<b>{point.name}</b>:<br>Count: {point.total:,.0f}<br>{point.percentage:.1f} %'
      
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>:<br>Count: {point.total}<br>{point.percentage:.1f} %'
          }
      }
  },
  series: [{
      type: 'pie',
      name: 'Policies',
      colors: [
        '#29c3be', 
        '#6262B5',
        '#ED561B', 
      ],
      data: [
          ['Telematics Policies',  this.policyCount[0].totalCount],
          ['PAYU Policies', this.policyCount[2].totalCount],
          ['PHYU Policies', this.policyCount[3].totalCount],
      ]
  }],
 });
});
}
  /**
   * state count 
   */
  getstate() {
    this.policyService.getStateCount(this.authHeader, this.username, this.domain)
      .subscribe(data => {
        // console.log("<<<<<<<<<<<All data>>>>>>>>>");
        // console.log(data);
        this.statedata = data.json();
        // console.log("state all data");
        // console.log(this.statedata);
        this.stateCount =data.json();
        let temp = this.statedata;
        temp.sort(function (a, b) { return b.TotalDistance - a.TotalDistance });
        // console.log("*****Sorted data****");
        console.log(temp);
        this.statedata =temp;
        
        let topdata = temp.slice(0, 5);
        let temp1 = topdata;      
        temp1.sort((a,b) => a.StateName.localeCompare(b.StateName))
        this.statedata = temp1;

        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
          var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
          ];
          return path;
        };

        Highcharts.chart('container2', {
          chart: {
            type: 'column'
          },
          credits: {
            enabled: false
          },
          // lang: {
          //   decimalPoint: ',',
          //   thousandsSep: ' '
          // },
          title: {
            text: 'Travelled vs Safety Distance Travelled'
          },
          xAxis: {
              crosshair: true,
            categories: [this.statedata[0].StateName, this.statedata[1].StateName, this.statedata[2].StateName
              , this.statedata[3].StateName, this.statedata[4].StateName]
          },
          yAxis: {
            min: 0,
            tickInterval: 30000000,
            title: {
              text: 'KMS'
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
            plotOptions: {
            column: {
              pointPadding: 0.3,
              borderWidth: 0,
              pointWidth: 7

            }
          },
          series: 
          // [{
          //   type: 'line',
          //   name: 'Distance Travelled',
          //   color: 'rgb(255,126,0)',
          //   data: [this.statedata[0].TotalDistance, this.statedata[1].TotalDistance, this.statedata[2].TotalDistance
          //     , this.statedata[3].TotalDistance, this.statedata[4].TotalDistance]
          // }, {
          //   type: 'line',
          //   name: 'Safe Distance Travelled',
          //   color: 'rgb(41, 195, 190)',
          //   data: [this.statedata[0].SafetyDistance, this.statedata[1].SafetyDistance, this.statedata[2].SafetyDistance
          //     , this.statedata[3].SafetyDistance, this.statedata[4].SafetyDistance]
          // }],
          [
             
            {
              type: 'column',
              name: 'Distance Travelled',
              color: 'rgb(255,126,0)',
              data: [this.statedata[0].TotalDistance, this.statedata[1].TotalDistance, this.statedata[2].TotalDistance
                  , this.statedata[3].TotalDistance, this.statedata[4].TotalDistance]
            },
            {
              type: 'column',
              name: 'Safe Distance Travelled',
              color: 'rgb(41, 195, 190)',
              data: [this.statedata[0].SafetyDistance, this.statedata[1].SafetyDistance, this.statedata[2].SafetyDistance
                  , this.statedata[3].SafetyDistance, this.statedata[4].SafetyDistance]
            }],
          exporting: {
            buttons: {
              contextButton: {
                symbol: 'download',
                symbolStroke: 'rgb(41, 195, 190)'
              }
            }

          }
        });
        this.getStateLocation();

        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
          var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
          ];
          return path;
        };

        Highcharts.chart('container3', {
          chart: {
            type: 'line'
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Average PHYU Scores in Active States'
          },
          xAxis: {
  
              categories: ['MH', 'KA', 'DL', 'GJ', 'UP', 'TN','HR', 'TS', 'AP', 'UN']
          },
          yAxis: {
            title: {
              text: 'Average Score'
            },
          
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },

          plotOptions: {
            line: {
              enableMouseTracking: true
            },
            column: {
              pointPadding: 0.3,
              borderWidth: 0
            }
          },
          
          series:
           [{
         
            type: 'line',
            name: 'Average',
            color: 'rgb(255,126,0)',
            data: [5.02, 5.01,4.28,4.86,4.54,4.50,4.77,4.69,4.66,4.46]
          }],
          exporting: {
            buttons: {
              contextButton: {
                symbol: 'download',
                symbolStroke: 'rgb(41, 195, 190)'
              }
            }

          }
        });
        
      })
      
 }

//screen downloadable


  
  /**
   * 
   *Total count
   */
  getToatlCountyear() {
    this.policyService.getTotalcounts(this.authHeader, this.username, this.domain)
      .subscribe(data => {
        this.counts = data.json();
        console.log("count all data");
        console.log(this.counts);
      })
  }

getMonths(){
  console.log("********get month function call*******")
var theMonths = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
var now = new Date();
for (var i = 0; i < 12; i++) {
  var future = new Date(now.getFullYear(), now.getMonth() - i, 1);
  var month = theMonths[future.getMonth()];
  var year = future.getFullYear().toString().substr(-2);
  console.log(month,year);
  this.monthsArray.push(month + ' ' + year);
  
}
this.newMonthArray = this.monthsArray.reverse();
console.log(this.newMonthArray);
}

  /**
   * travelSaftyGraph
   * 
   */

  travelSaftyGraph() {
    this.policyService.getStateCount(this.authHeader, this.username, this.domain)
      .subscribe(data => {
        this.saftycount = data.json();
        //console.log("******Travel safty count*********");
        //console.log(this.saftycount);
        for (let i = 0; i < this.saftycount.length; i++) {
          this.TotalDistance_array.push(this.saftycount.TotalDistance);
          //console.log(this.TotalDistance_array);
          this.SafetyDistance_array.push(this.saftycount.SafetyDistance);
          // console.log(this.TotalDistance_array);

        }
      })
  }


  /**
   * Monthly Engagment Graph
   * @param catType 
   * @param cat 
   */

  getCustomerDetails(catType, cat) {
    console.log("*************Date***************");
    console.log(this.toDate1);
    console.log(this.fromDate1);

    this.policyService.CustomerDetails(catType, cat, this.domain, this.fromDate1, this.toDate1, this.authHeader, this.username)
      .subscribe(data => {
        let custom = data.json();
        console.log("*************Getcustomer details***************");
        console.log(custom);
        //Allcustomer
        var demo1 = custom[0].AllCustomers;
        var res = demo1.replace(/=/g, ":");
        var month = res.replace(/Month/g, 'Month');
        var year = month.replace(/Year/g, 'Year');
        var count = year.replace(/Count/g, 'Count');
        console.log(count);
        console.log("*********all customer*************");
        var myJSON = JSON.parse(count);
        console.log(myJSON);
        this.f_n1.push(this.getCountSetFromJSON(myJSON));
        console.log("all customer:" + this.f_n1);
        let allcar = this.f_n1[0];
        console.log(allcar);

        //Car cannected
        var demo1 = custom[0].CarConnected;
        var res = demo1.replace(/=/g, ":");
        var month = res.replace(/Month/g, 'Month');
        var year = month.replace(/Year/g, 'Year');
        var count1 = year.replace(/Count/g, 'Count');
        console.log(count1);
        console.log("**********Car Cannected************");
        var myJSON = JSON.parse(count1);
        console.log(myJSON);
        this.f_n2.push(this.getCountSetFromJSON(myJSON));
        console.log("CarConnected:" + this.f_n2);
        let allcar1 = this.f_n2[0];
        console.log(allcar1);


        //device onboarded

        var demo1 = custom[0].DevicOnboarded;
        var res = demo1.replace(/=/g, ":");
        var month = res.replace(/Month/g, 'Month');
        var year = month.replace(/Year/g, 'Year');
        var count2 = year.replace(/Count/g, 'Count');
        console.log(count2);
        console.log("*********Device Onborded*************");
        var myJSON = JSON.parse(count2);
        console.log(myJSON);
        this.f_n3.push(this.getCountSetFromJSON(myJSON));
        console.log("DeviceOnBoarded:" + this.f_n3);
        let allcar2 = this.f_n3[0];
        console.log(allcar2);


        
        Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
          var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
          ];
          return path;
        };

        Highcharts.chart('container1', {
          chart: {
            type: 'column'
            
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Month Wise'
          },
          xAxis: {
            categories: this.newMonthArray,
            crosshair: true,
          },
          yAxis: {
            min: 0,
            tickInterval: 5000,
            title: {
              text: '#Vehicles'
            }
          },
          
          tooltip: {
           // valueDecimals: 10,
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:,.0f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.3,
              borderWidth: 0
            }
          },
          series: [
             
            {
              type: 'column',
              name: 'Total Cars',
              color: 'rgb(41, 195, 190)',
              data: [

                this.f_n2[0][0], this.f_n2[0][1], this.f_n2[0][2], this.f_n2[0][3],
                this.f_n2[0][4], this.f_n2[0][5], this.f_n2[0][6], this.f_n2[0][7],
                this.f_n2[0][8], this.f_n2[0][9], this.f_n2[0][10], this.f_n2[0][11]
              ]
            },
            {
              type: 'column',
              name: 'New Cars',
              color: 'rgb(255,126,0)',
              data: [
                this.f_n3[0][0], this.f_n3[0][1], this.f_n3[0][2], this.f_n3[0][3],
                this.f_n3[0][4], this.f_n3[0][5], this.f_n3[0][6], this.f_n3[0][7],
                this.f_n3[0][8], this.f_n3[0][9], this.f_n3[0][10], this.f_n3[0][11]
              ]
            }, {
              type: 'column',
              name: 'New Policies',
              color: 'rgb(93, 98, 181)',
              data: [
                this.f_n1[0][0], this.f_n1[0][1], this.f_n1[0][2], this.f_n1[0][3],
                this.f_n1[0][4], this.f_n1[0][5], this.f_n1[0][6], this.f_n1[0][7],
                this.f_n1[0][8], this.f_n1[0][9], this.f_n1[0][10], this.f_n1[0][11]
              ]
            }],
          exporting: {
            buttons: {
              contextButton: {
                symbol: 'download',
                symbolStroke: 'rgb(41, 195, 190)'
              }
            }
          }
          
        });
        Highcharts.setOptions({
          lang: {
            thousandsSep: ','
          }
        });
      })
  }
  hidePreloader() {
    throw new Error("Method not implemented.");
  }

  /** 
   * Create count set from json and return.
   * @param json 
   */
  public getCountSetFromJSON(json: any) {
    let countSet = [];
    for (let i = 0; i < Object.keys(json).length; i++) {
      let obj = json[i];
      //console.log("object at " + i + " : " + obj);
      countSet.push(obj.Count);
    }
    //console.log("complete countSet: " + countSet);
    return countSet;
  }

  /**
   * @param username 
   * @param authHeader 
   */
  public loadUsers() {

    this.userService.getDomain(this.username, this.authHeader).subscribe(
      data => {
        let domain = data.json();
        this.domain = domain[0].name;
        // this.getpolicyCounts('stage', 'AllCustomers');
        // this.getpolicyCounts('stage', 'Car Connected');
        // this.getpolicyCounts('stage', 'Device OnBoarded');
        this.getstate();
        this.getTelimaticAndPHYUPolicy();
        this.getToatlCountyear();
        this.travelSaftyGraph();
        this.getMonths();
        this.getCustomerDetails('stage', 'All')
        this.getActiveVeh();
        this.getRenewalRate();
      },
      () => {
      },
      () => {
      }
    )
  }

public captureScreen()  
  {  
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      const contentDataURL = canvas.toDataURL('image/png')  
       var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
     
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      // var img = new Image();
      // img.src = 'assets/ubi/footer-img.png';
      //  img.onload = function() {
      var position = 0;  
      pdf.setFillColor(255, 255, 255 );
      pdf.rect(10, 10, 150, 160, "F"); 
      pdf.addImage(contentDataURL, 'PNG', 0, 15, imgWidth, imgHeight)  
      pdf.save('Dashbord.pdf'); // Generated PDF   
    //  }
   
    });  
  } 
  

  
  /** 
  * Create count set from json and return.
  * @param json1 
  */
  public getCountSetFromJSON1(json1: any) {
    let countSet1 = [];
    for (let i = 0; i < Object.keys(json1).length; i++) {
      let obj = json1[i];
      countSet1.push(obj.Count);
    }
    return countSet1;
  }



  /**
  * Create count set from json and return.
  * 
  * @param json2
  */
  public getCountSetFromJSON2(json2: any) {
    let countSet2 = [];
    for (let i = 0; i < Object.keys(json2).length; i++) {
      let obj = json2[i];
      countSet2.push(obj.Count);
    }
    return countSet2;
  }
  /* download map image
  */
  downloadImage() {
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'Hitmap.png';
      this.downloadLink.nativeElement.click();
    });
  }
}


