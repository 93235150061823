import { Injectable } from "@angular/core"


@Injectable()
export class DashboardService {

    public stage:string = "not_verified";
    priority:string = "All";
    callFilter:string = "All calls";
    createdAfter:Date = new Date();
    pageIndex:number = 0;
    pageSize:number = 10;
    public userFilter:string = "All users";
    domainName:string;

    constructor() {
        this.createdAfter.setDate(this.createdAfter.getDate() - 365);
    }

    public getStage() : string {
        return this.stage;
    }

    public getPriority() : string {
        return this.priority;
    }

    public getCallFilter() : string {
        return this.callFilter;
    }

    public getCreatedAfter() : Date {
        return this.createdAfter;
    }

    public getPageIndex() : number {
        return this.pageIndex;
    }

    public getPageSize() : number {
        return this.pageSize;
    }




    public setStage(stage:string) {
        this.stage = stage;
    }

    public setPriority(priority:string) {
        this.priority = priority;
    }

    public setCallFilter(filter:string) {
        this.callFilter = filter;
    }

    public setCreatedAfter(createdAfter:Date) {
        this.createdAfter = createdAfter;
    }

    public setPageIndex(index: number) {
        this.pageIndex = index;
    }

    public setPageSize(size:number) {
        this.pageSize = size;
    }

    public setUserFilter(userFilter:string) {
        this.userFilter = userFilter;
    }
         
        
    public getUserFilter() : string {
        return this.userFilter;
    }
        
    public setDomain(domain:string) {
        this.domainName = domain;
    }

    public getDomain() : string {
        return this.domainName;
    }

    public invalidate() {
        this.domainName = null;
        this.pageIndex = 0;
        this.pageSize = 100;
    }
}