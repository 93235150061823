import {RestClient} from '../rest_client/RestClient'
import {Injectable} from '@angular/core'; 
import {StringUtils } from '../utils/StringUtils' 

@Injectable()
export class StagingService {

    constructor(private restClient: RestClient) {
    }

    public load(user:any, authHeader) {
        let body = user;

        let stagingLoaderAPI = `/admin/loader/loadSingle/AtomStagingAndVerificationDetailsUpdater`;

        return this.restClient.execPOSTRequest(stagingLoaderAPI, authHeader, body);
    }
}