import { Domain } from './Domain'
import { FilterModel, DashboardFilterModel } from "../DashboardFilterModel";

export class ILAssistDomain implements Domain {
    getIcon(): string {
        return "ic_ilassist.png";
    }
    getMobileAppName(): string {
        return "IL-Assist";
    }
    getDomainTelematicsProductName(): string {
        return "IL-Assist Telematics policy";
    }


    public filterModel:DashboardFilterModel;

    constructor() {
        this.filterModel = new DashboardFilterModel();
    }

    getName(): string {
        return "IL-Assist";
    }
    getPlayStoreLink(): string {
        return "https://getilassist.mycariq.com";
    }

    getDashboardFilters(): FilterModel[] {
        let allFilters:FilterModel[] = this.filterModel.getAllFilters();
        return allFilters;
    }
    getCompanyName(): string {
        return "ICICI Lombard General Insurance Co. Ltd";
    }
}
