import * as Moment from "moment";
import * as dateFormat from "dateformat";


export class DateUtils {

    public static FORMAT_DEFAULT:string = "YYYY-MM-DD hh:mm:ss";
    public static FORMAT_DATE:string = "YYYY-MM-DD";

    public constructor() {

    }

    public static millisToTimeString(millisec) {
        console.log("DateUtils: millisToTimeString called: millisec: " + millisec);
            
        let seconds = parseInt((millisec / 1000).toFixed(1));
            
        var minutes = parseInt((millisec / (1000 * 60)).toFixed(1));
        var hours = parseInt((millisec / (1000 * 60 * 60)).toFixed(1));
            
        var days = parseInt((millisec / (1000 * 60 * 60 * 24)).toFixed(1));
            
        if (seconds < 60) {
            return seconds + " Sec";
        } else if (minutes < 60) {
            return minutes + " Min";
        } else if (hours < 24) {
            return hours + " Hrs";
        } else {
            return days + " Days"
        }                    
    }


    /**
     * Get local date.
     * 
     * @param date 
     * @param format 
     */
    public getLocalDate(date:Date, format:string) {
        return Moment(date).local().format(format);
    } 


    /**
     * Validate date, start date should not be greater than end date.
     * 
     * @param startDate 
     * @param endDate 
     */
    public static isBefore(startDate:Date, endDate:Date) {
        console.log("DateUtils: startDate: " + startDate + ", endDate: " + endDate);

        return Moment(startDate).isBefore(endDate);
    }

    public static formatDate(date:Date, format:string) {
        return Moment(date).format(format);
    }

    public static getDateDifference(inputDate) {

        if(inputDate == null) 
            return "";
        let localDate:Date;
        try {
            localDate = dateFormat(new Date(inputDate + " UTC"),'yyyy-mm-dd HH:MM:ss');
        } catch(e) {
            console.log(e);
        }

        let today:Date = new Date();
        let dt:Date = new Date(localDate);
        let dateInMilli = dt.getTime();
        let todayInMilli = today.getTime();
        let diffMs:number =  todayInMilli - dateInMilli; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        let finalStr = "";

        if(diffDays >= 1) {
            if(diffDays > 1) {
                return finalStr = diffDays + " days ago";
            } else {
                return finalStr = diffDays + " day ago";
            }
        }
        if(diffHrs >= 1) {
            if(diffHrs > 1) {
                return finalStr = diffHrs + " Hours ago";
            } else {
                return finalStr = diffHrs + " Hour ago";
            }
        }
        if(diffMins >= 1) {
            if(diffMins > 1) {
                return finalStr = diffMins + " Minutes ago";
            } else {
                return finalStr = diffHrs + " Minute ago";
            }
        }
    } 


    /**
     * Get date from epic time 
     * 
     * @param epoc 
     */
    public static epocToDate(epoc) {
        return Moment(epoc).format(DateUtils.FORMAT_DEFAULT);
    }

    /**
     * Get arrays of dates from given date
     * 
     * @param date 
     * @param days 
     * @param format 
     */
    public static getDateArrFromGivenDate(date:Date, days:number, format:string) : string[] {
        let arr:string[] = [];
        for(let i = 0; i <=days; i++) {
            arr.push(DateUtils.getDateBeforeDays(date, i, format));
        }
        return arr;
    }

    /**
     * Substract days from given date
     * 
     * @param date 
     * @param days 
     * @param format 
     */
    public static getDateBeforeDays(date:Date, days:number, format:string) :string {
        return Moment(date).subtract(days, 'days').format(format);
    }

    public static toUTC(date, format:string){
        return Moment(date).utc().format(format);
    }
}