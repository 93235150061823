import { Platform } from "@angular/cdk/platform"
import { NativeDateAdapter, } from "@angular/material";


export class AppDateAdapter extends NativeDateAdapter {

    constructor(matDateLocale: string) {
        super(matDateLocale, new Platform());        
    }

    format(date: Date, displayFormat: Object): string {

        if (displayFormat === 'input') {

            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}/${month}/${day}`;
        }

        return date.toDateString();
    }
}